import React, { Fragment } from "react";

import GetImageFromFireBase from "../GetImageFromFirebase";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const adsNumber = [2, 3, 4, 5, 6, 7, 8, 9, 10];

const Advert = () => {
	return (
		<div>
			<Carousel
				autoPlay
				showArrows={true}
				showIndicators={false}
				showThumbs={false}
				showStatus={false}
				infiniteLoop
			>
				{adsNumber.map((i: number) => {
					return (
						<Fragment key={i}>
							<div>
								<GetImageFromFireBase source={`ad_${i}`} />
							</div>
						</Fragment>
					);
				})}
			</Carousel>
		</div>
	);
};

export default Advert;
