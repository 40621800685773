
import {combineReducers} from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import {firestoreReducer} from 'redux-firestore'
// Add firebase to reducers
import authReducer from "./authReducer";

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore:firestoreReducer,
  auth:authReducer
});

export default rootReducer;