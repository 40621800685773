export const EMAIL_ALREADY_EXISTS = 'auth/email-already-in-use';

export const INVALID_EMAIL = 'auth/invalid-email';

export const OPERATION_NOT_ALLOWED = 'auth/operation-not-allowed';

export const WEAK_PASSWORD = 'auth/weak-password';

export const SIGNUP_FEEDBACK = 'SIGNUP_FEEDBACK';

export interface SignUpValues {
    companyName?: string;
    address?: string;
    CACnumber?: string;
    type_of_delivery?: string;
    type_of_cargo_delivery_option?: string;
    type_of_human_delivery_option?: string;
    fixed_price_per_kg?: string;
    email?: string;
    password?: string;
    confirmpassword?: string;
    price_per_mile?: string;
    weight_of_parcel?: string;
    photoID?:string;
    representative_name?:string;
    representative_phonenumber?:string;
}


export interface ClientSignUpValues {
    type_of_transport?: string;
    client_pick_up_address?: string;
    client_destination_address?: string;
    client_pick_up_time?: string;
    parcel_length?: string;
    parcel_breadth?: string;
    parcel_height?: string;
    parcel_weight?: string;
    parcel_quantity?: string;
    number_of_seats?: string;
    luggage_space?: string;
    have_you_put_name_of_reciever_on_the_parcel?: string;
    have_you_put_mobile_number_of_reciever_on_the_parcel?: string;
    have_you_ensured_the_parcel_is_secured?: string;
    position_of_item?: string;
    reciever_first_name?: string;
    reciever_last_name?: string;
    reciever_email?: string;
    reciever_phonenumber?: string;
    first_name?: string;
    last_name?: string;
    email?: string;
    password?: string;
    confirmpassword?: string;
}