import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';
import { SendNotification } from '../../notification/sendNotificationAction';
import { ILogisticsCompanyAccount } from '../../../../ts/interfaces/logisticsCompany';
import { EMAIL_ALREADY_EXISTS, OPERATION_NOT_ALLOWED, INVALID_EMAIL, WEAK_PASSWORD, } from "../../../../ts/types/errors";
import { SIGNUP_FEEDBACK } from "../../../../ts/types/signup";

export const NewLogisticsCompanyAccount = (logistics_company_account: ILogisticsCompanyAccount, device_data: any): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

        try {
            const response = await firebase.auth().createUserWithEmailAndPassword(logistics_company_account.email, logistics_company_account.password);
            const createCollectionPromise = firebase.firestore().collection('LogisticsCompanies').doc(response.user.uid).set({
                company_name: logistics_company_account.company_name,
                company_address: logistics_company_account.company_address,
                CACnumber: logistics_company_account.CACnumber,
                type_of_delivery: logistics_company_account.type_of_delivery,
                type_of_cargo_delivery_option: logistics_company_account.type_of_cargo_delivery_option,
                type_of_human_delivery_option: logistics_company_account.type_of_human_delivery_option,
                price_per_mile: logistics_company_account.price_per_mile,
                representative_data: {
                    name: logistics_company_account.representative_data.name,
                    phone_number: logistics_company_account.representative_data.phone_number,
                    email: logistics_company_account.representative_data.email,
                    position: logistics_company_account.representative_data.position,
                },
                company_logo_ID: logistics_company_account.company_logo_ID,
                operational_countries: logistics_company_account.operational_countries,
                approved: false,
                blocked: false,
                is_new: true,
                is_available: true,
                featured: false,
                devices: [device_data],
            });
            const updateProfilePromise = firebase.auth().currentUser.updateProfile({
                displayName: 'logisticsCompany',
                photoURL: logistics_company_account.company_logo_ID,
            });
            //add as logistics
            // const addLogisticsCompanyRole = firebase.functions().httpsCallable('addLogisticsCompanyRole');

            // const addLogisticsCompanyRolePromise = addLogisticsCompanyRole({ email: logistics_company_account.email })

            const sendEmailPromise = firebase.auth().currentUser.sendEmailVerification();

            const allPromise = Promise.all([createCollectionPromise, updateProfilePromise, sendEmailPromise]);

            allPromise.then(() => {
                dispatch({ type: SIGNUP_FEEDBACK, payload: { severity: 'success', message: "Congratulations! Your Account has been created! A verification link has been sent to your email." } });
                dispatch(SendNotification({
                    to: firebase.auth().currentUser.uid,
                    subject: 'Welcome! to Fast Logistics',
                    body: 'We are happy to have you on the best logistics booking website',
                    severity: 'info'
                }));
            })



        } catch (error) {
            var errorCode = error.code;
            //    var errorMessage = error.message;

            switch (errorCode) {
                case EMAIL_ALREADY_EXISTS:
                    dispatch({ type: SIGNUP_FEEDBACK, payload: { severity: 'error', message: "Oops! An existing account is already using this email! Use another email" } })
                    break;
                case OPERATION_NOT_ALLOWED:
                    dispatch({ type: SIGNUP_FEEDBACK, payload: { severity: 'error', message: "Oops! The developers of this app did not activate this feature!A report would be sent to the owners " } })
                    break;
                case INVALID_EMAIL:
                    dispatch({ type: SIGNUP_FEEDBACK, payload: { severity: 'error', message: "Oops! This email is invalid! Check the format and try again" } })
                    break;
                case WEAK_PASSWORD:
                    dispatch({ type: SIGNUP_FEEDBACK, payload: { severity: 'warning', message: "Oops! Your password is too weak! try another password! " } })
                    break;
                default:
                    break;
            }
        }
    }