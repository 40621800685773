
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';

export const MarkAsNotNewUser = (uid: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

        return firebase.firestore().collection('LogisticsCompanies').doc(uid).update({
            is_new: false,

        })
            .then(() => {

            })
            .catch((error: any) => {
                console.log(error);

            })


    }