import React, { Fragment, useState, useEffect, lazy, Suspense } from "react";
//package
import _ from "lodash";
// import * as moment from 'moment';
// styles
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
//components
// import Grid from '@material-ui/core/Grid';
// import { Typography, Divider } from '@material-ui/core';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { Button, Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { Link } from "react-router-dom";

//connect react
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
//Alert Component
import CustomAlert from "../CustomAlert/CustomAlert";
import { DeleteBooking } from "../../store/actions/booking/deleteBookingAction";
// import { FindLogisticsCompany } from '../../store/actions/account/customer/findLogisticsCompanyAction';
//@ts-ignore
import CountryStateCityJson from "countrycitystatejson";

const LogisticsCompanyList = lazy(() =>
	import("../../components/LogisticsCompanyList/LogisticsCompanyList")
);
const CurrentBookingList = lazy(() =>
	import("../../components/Bookings/CurrentBookingList")
);
const CompletedBookingList = lazy(() =>
	import("../../components/Bookings/CompletedBookingList")
);

// const getAllBookings = (AllBookings: object[], id: string) => {
//     const bookings = AllBookings.filter((item: any) => {
//         return item.customer_id === id
//     })

//     return bookings
// }

const getPendingBookings = (AllBookingsArr: object[], id: string) => {
	const pending_bookings = AllBookingsArr.filter((item: any) => {
		return item.pending.status === true && item.customer_id === id;
	});

	const order_filtered_by_date_added = _.orderBy(
		pending_bookings,
		["created_on"],
		["desc"]
	);
	return order_filtered_by_date_added;
};

const getCurrentBookings = (AllBookings: object[], id: string) => {
	const result_new_booking = AllBookings.filter((item: any) => {
		if (typeof item.current !== "undefined") {
			return item.customer_id === id && item.current.status === true;
		}
		return null;
	});
	const order_filtered_by_date_added = _.orderBy(
		result_new_booking,
		["created_on"],
		["desc"]
	);
	return order_filtered_by_date_added;
};

const getCompletedBookings = (AllBookings: object[], id: string) => {
	const result_new_booking = AllBookings.filter((item: any) => {
		if (typeof item.customer_completed !== "undefined") {
			return item.customer_id === id && item.customer_completed.status === true;
		}
		return null;
	});

	const order_filtered_by_date_added = _.orderBy(
		result_new_booking,
		["created_on"],
		["desc"]
	);

	return order_filtered_by_date_added;
};

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</div>
	);
}

function a11yProps(index: any) {
	return {
		id: `scrollable-auto-tab-${index}`,
		"aria-controls": `scrollable-auto-tabpanel-${index}`,
	};
}
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			width: "100%",
			backgroundColor: theme.palette.background.paper,
			marginTop: 20,
		},
		no_result: {
			width: "100%",
			height: "200px",
			border: "2px gray solid",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
		pending_booking_top_section: {},
		pending_booking_bottom_section: {},
		pending_booking_wrapper: {
			width: "100%",
			height: "auto",
			borderRadius: 10,
			marginBottom: 20,
			[theme.breakpoints.down("sm")]: {
				height: "auto",
				padding: 20,
			},
		},
		booked_pending_booking_wrapper: {
			width: "100%",
			height: "auto",
			borderRadius: 10,
			marginBottom: 20,
			backgroundColor: "#ffe7e6",
			[theme.breakpoints.down("sm")]: {
				height: "auto",
				padding: 20,
			},
		},
		pickup_destination_wrapper: {
			display: "flex",
			justifyContent: "space-evenly",
			alignItems: "center",
		},
		pickup_destination_address_wrapper: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "column",
			padding: 20,
			height: "100%",
			width: "100%",
		},
		miles_divider_wrapper: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "column",
			height: "100%",
			width: "100%",
			"& div": {
				width: "100%",
				border: "2px black solid",
			},
		},
		pending_booking_action_wrapper: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "row",
			height: "100%",
			width: "100%",
		},
	})
);

const CustomerDashBoardWindow = ({
	allBookings,
	user,
	auth,
	signUpInfo,
	clearSignUpInfo,
	deleteBooking,
	allLogisticsCompanies,
	allRatingsAndReviews,
}: any) => {
	const styles = useStyles();
	const [tabIndex, settabIndex] = useState(0);
	const [pendingBooking, setpendingBooking] = useState<any>([]);
	const [currentBooking, setcurrentBooking] = useState<any>([]);
	const [completedBooking, setcompletedBooking] = useState<any>([]);
	const [
		showViewPendingBookingModal,
		setshowViewPendingBookingModal,
	] = useState(false);
	const [viewPendingBookingDetails, setviewPendingBookingDetails] = useState<
		any
	>({});
	const [findLogisticsCompaniesList, setfindLogisticsCompaniesList] = useState<
		any
	>([]);
	const [
		findLogisticsCompaniesListModal,
		setfindLogisticsCompaniesListModal,
	] = useState(false);
	const [deleteBookingModal, setDeleteBookingModal] = useState(false);
	const [deleteBookingId, setDeleteBookingId] = useState("");

	useEffect(() => {
		if (typeof allBookings !== "undefined") {
			// const result_bookings = getAllBookings(allBookings, user.uid);
			const result_pendingbooking = getPendingBookings(allBookings, user.id);
			const result_currentbooking = getCurrentBookings(allBookings, user.id);
			const result_completedbooking = getCompletedBookings(
				allBookings,
				user.id
			);
			// setbookings([...result_bookings]);
			setpendingBooking([...result_pendingbooking]);
			setcurrentBooking([...result_currentbooking]);
			setcompletedBooking([...result_completedbooking]);
		}
	}, [allBookings, user]);

	const findLogisticsCompany = (
		AllLogisticsCompany: object[],
		type_of_delivery: string,
		Pickup_Destination_Country_Name?: any,
		b_id?: any,
		b_booked?: any,
		b_chooosen_logistics_companies?: any,
		b_rejected?: any,
		distance?: number
	) => {
		const result_approved_unblocked_operational_countries = AllLogisticsCompany.filter(
			(item: any) => {
				return (
					item.approved === true &&
					item.blocked === false &&
					item.is_available === true
				);
			}
		);

		// const result_type_of_search =
		const result_type_of_search = result_approved_unblocked_operational_countries.filter(
			(item: any) => {
				return item.type_of_delivery === type_of_delivery;
			}
		);
		const result_operational_countries = result_type_of_search.filter(
			(item: any) => {
				return item.operational_countries.some(
					(r: any) => Pickup_Destination_Country_Name.indexOf(r) >= 0
				);
			}
		);
		const result_with_booking_id = result_operational_countries.map(
			(item: any) => ({
				...item,
				booking_id: b_id,
				booked: b_booked,
				chooosen_logistics_companies: b_chooosen_logistics_companies,
				distance: distance,
			})
		);

		// if (typeof b_rejected !== 'undefined') {
		const result_with_out_rejected = result_with_booking_id.filter(
			(item: any) => {
				return !b_rejected.includes(item.id);
			}
		);

		// }
		setfindLogisticsCompaniesList([...result_with_out_rejected]);
		// setfindLogisticsCompaniesList([...result_with_booking_id]);
		setfindLogisticsCompaniesListModal(true);
	};

	const getCountryNameFromShortName = (cn_sn_list: any[], sn: string) => {
		const indexOfCountry = _.findIndex(cn_sn_list, { country_short_name: sn });
		const cn = cn_sn_list[indexOfCountry].country_name;
		return cn;
	};

	const getCountryNameArray = (p_sn: string, d_sn: string) => {
		const pickup_at_country_sn = p_sn;
		const destination_to_country_sn = d_sn;

		const country_name_shortname_list = CountryStateCityJson.getCountries().map(
			(item: any) => {
				return { country_name: item.name, country_short_name: item.shortName };
			}
		);
		const pick_up_at_country_name = getCountryNameFromShortName(
			country_name_shortname_list,
			pickup_at_country_sn
		);
		const destination_country_name = getCountryNameFromShortName(
			country_name_shortname_list,
			destination_to_country_sn
		);

		const pickup_destination_countries = [];
		pickup_destination_countries[0] = pick_up_at_country_name;
		pickup_destination_countries[1] = destination_country_name;

		// console.log(pickup_destination_countries);
		return pickup_destination_countries;
	};

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		settabIndex(newValue);
	};

	const getSinglePendingBooking = (
		AllBookingsArr: object[],
		users_id: string,
		booking_id: string
	) => {
		const pending_bookings = AllBookingsArr.filter((item: any) => {
			return (
				item.pending.status === true &&
				item.customer_id === users_id &&
				item.id === booking_id
			);
		});

		setviewPendingBookingDetails({ ...pending_bookings[0] });
		setshowViewPendingBookingModal(true);
	};

	if (
		!isLoaded(allBookings) &&
		!isLoaded(allLogisticsCompanies) &&
		!isLoaded(allRatingsAndReviews)
	) {
		return <div>Database Loading...</div>;
	}
	return (
		<Fragment>
			{signUpInfo.severity === "error" ||
			signUpInfo.severity === "warning" ||
			signUpInfo.severity === "info" ||
			signUpInfo.severity === "success" ? (
				<CustomAlert
					severity={signUpInfo.severity}
					message={signUpInfo.message}
					show={true}
					clearState={clearSignUpInfo}
				/>
			) : null}
			<Dialog
				open={deleteBookingModal}
				onClose={() => setDeleteBookingModal(false)}
			>
				<DialogTitle id="delete-booking">Remove Pending Booking </DialogTitle>
				<DialogContentText>
					You are about permanently remove this booking
				</DialogContentText>
				<DialogActions>
					<Button onClick={() => setDeleteBookingModal(false)}>Cancel</Button>
					<Button
						variant="outlined"
						onClick={() => {
							deleteBooking(deleteBookingId);
							setTimeout(() => {
								setDeleteBookingModal(false);
							}, 1000);
						}}
					>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				key="pendingBookings"
				open={showViewPendingBookingModal}
				onClose={() => setshowViewPendingBookingModal(false)}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="pendingbooking-title">Pending Booking </DialogTitle>
				<DialogContent>
					<DialogContentText>
						{_.isEmpty(viewPendingBookingDetails)
							? null
							: "Added on : " +
							  new Date(
									viewPendingBookingDetails.created_on.seconds * 1000
							  ).toLocaleDateString("en-US")}
					</DialogContentText>
					{_.isEmpty(viewPendingBookingDetails) ? null : (
						<List component="div" aria-labelledby="pending-bookings">
							<ListItem>
								<ListItemText
									primary=" Your Role"
									secondary={_.capitalize(viewPendingBookingDetails.persona)}
								/>
							</ListItem>
							{viewPendingBookingDetails.persona === "sender" && (
								<Fragment>
									<ListItem>
										<ListItemText
											primary="Reciever Name"
											secondary={viewPendingBookingDetails.reciever.name}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											primary="Reciever Phone Number"
											secondary={
												viewPendingBookingDetails.reciever.phone_number
											}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											primary="Reciever Email"
											secondary={viewPendingBookingDetails.reciever.email}
										/>
									</ListItem>
								</Fragment>
							)}
							{viewPendingBookingDetails.persona === "reciever" && (
								<Fragment>
									<ListItem>
										<ListItemText
											primary="Sender Name"
											secondary={viewPendingBookingDetails.sender.name}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											primary="Sender Phone Number"
											secondary={viewPendingBookingDetails.sender.phone_number}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											primary="Sender Email"
											secondary={viewPendingBookingDetails.sender.email}
										/>
									</ListItem>
								</Fragment>
							)}
							{viewPendingBookingDetails.third_party === true && (
								<Fragment>
									<ListItem>
										<ListItemText
											primary="Sender Name"
											secondary={viewPendingBookingDetails.sender.name}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											primary="Sender Phone Number"
											secondary={viewPendingBookingDetails.sender.phone_number}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											primary="Sender Email"
											secondary={viewPendingBookingDetails.sender.email}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											primary="Reciever Name"
											secondary={viewPendingBookingDetails.reciever.name}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											primary="Reciever Phone Number"
											secondary={
												viewPendingBookingDetails.reciever.phone_number
											}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											primary="Reciever Email"
											secondary={viewPendingBookingDetails.reciever.email}
										/>
									</ListItem>
								</Fragment>
							)}

							<ListItem>
								<ListItemText
									primary="Pickup Address"
									secondary={viewPendingBookingDetails.pickup_at.address}
								/>
							</ListItem>
							<ListItem>
								<ListItemText
									primary="Pickup Region"
									secondary={
										viewPendingBookingDetails.pickup_at.city +
										" ," +
										viewPendingBookingDetails.pickup_at.state +
										" ." +
										viewPendingBookingDetails.pickup_at.country
									}
								/>
							</ListItem>
							<ListItem>
								<ListItemText
									primary="Destination Address"
									secondary={viewPendingBookingDetails.destination_to.address}
								/>
							</ListItem>
							<ListItem>
								<ListItemText
									primary="Destination Region"
									secondary={
										viewPendingBookingDetails.destination_to.city +
										" ," +
										viewPendingBookingDetails.destination_to.state +
										". " +
										viewPendingBookingDetails.destination_to.country
									}
								/>
							</ListItem>
							{viewPendingBookingDetails.type_of_transport === "Parcel" && (
								<Fragment>
									<ListItem>
										<ListItemText
											primary="Preferred Parcel Delvery Option"
											secondary={
												viewPendingBookingDetails.delivery_of_item_type +
												" Delivery"
											}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											primary="Parcel category"
											secondary={viewPendingBookingDetails.items.catergory}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											primary="Parcel description"
											secondary={viewPendingBookingDetails.items.description}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											primary="Parcel Quantity"
											secondary={viewPendingBookingDetails.items.quantity}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											primary="Parcel Weight"
											secondary={viewPendingBookingDetails.items.weight + " kg"}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											primary="Parcel Length"
											secondary={
												viewPendingBookingDetails.items.length + " inches"
											}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											primary="Parcel Breadth"
											secondary={
												viewPendingBookingDetails.items.breadth + " inches"
											}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											primary="Parcel Height"
											secondary={
												viewPendingBookingDetails.items.height + " inches"
											}
										/>
									</ListItem>
								</Fragment>
							)}
							{viewPendingBookingDetails.type_of_transport !== "Parcel" && (
								<Fragment>
									<ListItem>
										<ListItemText
											primary="Preferred Human Transport Option"
											secondary={
												viewPendingBookingDetails.number_of_seats +
												" Seater Vehicle"
											}
										/>
									</ListItem>
								</Fragment>
							)}
							<ListItem>
								<ListItemText
									primary="Have you put name of reciever on the parcel?"
									secondary={
										viewPendingBookingDetails.item_check_list
											.have_you_put_name_of_reciever_on_the_parcel
									}
								/>
							</ListItem>
							<ListItem>
								<ListItemText
									primary="Have you put phone number of reciever on the parcel?"
									secondary={
										viewPendingBookingDetails.item_check_list
											.have_you_put_mobile_number_of_reciever_on_the_parcel
									}
								/>
							</ListItem>
							<ListItem>
								<ListItemText
									primary="Have you ensure the parcel is secured?"
									secondary={
										viewPendingBookingDetails.item_check_list
											.have_you_ensured_the_parcel_is_secured
									}
								/>
							</ListItem>
							<ListItem>
								<ListItemText
									primary="Position of item is parcel note"
									secondary={
										viewPendingBookingDetails.item_check_list.position_of_item
									}
								/>
							</ListItem>
						</List>
					)}
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setshowViewPendingBookingModal(false)}
						color="primary"
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				maxWidth="md"
				fullWidth
				key="findlogisticsCompanyModal"
				open={findLogisticsCompaniesListModal}
				onClose={() => setfindLogisticsCompaniesListModal(false)}
				aria-labelledby="form-dialog-logistics-company"
			>
				<DialogTitle id="pendingbooking-title">
					{findLogisticsCompaniesList.length === 0 ? (
						"Sorry no logistics company in the region to fulfil your booking"
					) : (
						<Fragment>
							Showing{" "}
							{!_.isEmpty(findLogisticsCompaniesList) &&
								findLogisticsCompaniesList.length}{" "}
							Logistics Company in the region{" "}
						</Fragment>
					)}
				</DialogTitle>
				<DialogContent>
					<DialogContentText></DialogContentText>
					{/* {
                        _.isEmpty(viewPendingBookingDetails) ? (null) : (

                                                   )

                    } */}
					<Suspense fallback={<div>Loading...</div>}>
						{!_.isEmpty(findLogisticsCompaniesList) && (
							<LogisticsCompanyList
								companyArry={findLogisticsCompaniesList}
								ratingsAndReviews={allRatingsAndReviews}
								closeModal={setfindLogisticsCompaniesListModal}
							/>
						)}
					</Suspense>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setfindLogisticsCompaniesListModal(false)}
						color="primary"
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>

			<div className={styles.root}>
				<AppBar position="static" color="default">
					<Tabs
						value={tabIndex}
						onChange={handleChange}
						indicatorColor="primary"
						textColor="primary"
						variant="scrollable"
						scrollButtons="auto"
						aria-label="scrollable auto tabs example"
					>
						<Tab
							data-tut="newuser__pendingbooking"
							label="Pending Booking"
							{...a11yProps(0)}
						/>

						<Tab
							data-tut="newuser__currentbooking"
							label="Current Bookings"
							{...a11yProps(1)}
						/>

						<Tab
							data-tut="newuser__completedbooking"
							label="Completed Bookings"
							{...a11yProps(2)}
						/>
					</Tabs>
				</AppBar>
				<TabPanel value={tabIndex} index={0}>
					<div className={styles.pending_booking_top_section}>
						{pendingBooking.length === 0 && (
							<div className={styles.no_result}>
								<h6>No Pending Booking </h6>
							</div>
						)}

						{pendingBooking.map((item: any, index: number) => (
							<Fragment>
								<Box
									boxShadow={item.booked ? 0 : 10}
									className={
										item.booked
											? styles.booked_pending_booking_wrapper
											: styles.pending_booking_wrapper
									}
								>
									<Grid container>
										<Grid item xs={12} lg={2}>
											<div
												className={styles.pickup_destination_address_wrapper}
											>
												{item.booked && (
													<Typography variant="caption" align="center">
														You booked already
													</Typography>
												)}

												<Typography variant="body2" align="center">
													{item.persona === "sender" && (
														<Typography variant="h6" align="center">
															You are the Sender
														</Typography>
													)}
													{item.persona === "reciever" && (
														<Typography variant="h6" align="center">
															You are the Reciever
														</Typography>
													)}
													{item.third_party === true && (
														<Typography variant="h6" align="center">
															You are the Third Party
														</Typography>
													)}
												</Typography>
											</div>
										</Grid>
										<Grid item xs={12} lg={2}>
											<div
												className={styles.pickup_destination_address_wrapper}
											>
												<Typography variant="caption" align="center">
													Pick Up at
												</Typography>
												<Typography variant="body2" align="center">
													{item.pickup_at.address}
												</Typography>
											</div>
										</Grid>
										<Grid item xs={12} lg={2}>
											<div className={styles.miles_divider_wrapper}>
												<Typography variant="h6" align="center">
													{item.distance_text}les{" "}
												</Typography>
												<Typography variant="caption" align="center">
													{item.duration} drive
												</Typography>
												<div></div>
												<Typography variant="body1" align="center">
													{item.type_of_transport === "Parcel"
														? "Parcel Delivery"
														: "Human transport"}
												</Typography>
											</div>
										</Grid>
										<Grid item xs={12} lg={2}>
											<div
												className={styles.pickup_destination_address_wrapper}
											>
												<Typography variant="caption" align="center">
													Destination to
												</Typography>
												<Typography variant="body2" align="center">
													{/* {
                                                            _.isEmpty(pendingBooking) ? null : pendingBooking.destination_to.address

                                                        } */}
													{item.destination_to.address}
												</Typography>
											</div>
										</Grid>
										<Grid item xs={12} lg={4}>
											<div className={styles.pending_booking_action_wrapper}>
												<IconButton
													color="primary"
													aria-label="View pending booking"
													onClick={() =>
														getSinglePendingBooking(
															allBookings,
															user.id,
															item.id
														)
													}
												>
													<VisibilityIcon />
												</IconButton>
												{/* <IconButton color="primary" aria-label="edit pending booking"> */}
												<Link
													to={`/EditBooking/9938njnjndjenxnwndjndnncjnjnjcjdmcmdcswwowiuytroksojojsooj98dnehehe8hhddh8e9ejjddje9jdejelsmlxmxkmsxxsmsmx8hdhde8h/${user.customer_first_name}/${user.customer_last_name}/${user.customer_phone_number}/${auth.email}/${auth.uid}/${item.id}`}
												>
													<EditIcon />
												</Link>
												{/* </IconButton> */}
												<IconButton
													color="primary"
													aria-label="edit pending booking"
													onClick={() => {
														setDeleteBookingId(item.id);
														setDeleteBookingModal(true);
													}}
												>
													<DeleteForeverIcon />
												</IconButton>
												{/* {
                                                        typeof item.rejected !== 'undefined' ?
                                                            (<Button variant="text" onClick={() => findLogisticsCompany(allLogisticsCompanies, item.type_of_transport, getCountryNameArray(item.pickup_at.country, item.destination_to.country), item.id, item.rejected,item.distance)}>Find Logistics Company</Button>)
                                                            :
                                                            (<Button variant="text" onClick={() => findLogisticsCompany(allLogisticsCompanies, item.type_of_transport, getCountryNameArray(item.pickup_at.country, item.destination_to.country), item.id,item.distance)}>Find Logistics Company</Button>)
                                                    } */}

												<Button
													variant="text"
													onClick={() =>
														findLogisticsCompany(
															allLogisticsCompanies,
															item.type_of_transport,
															getCountryNameArray(
																item.pickup_at.country,
																item.destination_to.country
															),
															item.id,
															item.booked,
															item.choosen_logistics_companies,
															item.rejected,
															item.distance
														)
													}
												>
													Find Logistics Company
												</Button>
											</div>
										</Grid>
									</Grid>
								</Box>
							</Fragment>
						))}
					</div>
					<div className={styles.pending_booking_bottom_section}></div>
				</TabPanel>
				<TabPanel value={tabIndex} index={1}>
					<Suspense fallback={() => <div>Loading...</div>}>
						<CurrentBookingList data={currentBooking} />
					</Suspense>
					{currentBooking.length === 0 && (
						<div className={styles.no_result}>
							<h6>No Current Booking </h6>
						</div>
					)}
				</TabPanel>
				<TabPanel value={tabIndex} index={2}>
					<Suspense fallback={() => <div>Loading...</div>}>
						<CompletedBookingList data={completedBooking} user={user} />
					</Suspense>
					{completedBooking.length === 0 && (
						<div className={styles.no_result}>
							<h6>No Completed Booking </h6>
						</div>
					)}
				</TabPanel>
			</div>
		</Fragment>
	);
};

// export default CustomerDashBoardWindow;

const mapStateToProps = (state: any) => {
	return {
		allLogisticsCompanies: state.firestore.ordered.LogisticsCompanies,
		allRatingsAndReviews: state.firestore.ordered.RatingsAndReviews,
		allBookings: state.firestore.ordered.Bookings,
		auth: state.firebase.auth,
		signUpInfo: state.auth.signupInfo,
	};
};
const mapDispatchToProps = (dispatch: any) => {
	return {
		clearSignUpInfo: () =>
			dispatch({
				type: "SIGNUP_FEEDBACK",
				payload: { severity: "", message: "" },
			}),
		deleteBooking: (booking_id: string) => dispatch(DeleteBooking(booking_id)),
		// findLogisticsCompaniesForBooking: (p_d_cn: []) => dispatch(FindLogisticsCompany(p_d_cn)),
	};
};

export default compose<any>(
	firestoreConnect((props: any) => [
		{
			collection: "Bookings",
		},
		{
			collection: "LogisticsCompanies",
		},
		{
			collection: "RatingsAndReviews",
		},
	]),
	connect(mapStateToProps, mapDispatchToProps)
)(CustomerDashBoardWindow);
