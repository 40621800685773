


import React, { Fragment, useState, useEffect } from 'react';

//router dom
import { Redirect } from "react-router-dom";
//package
import clsx from 'clsx';
// styles
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
// import CssBaseline from '@material-ui/core/CssBaseline';
// components
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';
import Container from '@material-ui/core/Container';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

// icons
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import CommuteIcon from '@material-ui/icons/Commute';
import MoneyIcon from '@material-ui/icons/Money';
import CardMembership from '@material-ui/icons/CardMembership';
import LocationOn from '@material-ui/icons/LocationOn';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PublicIcon from '@material-ui/icons/Public';
import BusinessIcon from '@material-ui/icons/Business';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';


//connect react 
import { connect } from 'react-redux';
import { compose } from "redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import firebase from '../../config/firebaseConfig';

import Logo from '../../assets/images/logo2.png';

import Loader from '../../assets/images/fastlogisticsloader.gif';

import { ApproveLogisticsCompany } from '../../store/actions/admin/approveLogisticsCompanyAction';
import { BlockLogisticsCompany } from '../../store/actions/admin/blockLogisticsCompanyAction';
import { UnBlockLogisticsCompany } from '../../store/actions/admin/unBlockLogisticsCompanyAction';
// import _ from 'lodash';

import GetImageFromFirebase from '../../components/GetImageFromFirebase';


//material table
import MaterialTable from 'material-table';

//Alert Component
import CustomAlert from '../../components/CustomAlert/CustomAlert';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ImageUploader from '../../components/ImageUploader';



interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (

                <Box>
                    {children}
                </Box>

            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const drawerWidth = 240;



const getLogisticsCompany = (AllLogisticsCompany: any[], id: string) => {
    const contestant = AllLogisticsCompany.filter((item: any) => {
        return item.id === id
    });

    return contestant[0]
}

const getAllLogisticsCompanyTable = (AllLogisticsCompany: any[]) => {
    const result = AllLogisticsCompany.map(item => {
        return {
            company_logo_url: item.company_logo_ID,
            company_name: item.company_name,
            address: item.company_address,
            price_per_mile: item.price_per_mile,
            approved: item.approved,
            blocked: item.blocked,
            company_id: item.id,
            delivery_type:item.type_of_delivery,
        }
    });

    return result
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const adsNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: -drawerWidth,
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
        grow: {
            flexGrow: 1,
        },
        title: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '400px',
            [theme.breakpoints.up('md')]: {
                width: '20ch',
            },
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        logo: {
            width: 150,
            height: 'auto',
            [theme.breakpoints.down('sm')]: {
                width: 100,
            }
        },
        notifiction_appBar: {
            position: 'relative',
        },
        loader_wrapper: {
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        logo_wraper: {
            width: 100,
            height: 'auto',
            '& img': {
                width: '100%',
                height: 'auto'
            }
        },
        no_result: {
            width: '100%',
            height: '200px',
            border: '2px gray solid',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        account_details_wrapper: {
            width: '100%',
            height: 'auto',
            // border: '1px red solid',
            borderRadius: 30,
            marginTop: 10,
            paddingBottom: 50,
            paddingTop: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        },
        company_logo_wrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 2,
            height: '100%',
            '& img': {
                width: 100,
                height: 100,
                borderRadius: 100,
            }
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
        modal_title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
    }),
);

const AdminDashboard: React.FC = ({ auth, allLogisticsCompanies, globalOptions, approveCompany, signUpInfo, clearSignUpInfo, unblockCompany, blockCompany }: any) => {

    const styles = useStyles();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);
    const [menuIndex, setmenuIndex] = useState(0);
    const [pageLoading, setpageLoading] = useState(false);
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const [logisticsCompany, setLogisticsCompany] = useState<any>({});
    const [companydetailsModal, setCompanydetailsModal] = useState(false);
    const [logisticsCompanies, setLogisticsCompanies] = useState<any>([]);
    const [openOperationalCountries, setopenOperationalCountries] = useState(false);
    const [representativedata, setrepresentativedata] = useState(false);
    const [vehcicles, setVehcicles] = useState(false);
    const [cargoVehicles, setcargoVehicles] = useState(false);
    const [approvestate, setApprovestate] = useState(false);
    const [blockstate, setBlockstate] = useState(false);

    const [tabvalue, setTabValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };

    useEffect(() => {

        setTimeout(() => {
            setpageLoading(false)

        }, 2000);
        return () => {
            clearTimeout();
        }
    }, []);

    useEffect(() => {
        if (typeof allLogisticsCompanies !== 'undefined') {
            const result_logistics_companies = getAllLogisticsCompanyTable(allLogisticsCompanies);
            setLogisticsCompanies([...result_logistics_companies]);
        }

    }, [allLogisticsCompanies]);




    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };


    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const closeCompanyDetailModal = () => {
        setCompanydetailsModal(false);
        setApprovestate(false);
    }
    const openCompanyDetailModal = (id: string) => {
        const result = getLogisticsCompany(allLogisticsCompanies, id);
        setApprovestate(result.approved);
        setBlockstate(result.blocked);
        setLogisticsCompany({ ...result });
        setCompanydetailsModal(true);
    }

    const approveLogisticsCompany = (id: string) => {
        approveCompany(id);
        setApprovestate(true);
    }
    const blockLogisticsCompany = (id: string) => {
        blockCompany(id);
        setBlockstate(true);
    }
    const unblockLogisticsCompany = (id: string) => {
        unblockCompany(id);
        setBlockstate(false);
    }




    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >

            <MenuItem onClick={() => firebase.auth().signOut()}>Sign Out</MenuItem>
        </Menu>
    );


    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >

            <MenuItem onClick={() => firebase.auth().signOut()}>Sign Out</MenuItem>
        </Menu>
    );



    if (!isLoaded(auth)) {
        return <div className={styles.loader_wrapper}> PLease wait ...</div>
    } else {
        if (!auth.uid) {
            return <Redirect to="/login" />
        }
    }
    if (!isLoaded(allLogisticsCompanies) && !isLoaded(globalOptions)) {
        return <div className={styles.loader_wrapper}> <img src={Loader} alt="Loader logo" /> </div>
    } else {



    }


    return (
        <Fragment>
            <div className={styles.root}>
                {
                    signUpInfo.severity === 'error' || signUpInfo.severity === 'warning' || signUpInfo.severity === 'info' || signUpInfo.severity === 'success' ? <CustomAlert severity={signUpInfo.severity} message={signUpInfo.message} show={true} clearState={clearSignUpInfo} /> : null
                }
                <Dialog key="compan_details_modal" fullScreen open={companydetailsModal} onClose={closeCompanyDetailModal} TransitionComponent={Transition}>
                    <AppBar className={styles.notifiction_appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={closeCompanyDetailModal} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={styles.modal_title}>
                                Logistics company Details
                                </Typography>
                            <FormControlLabel

                                control={
                                    <Switch
                                        checked={approvestate}
                                        onChange={() => approveLogisticsCompany(logisticsCompany.id)}
                                        name="approveLogisticsCompany"
                                        color="default"
                                        disabled={approvestate}
                                    />
                                }
                                label="Approve"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={blockstate}
                                        onChange={blockstate ? () => unblockLogisticsCompany(logisticsCompany.id) : () => blockLogisticsCompany(logisticsCompany.id)}
                                        name="blockLogisticsCompany"
                                        color="secondary"
                                    />
                                }
                                label="Blocked"
                            />

                        </Toolbar>
                    </AppBar>
                    <Grid container>
                        <Grid item xs={12} lg={3}></Grid>
                        <Grid item xs={12} lg={6}>
                            <div style={{ width: '100%', height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} data-tut="newlogisticsCompany__details">
                                <Box className={styles.account_details_wrapper} boxShadow={10}>
                                    <div className={styles.company_logo_wrapper}>
                                        {logisticsCompany.company_logo_ID === "undefined" ? null : (
                                            <GetImageFromFirebase source={logisticsCompany.company_logo_ID} />
                                        )}
                                    </div>
                                    <List
                                        component="nav"
                                        aria-labelledby="nested-list-subheader"

                                    >
                                        <ListItem >
                                            <ListItemIcon>
                                                <BusinessIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Company Name" secondary={logisticsCompany.company_name} />
                                        </ListItem>
                                        <ListItem >
                                            <ListItemIcon>
                                                <LocationOn />
                                            </ListItemIcon>
                                            <ListItemText primary="Company Address" secondary={logisticsCompany.company_address} />
                                        </ListItem>
                                        <ListItem >
                                            <ListItemIcon>
                                                <CardMembership />
                                            </ListItemIcon>
                                            <ListItemText primary="CAC Number" secondary={logisticsCompany.CACnumber} />
                                        </ListItem>
                                        <ListItem >
                                            <ListItemIcon>
                                                <MoneyIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Price per Mile" secondary={'₦ ' + logisticsCompany.price_per_mile + '.00'} />
                                        </ListItem>
                                        <ListItem >
                                            <ListItemIcon>
                                                {logisticsCompany.type_of_delivery === 'Human' ? < CommuteIcon /> : < CommuteIcon />}
                                            </ListItemIcon>
                                            <ListItemText primary="Type of Delivery" secondary={logisticsCompany.type_of_delivery === 'Human' ? 'Human transportation' : 'Cargo Delivery'} />
                                        </ListItem>
                                        {
                                            typeof logisticsCompany.type_of_delivery !== 'undefined' && logisticsCompany.type_of_delivery === 'Human' ? (
                                                <Fragment>
                                                    <ListItem button onClick={() => setVehcicles(!vehcicles)}>
                                                        <ListItemIcon>
                                                            < EmojiTransportationIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Vehicles" />
                                                        {vehcicles ? <ExpandLess /> : <ExpandMore />}
                                                    </ListItem>
                                                    <Collapse in={vehcicles} timeout="auto" unmountOnExit>
                                                        <List component="div" disablePadding>
                                                            {
                                                                typeof logisticsCompany.type_of_human_delivery_option !== 'undefined' ?
                                                                    logisticsCompany.type_of_human_delivery_option.map((item: any) =>
                                                                        <ListItem key={item} className={styles.nested}>
                                                                            <ListItemText primary={item + ' Seater'} />
                                                                        </ListItem>) : null
                                                            }
                                                        </List>
                                                    </Collapse>
                                                </Fragment>
                                            ) : (
                                                    <Fragment>
                                                        <ListItem button onClick={() => setcargoVehicles(!cargoVehicles)}>
                                                            <ListItemIcon>
                                                                < LocalShippingIcon />
                                                            </ListItemIcon>
                                                            <ListItemText primary="Delivery Vehicles" />
                                                            {cargoVehicles ? <ExpandLess /> : <ExpandMore />}
                                                        </ListItem>
                                                        <Collapse in={cargoVehicles} timeout="auto" unmountOnExit>
                                                            <List component="div" disablePadding>
                                                                {
                                                                    typeof logisticsCompany.type_of_cargo_delivery_option !== 'undefined' ?
                                                                        logisticsCompany.type_of_cargo_delivery_option.map((item: any) =>
                                                                            <ListItem key={item} className={styles.nested}>
                                                                                <ListItemText primary={item + ' Delivery'} />
                                                                            </ListItem>) : null
                                                                }
                                                            </List>
                                                        </Collapse>
                                                    </Fragment>
                                                )
                                        }

                                        <ListItem button onClick={() => setopenOperationalCountries(!openOperationalCountries)}>
                                            <ListItemIcon>
                                                <PublicIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Operational Countries" />
                                            {openOperationalCountries ? <ExpandLess /> : <ExpandMore />}
                                        </ListItem>
                                        <Collapse in={openOperationalCountries} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {
                                                    typeof logisticsCompany.operational_countries !== 'undefined' ?
                                                        logisticsCompany.operational_countries.map((item: any) =>
                                                            <ListItem key={item} className={styles.nested}>
                                                                <ListItemText primary={item} />
                                                            </ListItem>) : null
                                                }
                                            </List>
                                        </Collapse>
                                        <ListItem button onClick={() => setrepresentativedata(!representativedata)}>
                                            <ListItemIcon>
                                                <RecentActorsIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Your Representative info" />
                                            {representativedata ? <ExpandLess /> : <ExpandMore />}
                                        </ListItem>
                                        <Collapse in={representativedata} timeout="auto" unmountOnExit>
                                            {
                                                typeof logisticsCompany.representative_data !== 'undefined' ? (
                                                    <List component="div" disablePadding>
                                                        <ListItem className={styles.nested}>
                                                            <ListItemText primary="Name" secondary={logisticsCompany.representative_data.name} />
                                                        </ListItem>
                                                        <ListItem className={styles.nested}>
                                                            <ListItemText primary="Position" secondary={logisticsCompany.representative_data.position} />
                                                        </ListItem>
                                                        <ListItem className={styles.nested}>
                                                            <ListItemText primary="Email" secondary={logisticsCompany.representative_data.email} />
                                                        </ListItem>
                                                        <ListItem className={styles.nested}>
                                                            <ListItemText primary="Phone Number" secondary={logisticsCompany.representative_data.phone_number} />
                                                        </ListItem>
                                                    </List>

                                                ) : (null)
                                            }



                                        </Collapse>


                                    </List>
                                </Box>
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={3}></Grid>
                    </Grid>
                </Dialog>
                <AppBar
                    position="fixed"
                    className={clsx(styles.appBar, {
                        [styles.appBarShift]: open,
                    })}
                    style={{ backgroundColor: '#cce0ff' }}
                >
                    <Container>
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                className={clsx(styles.menuButton, open && styles.hide)}
                            >
                                <MenuIcon />
                            </IconButton>
                            {pageLoading ? (
                                <Skeleton width={100} height={35} variant="rect" />


                            ) : (
                                    <img className={styles.logo} src={Logo} alt="Fast logistics logo" />
                                )}


                            <div className={styles.grow} />
                            <div className={styles.sectionDesktop}>

                                <IconButton
                                    edge="end"
                                    aria-label="account of current logisticsCompany"
                                    aria-controls={menuId}
                                    aria-haspopup="true"
                                    onClick={handleProfileMenuOpen}
                                    color="primary"
                                >
                                    <AccountCircle />
                                </IconButton>
                            </div>
                            <div className={styles.sectionMobile}>
                                <IconButton
                                    aria-label="show more"
                                    aria-controls={mobileMenuId}
                                    aria-haspopup="true"
                                    onClick={handleMobileMenuOpen}
                                    color="primary"
                                >
                                    <MoreIcon />
                                </IconButton>
                            </div>
                        </Toolbar>
                    </Container>
                </AppBar>
                {renderMobileMenu}
                {renderMenu}
                <div data-tut="newlogisticsCompany__menu">
                    <Drawer
                        className={styles.drawer}
                        variant="persistent"
                        anchor="left"
                        open={open}
                        classes={{
                            paper: styles.drawerPaper,
                        }}
                    >
                        <div className={styles.toolbar}>
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                            </IconButton>
                        </div>
                        <Divider />
                        <List>
                            <ListItem button onClick={() => setmenuIndex(0)} selected={menuIndex === 0 ? true : false}>
                                <ListItemIcon><DashboardTwoToneIcon /></ListItemIcon>
                                <ListItemText primary="Dashboard" />
                            </ListItem>

                        </List>
                        <Divider />
                        <List>

                        </List>
                        <Divider />
                        <List>

                            <ListItem button onClick={() => setmenuIndex(1)} selected={menuIndex === 1 ? true : false}>
                                <ListItemIcon><AccountCircleTwoToneIcon /></ListItemIcon>
                                <ListItemText primary="Account" />
                            </ListItem>
                        </List>
                        <Divider />
                        <List>
                            <ListItem button onClick={() => setmenuIndex(2)} selected={menuIndex === 2 ? true : false}>
                                <ListItemIcon><SettingsTwoToneIcon /></ListItemIcon>
                                <ListItemText primary="Settings" />
                            </ListItem>
                        </List>
                    </Drawer>
                </div>

                <main className={styles.content}>

                    {/* {
                        menuIndex === 0 && <DashBoardWindow logisticsCompany={currentlogisticsCompany} />
                    } */}



                    <Grid container>
                        <Grid item xs={12} lg={1}></Grid>
                        <Grid item xs={12} lg={10}>
                            <Typography variant="h3" align="center" style={{ marginTop: 100 }}>Welcome Admin</Typography>
                            <AppBar position="static">
                                <Tabs value={tabvalue} onChange={handleChange} aria-label="admin tabs">
                                    <Tab label="Manage Logistics companies" {...a11yProps(0)} />
                                    <Tab label="Manage ads" {...a11yProps(1)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={tabvalue} index={0}>
                                <Box className={styles.root} boxShadow={5}>
                                    <MaterialTable
                                        style={{ width: '100%', boxShadow: '0px 0px', backgroundColor: theme.palette.background.default }}

                                        title="All Logistics Company"
                                        columns={[

                                            { title: 'Logo', field: 'company_logo_url', render: rowData => <div className={styles.logo_wraper}><GetImageFromFirebase source={rowData.company_logo_url} /></div> },
                                            { title: 'Company Name', field: 'company_name' },
                                            { title: 'Address', field: 'address' },
                                            { title: 'Delivery type', field: 'delivery_type' },
                                            { title: 'Price per mile', field: 'price_per_mile', type: 'numeric' },
                                            { title: 'Approval', field: 'approved', type: 'boolean' },
                                            { title: 'Blocked', field: 'blocked', type: 'boolean' },
                                            { field: 'company_id', hidden: true }

                                        ]}
                                        data={logisticsCompanies}

                                        options={{
                                            search: true,
                                            actionsColumnIndex: -1
                                        }}

                                        actions={[
                                            {
                                                icon: 'save',
                                                tooltip: 'Save logisticsCompany',

                                                onClick: (event, rowData: any) => openCompanyDetailModal(rowData.company_id)

                                            },

                                        ]}

                                        components={{

                                            Action: props => (
                                                <Button aria-label="view-vendor-details" size="medium" onClick={(event) => props.action.onClick(event, props.data)}>
                                                    View
                                                </Button>

                                            ),

                                        }}

                                    />
                                </Box>
                            </TabPanel>
                            <TabPanel value={tabvalue} index={1}>
                                <Grid container>
                                    <Grid item xs={12} lg={1}></Grid>
                                    <Grid item xs={12} lg={10}>

                                        <div style={{ marginBottom: 50 }} />
                                        {
                                            adsNumber.map((i: number) => {
                                                return <Fragment key={i}>
                                                    <Grid container>
                                                        <Grid item xs={12} lg={6}>
                                                            <ImageUploader onRequestSave={(id: string) => alert(`Uploaded  Ad ${i}`)} onRequestClear={() => console.log('cleared')} existingImageId={`ad_${i}`} />
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <div style={{ marginBottom: 30 }} />
                                                            <Typography variant="h5" align="center">{
                                                                `Ad ${i}`
                                                            }</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Divider />
                                                </Fragment>
                                            })
                                        }





                                    </Grid>
                                    <Grid item xs={12} lg={1}></Grid>
                                </Grid>
                            </TabPanel>
                        </Grid>
                        <Grid item xs={12} lg={1}></Grid>
                    </Grid>

                </main>
            </div>


        </Fragment>
    )
}



const mapStateToProps = (state: any) => {

    return {
        auth: state.firebase.auth,
        allLogisticsCompanies: state.firestore.ordered.LogisticsCompanies,
        globalOptions: state.firestore.ordered.globalOptions,
        signUpInfo: state.auth.signupInfo,

    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        approveCompany: (id: string) => dispatch(ApproveLogisticsCompany(id)),
        blockCompany: (id: string) => dispatch(BlockLogisticsCompany(id)),
        unblockCompany: (id: string) => dispatch(UnBlockLogisticsCompany(id)),
        clearSignUpInfo: () => dispatch({ type: 'SIGNUP_FEEDBACK', payload: { severity: '', message: '' } }),
    };
};



export default compose<any>(
    firestoreConnect((props: any) => [
        {
            collection: 'LogisticsCompanies',

        },



    ]),
    connect(mapStateToProps, mapDispatchToProps)
)(AdminDashboard)