import { INVALID_EMAIL,USER_DISABLED, USER_NOT_FOUND, WRONG_PASSWORD, SIGNIN_FEEDBACK,SignInValues} from './signinTypes';


export const SignIn  = (credentials:SignInValues) => (dispatch: any, getState: any, { getFirebase }: any)=>{
    const firebase = getFirebase();
    firebase.auth().signInWithEmailAndPassword(credentials.email, credentials.password)
    .catch((error:any)=>{
        var errorCode = error.code;
        //    var errorMessage = error.message;

        switch (errorCode) {
            case USER_DISABLED:
                dispatch({ type: SIGNIN_FEEDBACK, payload: { severity: 'error', message: "Oops! This user has been disabled" } })
                break;
            case USER_NOT_FOUND:
                dispatch({ type: SIGNIN_FEEDBACK, payload: { severity: 'error', message: "Oops! for some reasons this user is not found" } })
                break;
            case INVALID_EMAIL:
                dispatch({ type: SIGNIN_FEEDBACK, payload: { severity: 'error', message: "Oops! This email is invalid! Check the format and try again" } })
                break;
            case WRONG_PASSWORD:
                dispatch({ type: SIGNIN_FEEDBACK, payload: { severity: 'warning', message: "Oops! Your password is wrong! try again " } })
                break;
            default:
                break;
        }
    })

}