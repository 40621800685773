import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';
import { EMAIL_ALREADY_EXISTS, OPERATION_NOT_ALLOWED, INVALID_EMAIL, WEAK_PASSWORD, } from "../../../../ts/types/errors";
import { SIGNUP_FEEDBACK } from "../../../../ts/types/signup";


export const AdminSignupAction = (creden:{email:string;password:string;username:string;}): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

        try {
            const response = await firebase.auth().createUserWithEmailAndPassword(creden.email,creden.password);
            const createCollectionPromise = firebase.firestore().collection('Admin').doc(response.user.uid).set({
               username:creden.username,
               email:creden.email
            });
            const updateProfilePromise = firebase.auth().currentUser.updateProfile({
                displayName: "admin",
               
            });
            const sendEmailPromise = firebase.auth().currentUser.sendEmailVerification();

            const allPromise = Promise.all([createCollectionPromise, updateProfilePromise, sendEmailPromise]);

            allPromise.then(() => {
                dispatch({ type: SIGNUP_FEEDBACK, payload: { severity: 'success', message: "Congratulations! Your Account has been created! A verification link has been sent to your email." } });
           
            })



        } catch (error) {
            var errorCode = error.code;
            //    var errorMessage = error.message;

            switch (errorCode) {
                case EMAIL_ALREADY_EXISTS:
                    dispatch({ type: SIGNUP_FEEDBACK, payload: { severity: 'error', message: "Oops! An existing account is already using this email! Use another email" } })
                    break;
                case OPERATION_NOT_ALLOWED:
                    dispatch({ type: SIGNUP_FEEDBACK, payload: { severity: 'error', message: "Oops! The developers of this app did not activate this feature!A report would be sent to the owners " } })
                    break;
                case INVALID_EMAIL:
                    dispatch({ type: SIGNUP_FEEDBACK, payload: { severity: 'error', message: "Oops! This email is invalid! Check the format and try again" } })
                    break;
                case WEAK_PASSWORD:
                    dispatch({ type: SIGNUP_FEEDBACK, payload: { severity: 'warning', message: "Oops! Your password is too weak! try another password! " } })
                    break;
                default:
                    break;
            }
        }
    }