// import { IBooking } from "../../../ts/interfaces/booking";
import { SendNotification } from '../notification/sendNotificationAction';
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';

export const PendingBooking = (pending_booking: any): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

        firebase.firestore().collection('Bookings').doc().set({
            customer_id: pending_booking.customer_id,
            logistics_company_id: '',
            persona: pending_booking.persona,
            pickup_at: {
                address: pending_booking.pickup_at.address,
                country: pending_booking.pickup_at.country,
                state: pending_booking.pickup_at.state,
                city: pending_booking.pickup_at.city,
            },
            destination_to: {
                address: pending_booking.destination_to.address,
                country: pending_booking.destination_to.country,
                state: pending_booking.destination_to.state,
                city: pending_booking.destination_to.city
            },
            sender: {
                name: pending_booking.sender.name,
                phone_number: pending_booking.sender.phone_number,
                email: pending_booking.sender.email,
            },
            reciever: {
                name: pending_booking.reciever.name,
                phone_number: pending_booking.reciever.phone_number,
                email: pending_booking.reciever.email,
            },
            third_party: pending_booking.third_party,
            items: {
                catergory: pending_booking.items.catergory,
                description: pending_booking.items.description,
                quantity: pending_booking.items.quantity,
                weight: pending_booking.items.weight,
                length: pending_booking.items.length,
                breadth: pending_booking.items.breadth,
                height: pending_booking.items.height,
            },
            pending: {
                status: true,
                on: firebase.firestore.FieldValue.serverTimestamp(),
            },
            new: {
                status: false,
            },
            current:{
                status:false,
            },
            created_on: firebase.firestore.FieldValue.serverTimestamp(),
            // price_per_mile: pending_booking.price_per_mile,
            // miles: pending_booking.miles,
            number_of_seats: pending_booking.number_of_seats,
            type_of_transport: pending_booking.type_of_transport,
            delivery_of_item_type: pending_booking.delivery_of_item_type,
            item_check_list: {
                have_you_put_name_of_reciever_on_the_parcel: pending_booking.item_check_list.have_you_put_name_of_reciever_on_the_parcel,
                have_you_put_mobile_number_of_reciever_on_the_parcel: pending_booking.item_check_list.have_you_put_mobile_number_of_reciever_on_the_parcel,
                have_you_ensured_the_parcel_is_secured: pending_booking.item_check_list.have_you_ensured_the_parcel_is_secured,
                position_of_item: pending_booking.item_check_list.position_of_item,

            },
            distance:pending_booking.distance,
            distance_text:pending_booking.distance_text,
            duration:pending_booking.duration,
            rejected:[]

        })
            .then(() => {
                dispatch({ type: 'SIGNUP_FEEDBACK', payload: { severity: 'success', message: "New booking added!" } });
                dispatch(SendNotification({
                    to:  pending_booking.customer_id,
                    subject: 'New Booking Added!',
                    body: 'You have added a new booking, its still in pending phase until you pick a logistics company',
                    severity: 'info'
                }));
            })
            .catch(() => {
                dispatch({ type: 'SIGNUP_FEEDBACK', payload: { severity: 'error', message: "Oops! Sorry an error occured , please try again" } });
            })

    }