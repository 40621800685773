import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Redirect } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
//formik
import { Formik, Form, Field } from 'formik';
import { Button, LinearProgress, FormControlLabel, Box } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Autocomplete } from 'formik-material-ui-lab';
import { default as MyTextField } from '@material-ui/core/TextField';
// import { , CheckboxWithLabel } from 'formik-material-ui';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import { RadioGroup } from 'formik-material-ui';

//icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EmailIcon from '@material-ui/icons/Email';
import Call from '@material-ui/icons/Call';
// import LocationOn from '@material-ui/icons/LocationOn';
import {
    useParams
} from "react-router-dom";
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { Select } from 'formik-material-ui';

import BGImage from '../../assets/images/pexels-photo-4393444.jpeg';
//Alert Component
import CustomAlert from '../CustomAlert/CustomAlert';



//@ts-ignore
import CountryStateCityJson from 'countrycitystatejson';

//yup
import * as yup from 'yup';

import ParcelDImension from '../../assets/images/parcel_dimension.jpg';

//signupinfo type
import { SIGNUP_FEEDBACK } from "../../store/actions/auth/signup/signupTypes";


//connect react 
import { connect } from 'react-redux';
import Logo from '../../assets/images/logo.svg';

import { IBooking } from '../../ts/interfaces/booking';
import { ICustomerAccount } from '../../ts/interfaces/customer';

//images
import BikeImage from '../../assets/images/bike.png';
import MotorBikeImage from '../../assets/images/motorbike.jpg';
import VanImage from '../../assets/images/van.png';
import TruckImage from '../../assets/images/truck.jpg';
import AirPlaneImage from '../../assets/images/airplane.jpg';
import CargoShipImage from '../../assets/images/cargoship.jpg';
import SaloonCarImage from '../../assets/images/salooncar.jpg';
import PowerBikeImage from '../../assets/images/powerbike.jpg';
import SiennaImage from '../../assets/images/sienna.jpg';
import KekeImage from '../../assets/images/keke.jpg';
import HiluxImage from '../../assets/images/hilux.jpg';
import CoasterImage from '../../assets/images/coaster.jpg';
import TourImage from '../../assets/images/tourbus.jpg';

import { PendingBooking } from '../../store/actions/booking/pendingBookingAction';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

import axios from 'axios';

import _ from 'lodash';



interface ICustomerSignUpAndBook extends IBooking, ICustomerAccount {

}

//signup yup schema
const CustomerValidationSchema = yup.object().shape({
    customer_first_name: yup
        .string()
    ,
    customer_last_name: yup
        .string()
    ,
    email: yup
        .string()
        .email()

    ,

    persona: yup
        .string()
    ,
    type_of_transport: yup.string(),
    pickup_at: yup.object({
        address: yup.string(),
        country: yup.string(),
        state: yup.string(),
        city: yup.string(),
    }),
    destination_to: yup.object({
        address: yup.string(),
        country: yup.string(),
        state: yup.string(),
        city: yup.string(),
    }),
    sender: yup.object({
        name: yup.string(),
        phone_number: yup.string(),
        email: yup.string(),
    }),
    reciever: yup.object({
        name: yup.string(),
        phone_number: yup.string(),
        email: yup.string(),
    }),
    items: yup.object({
        catergory: yup.string(),
        description: yup.string(),
        quantity: yup.number(),
        weight: yup.number(),
        length: yup.number(),
        breadth: yup.number(),
        height: yup.number(),
    }),
    number_of_seats: yup.string(),
    delivery_of_item_type: yup.string(),
    price_per_mile: yup.number(),
    miles: yup.number(),
    item_check_list: yup.object({
        have_you_put_name_of_reciever_on_the_parcel: yup.string(),
        have_you_put_mobile_number_of_reciever_on_the_parcel: yup.string(),
        have_you_ensured_the_parcel_is_secured: yup.string(),
        position_of_item: yup.string(),

    }),

})

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        button: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
        signup_wrapper: {
            height: "99.2vh",
            width: "99.8vw"
        },
        signup_form_wrapper: {
            width: "100%",
            height: "auto",
            marginTop: theme.spacing(10),
            backgroundColor: "white",
            paddingBottom: 20,
        },
        signup_top_section: {
            height: 50,
            paddingTop: 20,
        },
        signup_middle_section: {
            paddingLeft: "50px",
            paddingRight: "50px",
            paddingTop: "20px"

        },
        signup_bottom_section: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "20px",
            paddingLeft: "30px",
            paddingRight: "30px",
        },
        page_title: {
            fontWeight: "bolder",
        },
        form_field: {
            // marginBottom: "50px",
        },
        delivery_options: {
            width: '98%',
            height: 150,
            backgroundColor: 'white',
            borderRadius: 10,
            marginBottom: 10,
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            flexDirection: 'row',
            '& img': {
                width: 120,
                height: 120,
            }
        },
        bg_image_wrapper: {
            position: 'absolute',
            right: 0,
            top: 0,
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& div ': {
                width: 200,
                height: 100,
                backgroundColor: 'white',
                boxShadow: theme.shadows[11],
                '& img': {
                    width: '100%'
                }
            }
        },
        icon: {
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(2),
        },
    }),
);


function isBlank(value: any) {
    const result = _.isEmpty(value) && !_.isNumber(value);
    return result || _.isNaN(value);
}

function loadScript(src: string, position: HTMLElement | null, id: string) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };


interface PlaceType {
    description: string;
    structured_formatting: {
        main_text: string;
        secondary_text: string;
        main_text_matched_substrings: [
            {
                offset: number;
                length: number;
            },
        ];
    };
}




function getSteps() {
    return ['What do you want to transport?', 'Parcel checklist', "Reciever's details", 'Your contact details'];
}




function AddNewPendingBooking({ signUpInfo, clearSignUpInfo, NewPendingBooking }: any) {
    const styles = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [typeoftransport, settypeoftransport] = useState(0);

    // const [nextButtonState, setnextButtonState] = useState(true);
    const [isThirdParty, setisThirdParty] = useState(false);
    const [Persona, setPersona] = useState(0);
    // const [pickupState, setpickupState] = useState<any[]>([]);
    // const [pickupCity, setpickupCity] = useState<any[]>([]);
    // const [disablePickupState, setdisablePickupState] = useState(true);
    // const [disablePickupCity, setdisablePickupCity] = useState(true);
    // const [destinationState, setdestinationState] = useState<any[]>([]);
    // const [destinationpCity, setdestinationCity] = useState<any[]>([]);
    // const [disableDestinationState, setdisableDestinationState] = useState(true);
    // const [disableDestinationCity, setdisableDestinationCity] = useState(true);

    const [pickUpAddressValue, setPickUpAddressValue] = React.useState<PlaceType | null>(null);
    const [pickUpAddressInputValue, setPickUpAddressInputValue] = React.useState('');
    const [pickUpAddressoOptions, setPickUpAddressOptions] = React.useState<PlaceType[]>([]);
    const [destinationAddressValue, setDestinationAddressValue] = React.useState<PlaceType | null>(null);
    const [destinationAddressInputValue, setDestinationAddressInputValue] = React.useState('');
    const [destinationAddressoOptions, setDestinationAddressoOptions] = React.useState<PlaceType[]>([]);
    const destinationAddressLoaded = React.useRef(false);
    const pickUpAddressLoaded = React.useRef(false);

    const [distance, setDistance] = useState(0);
    const [distanecText, setDistanecText] = useState('');
    const [duration, setDuration] = useState('');

    const [pickUpCountry, setPickUpCountry] = useState('');
    const [pickUpCountryInputValue, setPickUpCountryInputValue] = useState('');

    const [destinationCountry, setDestinationCountry] = useState('');
    const [destinationCountryInputValue, setDestinationCountryInputValue] = useState('');


    useEffect(() => {

        if (!isBlank(pickUpAddressValue) && !isBlank(destinationAddressValue)) {
            // const result = fetchData(urlToCloudFunction);
            const fetchData = async () => {
                const response = await axios(`https://us-central1-fastlogisticsng-6184a.cloudfunctions.net/expressWidgets/getDistance/pickup_at/${pickUpAddressValue?.description}/destination_to/${destinationAddressValue?.description}`);
                const json = await response.data;
                // console.log(json);

                setDistance(json.distance);
                setDistanecText(json.distanceText);
                setDuration(json.duration);
            }

            fetchData();

        }

    }, [pickUpAddressValue, destinationAddressValue]);

    if (typeof window !== 'undefined' && !pickUpAddressLoaded.current && !destinationAddressLoaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                'https://maps.googleapis.com/maps/api/js?key=AIzaSyAYVMr5WMxDnEeCTyhT55qW_XDiAJt9Qds&libraries=places',
                document.querySelector('head'),
                'google-maps',
            );
        }

        pickUpAddressLoaded.current = true;
        destinationAddressLoaded.current = true;
    }

    const fetch = React.useMemo(
        () =>
            throttle((request: { input: string }, callback: (results?: PlaceType[]) => void) => {
                (autocompleteService.current as any).getPlacePredictions(request, callback);
            }, 200),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && (window as any).google) {
            autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (pickUpAddressInputValue === '') {
            setPickUpAddressOptions(pickUpAddressValue ? [pickUpAddressValue] : []);
            return undefined;
        }

        fetch({ input: pickUpAddressInputValue }, (results?: PlaceType[]) => {
            if (active) {
                let newOptions = [] as PlaceType[];

                if (pickUpAddressValue) {
                    newOptions = [pickUpAddressValue];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setPickUpAddressOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [pickUpAddressValue, pickUpAddressInputValue, fetch]);

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && (window as any).google) {
            autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (destinationAddressInputValue === '') {
            setDestinationAddressoOptions(destinationAddressValue ? [destinationAddressValue] : []);
            return undefined;
        }

        fetch({ input: destinationAddressInputValue }, (results?: PlaceType[]) => {
            if (active) {
                let newOptions = [] as PlaceType[];

                if (destinationAddressValue) {
                    newOptions = [destinationAddressValue];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setDestinationAddressoOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [destinationAddressValue, destinationAddressInputValue, fetch]);


    const steps = getSteps();
    let { firstname, lastname, phonenumber, customeremail, customerid } = useParams<any>();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        // setnextButtonState(true);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = (resetForm: any) => {
        setActiveStep(0);
        resetForm();
    };




    return (
        <div className={styles.root}>
            {
                signUpInfo.severity === 'error' || signUpInfo.severity === 'warning' || signUpInfo.severity === 'info' || signUpInfo.severity === 'success' ? <CustomAlert severity={signUpInfo.severity} message={signUpInfo.message} show={true} clearState={clearSignUpInfo} /> : null
            }
            {
                signUpInfo.severity === 'success' ? <Redirect to="/CustomerDashboard" /> : null
            }
            <AppBar position="static" style={{ backgroundColor: 'white' }} elevation={0}>
                <Container>
                    <Toolbar>
                        <Breadcrumbs aria-label="breadcrumb" separator="›">
                            <Link color="inherit" href="/CustomerDashboard">
                                Dashboard
                            </Link>
                            <Typography color="textPrimary">New booking</Typography>
                        </Breadcrumbs>
                    </Toolbar>
                </Container>
            </AppBar>
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <div style={{ position: 'relative' }}>
                        <div className={styles.bg_image_wrapper} style={{ backgroundImage: `url(${BGImage})`, width: "100%", height: '100vh', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                            <div>
                                <img src={Logo} alt="fastlogistics " />
                            </div>
                        </div>
                    </div>

                </Grid>
                <Grid item xs={12} lg={6}>
                    <div style={{ width: '100%', height: '100vh', overflowX: 'hidden', overflowY: 'auto', }}>
                        <Typography variant="h4" align="center" >Find Logistics Companies close to you</Typography>
                        <Formik
                            initialValues={{
                                customer_id: customerid,
                                customer_first_name: firstname,
                                customer_last_name: lastname,
                                customer_phone_number: phonenumber,

                                email: customeremail,

                                persona: '',
                                type_of_transport: '',
                                pickup_at: {
                                    address: '',
                                    country: '',
                                    state: '',
                                    city: '',
                                },
                                destination_to: {
                                    address: '',
                                    country: '',
                                    state: '',
                                    city: '',
                                },
                                sender: {
                                    name: '',
                                    phone_number: '',
                                    email: '',
                                },
                                reciever: {
                                    name: '',
                                    phone_number: '',
                                    email: '',
                                },
                                third_party: false,
                                items: {
                                    catergory: '',
                                    description: '',
                                    quantity: 0,
                                    weight: 0,
                                    length: 0,
                                    breadth: 0,
                                    height: 0,
                                },
                                number_of_seats: '',
                                delivery_of_item_type: '',
                                item_check_list: {
                                    have_you_put_name_of_reciever_on_the_parcel: '',
                                    have_you_put_mobile_number_of_reciever_on_the_parcel: '',
                                    have_you_ensured_the_parcel_is_secured: '',
                                    position_of_item: '',

                                },
                                distance: distance,
                                distance_text: distanecText,
                                duration: duration

                            }}
                            validate={values => {
                                const errors: Partial<ICustomerSignUpAndBook> = {};

                                if (values.type_of_transport) {
                                    switch (values.type_of_transport) {
                                        case 'Parcel':
                                            settypeoftransport(1);
                                            if (values.delivery_of_item_type !== '' && values.items.length > 2 && values.items.breadth > 2 && values.items.height > 2 && values.items.weight > 0 && values.items.quantity > 0) {
                                                // setnextButtonState(false);
                                            }
                                            break;
                                        case 'Human':
                                            settypeoftransport(2);
                                            if (values.number_of_seats !== '') {
                                                // setnextButtonState(true);
                                                setActiveStep(4);
                                            }
                                            break;
                                        default:
                                            settypeoftransport(0)
                                    }
                                }
                                if (values.persona) {
                                    switch (values.persona) {
                                        case 'sender':
                                            // setnextButtonState(true);
                                            setPersona(1);
                                            setisThirdParty(false);
                                            if (values.reciever.name !== '' && values.reciever.phone_number !== '' && values.reciever.email !== '') {
                                                // setnextButtonState(false);
                                            }
                                            break;
                                        case 'reciever':
                                            // setnextButtonState(true);
                                            setPersona(2);
                                            setisThirdParty(false);
                                            if (values.sender.name !== '' && values.sender.phone_number !== '' && values.sender.email !== '') {
                                                // setnextButtonState(false);
                                            }
                                            break;
                                        case 'third-party':
                                            // setnextButtonState(true);
                                            setisThirdParty(true);
                                            setPersona(3);
                                            if (values.sender.name !== '' && values.sender.phone_number !== '' && values.sender.email !== '' && values.reciever.name !== '' && values.reciever.phone_number !== '' && values.reciever.email !== '') {
                                                // setnextButtonState(false);
                                            }
                                            break;
                                        default:
                                            settypeoftransport(0)
                                    }
                                }

                                if (values.item_check_list.have_you_ensured_the_parcel_is_secured !== '' && values.item_check_list.have_you_put_mobile_number_of_reciever_on_the_parcel !== '' && values.item_check_list.have_you_put_name_of_reciever_on_the_parcel !== '' && values.item_check_list.position_of_item !== '') {
                                    // setnextButtonState(false);
                                }



                                // if (pickUpCountry) {

                                //     let pickup_states = CountryStateCityJson.getStatesByShort(pickUpCountry);
                                //     setpickupState([...pickup_states]);
                                //     setdisablePickupState(false);
                                // }


                                // if (values.pickup_at.state) {
                                //     let pickup_cities = CountryStateCityJson.getCities(pickUpCountry, values.pickup_at.state);
                                //     setpickupCity([...pickup_cities]);
                                //     setdisablePickupCity(false);
                                // }
                                // if (destinationCountry) {
                                //     let destination_states = CountryStateCityJson.getStatesByShort(destinationCountry);
                                //     setdestinationState([...destination_states]);
                                //     setdisableDestinationState(false);
                                // }

                                // if (values.destination_to.state) {
                                //     let destination_cities = CountryStateCityJson.getCities(destinationCountry, values.destination_to.state);
                                //     setdestinationCity([...destination_cities]);
                                //     setdisableDestinationCity(false);
                                // }

                                if (values.pickup_at.address !== '' && values.pickup_at.country !== '' && values.pickup_at.state !== '' && values.pickup_at.city !== '' && values.destination_to.address !== '' && values.destination_to.country !== '' && values.destination_to.state !== '' && values.destination_to.city !== '') {
                                    // setnextButtonState(false);
                                }


                                if (values.customer_first_name && values.customer_last_name && values.customer_phone_number && values.email) {
                                    // setnextButtonState(false);
                                }



                                return errors;
                            }}
                            validateOnBlur={true}
                            validationSchema={CustomerValidationSchema}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                setTimeout(() => {
                                    setSubmitting(false);
                                    values.third_party = isThirdParty;
                                    if (values.persona) {
                                        switch (values.persona) {
                                            case 'sender':
                                                values.sender.name = values.customer_first_name + ' ' + values.customer_last_name;
                                                values.sender.email = values.email;
                                                values.sender.phone_number = values.customer_phone_number;
                                                break;
                                            case 'reciever':
                                                values.reciever.name = values.customer_first_name + ' ' + values.customer_last_name;
                                                values.reciever.email = values.email;
                                                values.reciever.phone_number = values.customer_phone_number;
                                                break;

                                            default:

                                        }
                                    }

                                    //@ts-ignore
                                    values.pickup_at.address = pickUpAddressValue.description;
                                    //@ts-ignore
                                    values.destination_to.address = destinationAddressValue.description;
                                    // handlegetDistance(values.pickup_at.address, values.destination_to.address);
                                    values.distance = distance;
                                    values.distance_text = distanecText;
                                    values.duration = duration;

                                    values.pickup_at.country = pickUpCountry;
                                    values.destination_to.country = destinationCountry;
                                    // alert(JSON.stringify(values, null, 2));
                                    NewPendingBooking(values);
                                    resetForm();
                                    // console.log(values);

                                }, 500);
                            }}
                        >
                            {({ submitForm, isSubmitting, resetForm }) => (
                                <Form>



                                    <Stepper activeStep={activeStep} orientation="vertical">
                                        <Step key="0" active={activeStep === 0 ? true : false}>
                                            <StepLabel key="0.1">What is your role?</StepLabel>
                                            <StepContent key="0.2">
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="the_persona">Who are you in the delivery chain</InputLabel>
                                                    <Field
                                                        component={Select}
                                                        name="persona"
                                                        inputProps={{
                                                            id: 'the_persona',
                                                        }}
                                                    >
                                                        <MenuItem value="sender">Sender (You are booking the delivery)</MenuItem>
                                                        <MenuItem value="reciever">Reciever (You are reciever of the delivery)</MenuItem>
                                                        <MenuItem value="third-party">Third Party (You are booking the delivery on behalf of another person )</MenuItem>

                                                    </Field>
                                                </FormControl>
                                                {
                                                    Persona === 1 || Persona === 3 ? (
                                                        <Fragment>
                                                            <br />
                                                            <br />
                                                            <Typography variant='h6'>Recievers Details</Typography>
                                                            <Field
                                                                component={TextField}
                                                                name="reciever.name"
                                                                type="text"
                                                                label="Reciever's Name"
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <AccountCircleIcon />
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                fullWidth
                                                                className={styles.form_field}
                                                            />
                                                            <br />
                                                            <Field
                                                                component={TextField}
                                                                name="reciever.phone_number"
                                                                type="text"
                                                                label="Reciever's Phone Number"
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <Call />
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                fullWidth
                                                                className={styles.form_field}
                                                            />
                                                            <br />
                                                            <Field
                                                                component={TextField}
                                                                name="reciever.email"
                                                                type="email"
                                                                label="Reciever's Email"
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <EmailIcon />
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                fullWidth
                                                                className={styles.form_field}
                                                            />
                                                            <br />

                                                        </Fragment>
                                                    ) : (null)
                                                }

                                                {
                                                    Persona === 2 || Persona === 3 ? (
                                                        <Fragment>
                                                            <br />
                                                            <br />
                                                            <Typography variant='h6'>Sender Details</Typography>
                                                            <Field
                                                                component={TextField}
                                                                name="sender.name"
                                                                type="text"
                                                                label="sender's Name"
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <AccountCircleIcon />
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                fullWidth
                                                                className={styles.form_field}
                                                            />
                                                            <br />
                                                            <br />
                                                            <Field
                                                                component={TextField}
                                                                name="sender.phone_number"
                                                                type="text"
                                                                label="Sender's Phone Number"
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <Call />
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                fullWidth
                                                                className={styles.form_field}
                                                            />
                                                            <br />
                                                            <br />
                                                            <Field
                                                                component={TextField}
                                                                name="sender.email"
                                                                type="email"
                                                                label="Sender's Email"
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <EmailIcon />
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                fullWidth
                                                                className={styles.form_field}
                                                            />
                                                            <br />
                                                            <br />
                                                        </Fragment>
                                                    ) : (null)
                                                }


                                                <div className={styles.actionsContainer}>
                                                    {isSubmitting && <LinearProgress />}
                                                    <div>
                                                        <Button
                                                            disabled={activeStep === 0}
                                                            onClick={handleBack}
                                                            className={styles.button}
                                                        >
                                                            Back
                                            </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={handleNext}
                                                            className={styles.button}
                                                            // disabled={nextButtonState}

                                                        >
                                                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                        </Button>
                                                    </div>
                                                </div>

                                            </StepContent>
                                        </Step>
                                        <Step key="1" active={activeStep === 1 ? true : false}>
                                            <StepLabel key="1.1">Pick up to Destination Address</StepLabel>
                                            <StepContent key="1.2">

                                                <Fragment>
                                                    <FormControl fullWidth>
                                                        <Typography variant="h6">Pick up Location</Typography>
                                                        <Field
                                                            name="pickup_at.address"
                                                            component={Autocomplete}

                                                            getOptionLabel={(option: any) => (typeof option === 'string' ? option : option.description)}
                                                            filterOptions={(x: any) => x}
                                                            options={pickUpAddressoOptions}
                                                            autoComplete
                                                            includeInputInList
                                                            filterSelectedOptions
                                                            value={pickUpAddressValue}
                                                            onChange={(event: any, newValue: PlaceType | null) => {
                                                                setPickUpAddressOptions(newValue ? [newValue, ...pickUpAddressoOptions] : pickUpAddressoOptions);
                                                                setPickUpAddressValue(newValue);

                                                            }}
                                                            onInputChange={(event: any, newInputValue: any) => {
                                                                setPickUpAddressInputValue(newInputValue);
                                                            }}
                                                            renderInput={(params: any) => (
                                                                <MyTextField {...params} label="Address" fullWidth />

                                                            )}
                                                            renderOption={(option: any) => {
                                                                const matches = option.structured_formatting.main_text_matched_substrings;
                                                                const parts = parse(
                                                                    option.structured_formatting.main_text,
                                                                    matches.map((match: any) => [match.offset, match.offset + match.length]),
                                                                );

                                                                return (
                                                                    <Grid container alignItems="center">
                                                                        <Grid item>
                                                                            <LocationOnIcon className={styles.icon} />
                                                                        </Grid>
                                                                        <Grid item xs>
                                                                            {parts.map((part, index) => (
                                                                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                                                    {part.text}
                                                                                </span>
                                                                            ))}
                                                                            <Typography variant="body2" color="textSecondary">
                                                                                {option.structured_formatting.secondary_text}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                );
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <br />
                                                    <br />
                                                    <FormControl fullWidth>
                                                        <Field
                                                            name="pickup_at.country"
                                                            component={Autocomplete}
                                                            inputProps={{
                                                                id: 'pickup_at_country',
                                                            }}
                                                            value={pickUpCountryInputValue}

                                                            options={CountryStateCityJson.getCountries()}
                                                            getOptionLabel={(option: any) => (typeof option === 'string' ? option : option.name)}

                                                            renderInput={(params: any) => <MyTextField {...params} label="Country" variant="standard" />}
                                                            onChange={(event: any, newValue: any) => {
                                                                if (newValue !== null) {
                                                                    setPickUpCountryInputValue(newValue.name);
                                                                }
                                                                if (newValue !== null) {
                                                                    setPickUpCountry(newValue.shortName);
                                                                }


                                                            }}




                                                            renderOption={(option: any) => {


                                                                return (
                                                                    <Typography variant="caption" >{option.name}</Typography>
                                                                );
                                                            }}

                                                        />
                                                    </FormControl>
                                                    {/* <br />
                                                    <br /> */}
                                                    {/* <FormControl fullWidth>
                                                        <Field
                                                            name="pickup_at.state"
                                                            component={Autocomplete}
                                                            inputProps={{
                                                                id: 'pickup_at_state',
                                                            }}

                                                            options={pickupState}
                                                            getOptionLabel={(option: any) => (typeof option === 'string' ? option : option)}

                                                            renderInput={(params: any) => <MyTextField {...params} label="State" variant="standard" />}
                                                            disabled={disablePickupState}

                                                            renderOption={(option: any) => {


                                                                return (
                                                                    <Typography variant="caption" >{option}</Typography>
                                                                );
                                                            }}

                                                        />
                                                    </FormControl> */}
                                                    {/* <br />
                                                    <br />
                                                    <FormControl fullWidth>
                                                        <Field
                                                            name="pickup_at.city"
                                                            component={Autocomplete}
                                                            inputProps={{
                                                                id: 'pickup_at_city',
                                                            }}

                                                            options={pickupCity}
                                                            getOptionLabel={(option: any) => (typeof option === 'string' ? option : option)}

                                                            renderInput={(params: any) => <MyTextField {...params} label="City" variant="standard" />}

                                                            disabled={disablePickupCity}
                                                            renderOption={(option: any) => {


                                                                return (
                                                                    <Typography variant="caption" >{option}</Typography>
                                                                );
                                                            }}

                                                        />
                                                    </FormControl>
                                                    <br />
                                                    <br /> */}
                                                    {/* <Field
                                                        component={CheckboxWithLabel}
                                                        type="checkbox"
                                                        name="save_pick_up_address"
                                                        Label={{ label: 'Save Pickup Address for future bookings' }}
                                                        value={true}

                                                    /> */}
                                                </Fragment>
                                                <Fragment>
                                                    <FormControl fullWidth>
                                                        <Typography variant="h6">Destination Location</Typography>
                                                        <Field
                                                            name="destination_to.address"
                                                            component={Autocomplete}

                                                            getOptionLabel={(option: any) => (typeof option === 'string' ? option : option.description)}
                                                            filterOptions={(x: any) => x}
                                                            options={destinationAddressoOptions}
                                                            autoComplete
                                                            includeInputInList
                                                            filterSelectedOptions
                                                            value={destinationAddressValue}
                                                            onChange={(event: any, newValue: PlaceType | null) => {
                                                                setDestinationAddressoOptions(newValue ? [newValue, ...destinationAddressoOptions] : destinationAddressoOptions);
                                                                setDestinationAddressValue(newValue);

                                                            }}
                                                            onInputChange={(event: any, newInputValue: any) => {
                                                                setDestinationAddressInputValue(newInputValue);
                                                            }}
                                                            renderInput={(params: any) => (
                                                                <MyTextField {...params} label="Address" fullWidth />

                                                            )}
                                                            renderOption={(option: any) => {
                                                                const matches = option.structured_formatting.main_text_matched_substrings;
                                                                const parts = parse(
                                                                    option.structured_formatting.main_text,
                                                                    matches.map((match: any) => [match.offset, match.offset + match.length]),
                                                                );

                                                                return (
                                                                    <Grid container alignItems="center">
                                                                        <Grid item>
                                                                            <LocationOnIcon className={styles.icon} />
                                                                        </Grid>
                                                                        <Grid item xs>
                                                                            {parts.map((part, index) => (
                                                                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                                                    {part.text}
                                                                                </span>
                                                                            ))}
                                                                            <Typography variant="body2" color="textSecondary">
                                                                                {option.structured_formatting.secondary_text}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                );
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <br />
                                                    <br />
                                                    <FormControl fullWidth>
                                                        <Field
                                                            name="destination_to.country"
                                                            component={Autocomplete}
                                                            inputProps={{
                                                                id: 'destination_to_country',
                                                            }}
                                                            value={destinationCountryInputValue}

                                                            options={CountryStateCityJson.getCountries()}
                                                            getOptionLabel={(option: any) => (typeof option === 'string' ? option : option.name)}

                                                            renderInput={(params: any) => <MyTextField {...params} label="Country" variant="standard" />}
                                                            onChange={(event: any, newValue: any) => {
                                                                if (newValue !== null) {
                                                                    setDestinationCountryInputValue(newValue.name);
                                                                }
                                                                if (newValue !== null) {
                                                                    setDestinationCountry(newValue.shortName);
                                                                }


                                                            }}




                                                            renderOption={(option: any) => {


                                                                return (
                                                                    <Typography variant="caption" >{option.name}</Typography>
                                                                );
                                                            }}

                                                        />
                                                    </FormControl>
                                                    {/* <br />
                                                    <br />
                                                    <FormControl fullWidth>
                                                        <Field
                                                            name="destination_to.state"
                                                            component={Autocomplete}
                                                            inputProps={{
                                                                id: 'destination_to_state',
                                                            }}

                                                            options={destinationState}
                                                            getOptionLabel={(option: any) => (typeof option === 'string' ? option : option)}

                                                            renderInput={(params: any) => <MyTextField {...params} label="State" variant="standard" />}

                                                            disabled={disableDestinationState}
                                                            renderOption={(option: any) => {


                                                                return (
                                                                    <Typography variant="caption" >{option}</Typography>
                                                                );
                                                            }}

                                                        />
                                                    </FormControl>
                                                    <br />
                                                    <br />
                                                    <FormControl fullWidth>
                                                        <Field
                                                            name="destination_to.city"
                                                            component={Autocomplete}
                                                            inputProps={{
                                                                id: 'destination_to_city',
                                                            }}

                                                            options={destinationpCity}
                                                            getOptionLabel={(option: any) => (typeof option === 'string' ? option : option)}

                                                            renderInput={(params: any) => <MyTextField {...params} label="City" variant="standard" />}

                                                            disabled={disableDestinationCity}
                                                            renderOption={(option: any) => {


                                                                return (
                                                                    <Typography variant="caption" >{option}</Typography>
                                                                );
                                                            }}

                                                        />
                                                    </FormControl>
                                                    <br />
                                                    <br /> */}
                                                    {/* <Field
                                                        component={CheckboxWithLabel}
                                                        type="checkbox"
                                                        checked={true}
                                                        name="save_destination_address"
                                                        Label={{ label: 'Save Destination Address for future bookings' }}
                                                        value={true}

                                                    /> */}
                                                </Fragment>
                                                <div className={styles.actionsContainer}>
                                                    {isSubmitting && <LinearProgress />}
                                                    <div>
                                                        <Button
                                                            disabled={activeStep === 0}
                                                            onClick={handleBack}
                                                            className={styles.button}
                                                        >
                                                            Back
                                            </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={handleNext}
                                                            className={styles.button}
                                                            // disabled={nextButtonState}

                                                        >
                                                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </StepContent>
                                        </Step>
                                        <Step key="2" active={activeStep === 2 ? true : false}>
                                            <StepLabel key="1.1">Describe your shipment</StepLabel>
                                            <StepContent key="1.2">

                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="type-of-transport">Select what you want to transport</InputLabel>
                                                    <Field
                                                        component={Select}
                                                        name="type_of_transport"
                                                        inputProps={{
                                                            id: 'type-of-transport',
                                                        }}
                                                    >
                                                        <MenuItem value="Parcel">Parcel</MenuItem>
                                                        <MenuItem value="Human">Human Travel</MenuItem>

                                                    </Field>
                                                </FormControl>
                                                <br />

                                                {
                                                    typeoftransport === 1 ? (
                                                        <Fragment>

                                                            <br />
                                                            <FormControl fullWidth>
                                                                <InputLabel htmlFor="the_category_of_item">Category of Item</InputLabel>
                                                                <Field
                                                                    component={Select}
                                                                    name="items.catergory"
                                                                    inputProps={{
                                                                        id: 'the_category_of_item',
                                                                    }}
                                                                >
                                                                    <MenuItem value="Phones, Computers and Electronics">Phones, Computers and Electronics</MenuItem>
                                                                    <MenuItem value="Home, Office and Electrical Appliance">Home, Office and Electrical Appliance</MenuItem>
                                                                    <MenuItem value="Perfume, Cosmetics and Makeup">Perfume, Cosmetics and Makeup</MenuItem>
                                                                    <MenuItem value="Fees or Additional Charges">Fees or Additional Charges</MenuItem>
                                                                    <MenuItem value="Packed food and spices">Packed food and spices</MenuItem>
                                                                    <MenuItem value="Books , Documents">Books , Documents</MenuItem>
                                                                    <MenuItem value="Shoes">Shoes</MenuItem>
                                                                    <MenuItem value="Watches, Jewelry & Trinkets">Watches, Jewelry & Trinkets</MenuItem>
                                                                    <MenuItem value="Medication and Pharmaceuticals">Medication and Pharmaceuticals</MenuItem>
                                                                    <MenuItem value="Kitchen Ware">Kitchen Ware</MenuItem>
                                                                    <MenuItem value="Clothing and Fashion Items">Clothing and Fashion Items</MenuItem>
                                                                    <MenuItem value="Others">Others</MenuItem>

                                                                </Field>
                                                            </FormControl>

                                                            <br />
                                                            <br />
                                                            <Typography>Pick which type of vehicle your want for delivery</Typography>
                                                            <Field component={RadioGroup} name="delivery_of_item_type">
                                                                <Grid container>
                                                                    <Grid item xs={12} lg={6}>
                                                                        <Box className={styles.delivery_options} boxShadow={5}>
                                                                            <FormControlLabel
                                                                                value="Bicycle"
                                                                                control={<Radio disabled={isSubmitting} />}
                                                                                label="Bicycle Delivery"
                                                                                disabled={isSubmitting}
                                                                            />
                                                                            <img src={BikeImage} alt="Bicycle" />
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={6}>
                                                                        <Box className={styles.delivery_options} boxShadow={5}>
                                                                            <FormControlLabel
                                                                                value="Bike"
                                                                                control={<Radio disabled={isSubmitting} />}
                                                                                label="Bike Delivery"
                                                                                disabled={isSubmitting}
                                                                            />
                                                                            <img src={MotorBikeImage} alt="Bike Delivery" />
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={6}>
                                                                        <Box className={styles.delivery_options} boxShadow={5}>
                                                                            <FormControlLabel
                                                                                value="Van"
                                                                                control={<Radio disabled={isSubmitting} />}
                                                                                label="Van Delivery"
                                                                                disabled={isSubmitting}
                                                                            />
                                                                            <img src={VanImage} alt="Van Delivery" />
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={6}>
                                                                        <Box className={styles.delivery_options} boxShadow={5}>
                                                                            <FormControlLabel
                                                                                value="Truck"
                                                                                control={<Radio disabled={isSubmitting} />}
                                                                                label="Truck Delivery"
                                                                                disabled={isSubmitting}
                                                                            />
                                                                            <img src={TruckImage} alt="Truck Delivery" />
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={6}>
                                                                        <Box className={styles.delivery_options} boxShadow={5}>
                                                                            <FormControlLabel
                                                                                value="Air"
                                                                                control={<Radio disabled={isSubmitting} />}
                                                                                label="Air Delivery"
                                                                                disabled={isSubmitting}
                                                                            />
                                                                            <img src={AirPlaneImage} alt="Air Delivery" />
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={6}>
                                                                        <Box className={styles.delivery_options} boxShadow={5}>
                                                                            <FormControlLabel
                                                                                value="Ship"
                                                                                control={<Radio disabled={isSubmitting} />}
                                                                                label="Ship Delivery"
                                                                                disabled={isSubmitting}
                                                                            />
                                                                            <img src={CargoShipImage} alt="Ship Delivery" />
                                                                        </Box>
                                                                    </Grid>


                                                                </Grid>
                                                            </Field>
                                                            <br />


                                                            <img src={ParcelDImension} alt="parcel dimension" style={{ width: '100%', height: 'auto' }} />
                                                            <Field
                                                                component={TextField}
                                                                name="items.length"
                                                                type="number"
                                                                label="Parcel Length"
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <Typography>iches</Typography>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                fullWidth
                                                                className={styles.form_field}
                                                            />
                                                            <br />
                                                            <Field
                                                                component={TextField}
                                                                name="items.breadth"
                                                                type="number"
                                                                label="Parcel breadth"
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <Typography>iches</Typography>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                fullWidth
                                                                className={styles.form_field}
                                                            />
                                                            <br />
                                                            <Field
                                                                component={TextField}
                                                                name="items.height"
                                                                type="number"
                                                                label="Parcel height"
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <Typography>iches</Typography>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                fullWidth
                                                                className={styles.form_field}
                                                            />
                                                            <br />
                                                            <Field
                                                                component={TextField}
                                                                name="items.weight"
                                                                type="number"
                                                                label="Parcel weight"
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <Typography>Kg</Typography>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                fullWidth
                                                                className={styles.form_field}
                                                            />
                                                            <br />
                                                            <Field
                                                                component={TextField}
                                                                name="items.quantity"
                                                                type="number"
                                                                label="Parcel quantity"

                                                                fullWidth
                                                                className={styles.form_field}
                                                            />
                                                            <br />
                                                            <Field
                                                                component={TextField}
                                                                name="items.description"
                                                                type="text"
                                                                label="Add Note"

                                                                fullWidth
                                                                className={styles.form_field}
                                                            />
                                                            <br />
                                                        </Fragment>
                                                    ) : (null)
                                                }

                                                {
                                                    typeoftransport === 2 ? (
                                                        <Fragment>
                                                            <br />
                                                            <Typography>Pick which type of vehicle your want for transportation</Typography>
                                                            <Field component={RadioGroup} name="number_of_seats">
                                                                <Grid container>
                                                                    <Grid item xs={12} lg={6}>
                                                                        <Box className={styles.delivery_options} boxShadow={5}>
                                                                            <FormControlLabel
                                                                                value="1"
                                                                                control={<Radio disabled={isSubmitting} />}
                                                                                label="1 Seat"
                                                                                disabled={isSubmitting}
                                                                            />
                                                                            <img src={PowerBikeImage} alt="1 seater" />
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={6}>
                                                                        <Box className={styles.delivery_options} boxShadow={5}>
                                                                            <FormControlLabel
                                                                                value="3"
                                                                                control={<Radio disabled={isSubmitting} />}
                                                                                label="3 Seater"
                                                                                disabled={isSubmitting}
                                                                            />
                                                                            <img src={KekeImage} alt="3 Seater " />
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={6}>
                                                                        <Box className={styles.delivery_options} boxShadow={5}>
                                                                            <FormControlLabel
                                                                                value="4"
                                                                                control={<Radio disabled={isSubmitting} />}
                                                                                label="4 Seater"
                                                                                disabled={isSubmitting}
                                                                            />
                                                                            <img src={SaloonCarImage} alt="4 Seater " />
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={6}>
                                                                        <Box className={styles.delivery_options} boxShadow={5}>
                                                                            <FormControlLabel
                                                                                value="7"
                                                                                control={<Radio disabled={isSubmitting} />}
                                                                                label="7 Seater"
                                                                                disabled={isSubmitting}
                                                                            />
                                                                            <img src={SiennaImage} alt="7 Seater " />
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={6}>
                                                                        <Box className={styles.delivery_options} boxShadow={5}>
                                                                            <FormControlLabel
                                                                                value="12"
                                                                                control={<Radio disabled={isSubmitting} />}
                                                                                label="12 Seater"
                                                                                disabled={isSubmitting}
                                                                            />
                                                                            <img src={HiluxImage} alt="12 Seater " />
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={6}>
                                                                        <Box className={styles.delivery_options} boxShadow={5}>
                                                                            <FormControlLabel
                                                                                value="22"
                                                                                control={<Radio disabled={isSubmitting} />}
                                                                                label="22 Seater"
                                                                                disabled={isSubmitting}
                                                                            />
                                                                            <img src={CoasterImage} alt="22 Seater " />
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={6}>
                                                                        <Box className={styles.delivery_options} boxShadow={5}>
                                                                            <FormControlLabel
                                                                                value="36 - 60 Seater"
                                                                                control={<Radio disabled={isSubmitting} />}
                                                                                label="36 - 60 Seater"
                                                                                disabled={isSubmitting}
                                                                            />
                                                                            <img src={TourImage} alt="36 - 60 Seater " />
                                                                        </Box>
                                                                    </Grid>

                                                                </Grid>
                                                            </Field>

                                                        </Fragment>

                                                    ) : (null)
                                                }
                                                <div className={styles.actionsContainer}>
                                                    {isSubmitting && <LinearProgress />}
                                                    <div>
                                                        <Button
                                                            disabled={activeStep === 0}
                                                            onClick={handleBack}
                                                            className={styles.button}
                                                        >
                                                            Back
                                            </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={handleNext}
                                                            className={styles.button}
                                                            // disabled={nextButtonState}

                                                        >
                                                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </StepContent>
                                        </Step>
                                        <Step key="3" active={activeStep === 3 ? true : false}>
                                            <StepLabel key="2.1">Parcel Checklist</StepLabel>
                                            <StepContent key="2.2">
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="have_you_put_name_of_reciever_on_the_parcel">Have you put the name of the reciever on the parcel?</InputLabel>
                                                    <Field
                                                        component={Select}
                                                        name="item_check_list.have_you_put_name_of_reciever_on_the_parcel"
                                                        inputProps={{
                                                            id: 'have_you_put_name_of_reciever_on_the_parcel',
                                                        }}


                                                    >
                                                        <MenuItem value="Yes">Yes</MenuItem>
                                                        <MenuItem value="No">No</MenuItem>

                                                    </Field>
                                                </FormControl>


                                                <br />
                                                <br />
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="have_you_put_mobile_number_of_reciever_on_the_parcel">Have you put the mobile number of the reciever on the parcel?</InputLabel>
                                                    <Field
                                                        component={Select}
                                                        name="item_check_list.have_you_put_mobile_number_of_reciever_on_the_parcel"
                                                        inputProps={{
                                                            id: 'have_you_put_mobile_number_of_reciever_on_the_parcel',
                                                        }}


                                                    >
                                                        <MenuItem value="Yes">Yes</MenuItem>
                                                        <MenuItem value="No">No</MenuItem>

                                                    </Field>
                                                </FormControl>
                                                <br />
                                                <br />
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="have_you_ensured_the_parcel_is_secured">Have you ensured the parcel is secured?</InputLabel>
                                                    <Field
                                                        component={Select}
                                                        name="item_check_list.have_you_ensured_the_parcel_is_secured"
                                                        inputProps={{
                                                            id: 'have_you_ensured_the_parcel_is_secured',
                                                        }}


                                                    >
                                                        <MenuItem value="Yes">Yes</MenuItem>
                                                        <MenuItem value="No">No</MenuItem>

                                                    </Field>
                                                </FormControl>

                                                <br />
                                                <br />
                                                <Field
                                                    component={TextField}
                                                    name="item_check_list.position_of_item"
                                                    type="text"
                                                    label="Does the item have a position to be placed in?"
                                                    fullWidth
                                                    className={styles.form_field}
                                                />


                                                <div className={styles.actionsContainer}>
                                                    {isSubmitting && <LinearProgress />}
                                                    <div>
                                                        <Button
                                                            disabled={activeStep === 0}
                                                            onClick={handleBack}
                                                            className={styles.button}
                                                        >
                                                            Back
                                            </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={handleNext}
                                                            className={styles.button}
                                                            // disabled={nextButtonState}

                                                        >
                                                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </StepContent>
                                        </Step>


                                        {/* <div className={styles.actionsContainer}>
                                            {isSubmitting && <LinearProgress />}
                                            <div>
                                                <Button
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                    className={styles.button}
                                                >
                                                    Back
                                            </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleNext}
                                                    className={styles.button}
                                                    disabled={nextButtonState}

                                                >
                                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                </Button>
                                            </div>
                                        </div> */}
                                    </Stepper>
                                    {isSubmitting && <LinearProgress />}
                                    {activeStep === steps.length && (
                                        <Paper square elevation={0} className={styles.resetContainer}>
                                            <Typography>All steps completed - you&apos;re finished</Typography>
                                            <Button onClick={() => handleReset(resetForm)} className={styles.button}>
                                                Reset
                                        </Button>


                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={isSubmitting}
                                                onClick={submitForm}
                                            >
                                                Submit
                                </Button>
                                        </Paper>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Grid>

            </Grid>
        </div>
    );
}

const mapStateToProps = (state: any) => {

    return {
        signUpInfo: state.auth.signupInfo,
        // auth: state.firebase.auth,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        clearSignUpInfo: () => dispatch({ type: SIGNUP_FEEDBACK, payload: { severity: '', message: '' } }),
        NewPendingBooking: (data: any) => dispatch(PendingBooking(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewPendingBooking);