
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';
import { SendNotification } from '../notification/sendNotificationAction';

export const ApproveLogisticsCompany = (id: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

        firebase.firestore().collection('LogisticsCompanies').doc(id).set({
            approved: true,
            approved_on: firebase.firestore.FieldValue.serverTimestamp(),

        }, { merge: true })
            .then(() => {
                dispatch({ type: 'SIGNUP_FEEDBACK', payload: { severity: 'success', message: "Account Approved!" } })
                dispatch(SendNotification({
                    to: id,
                    subject: 'You have been Approved!',
                    body: 'You can now recieve recieve new bookings from customers',
                    severity: 'info'
                }));
            })

    }