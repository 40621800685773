


import React, { Fragment, useState, useEffect, useRef } from 'react';

//router dom
import { Redirect, Link } from "react-router-dom";
//package
import clsx from 'clsx';
// styles
import { createStyles, makeStyles, useTheme, Theme, fade } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
// components
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import DraftsIcon from '@material-ui/icons/Drafts';
import { TransitionProps } from '@material-ui/core/transitions';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

// windows
import CustomerDashBoardWindow from '../../components/CustomerDashboardWindow';
// import StoreWindow from '../../components/StoreWindow';
// import OrdersWindow from '../../components/OrdersWindow';
// import CustomersWindow from '../../components/CustomersWindow';
// import UsersWindow from '../../components/UsersWindow';
// import AnalyticsWindow from '../../components/AnalyticsWindow';
// import ActivityLogWindow from '../../components/ActivityLogWindow';
// import SettingsWindow from '../../components/SettingsWindow';
import AccountWindow from '../../components/AccountWindow';
// icons
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import MailIcon from '@material-ui/icons/Mail';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
// import StoreMallDirectoryTwoToneIcon from '@material-ui/icons/StoreMallDirectoryTwoTone';
// import ListAltTwoToneIcon from '@material-ui/icons/ListAltTwoTone';
// import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone';
// import BookTwoToneIcon from '@material-ui/icons/BookTwoTone';
// import AssessmentTwoToneIcon from '@material-ui/icons/AssessmentTwoTone';
// import SupervisedUserCircleTwoToneIcon from '@material-ui/icons/SupervisedUserCircleTwoTone';
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';
// import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';

//connect react 
import { connect } from 'react-redux';
import { compose } from "redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { MarkAsRead } from '../../store/actions/notification/markAsReadAction';
import { MarkAsNotNewUser } from '../../store/actions/account/customer/markAsNotNewUser';
import firebase from '../../config/firebaseConfig';

import Logo from '../../assets/images/logo2.png';
import Loader from '../../assets/images/fastlogisticsloader.gif';

// import Tour from "reactour";
// import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import _ from 'lodash';
// import GetImageFromFireBase from '../../components/GetImageFromFirebase';



// import Sound from 'react-sound';
import UIfx from 'uifx'

import soundfile from '../../assets/audio/filling-your-inbox.mp3';

//Advert
import Advert from '../../components/Advert';


const NotificationSound = new UIfx(
    soundfile,
    {
        volume: 1.0, // number between 0.0 ~ 1.0
    }
)

const drawerWidth = 240;

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const getCustomer = (AllContestantsArr: object[], id: string) => {
    const contestant = AllContestantsArr.filter((item: any) => {
        return item.id === id
    });

    return contestant[0]
}

const getNotifications = (Allnotifications: object[], id: string) => {
    const notification = Allnotifications.filter((item: any) => {
        return item.to === id
    });
    const sorted = _.orderBy(notification, ['sent_on'], ['desc']);
    return sorted
}

const getNotificationCount = (Allnotifications: object[], id: string) => {
    const notification = Allnotifications.filter((item: any) => {
        return item.to === id && item.unread === true
    });

    return notification.length
}







const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: -drawerWidth,
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        grow: {
            flexGrow: 1,
        },
        title: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                width: 'auto',
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '400px',
            [theme.breakpoints.up('md')]: {
                width: '20ch',
            },
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        logo: {
            width: 150,
            height: 'auto',
            [theme.breakpoints.down('sm')]: {
                width: 100,
            }
        },
        notifiction_appBar: {
            position: 'relative',
        },
        loader_wrapper: {
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        side_ad: {
            width: 200,
            height: 500,
            // backgroundColor: 'gray',
            marginBottom: 50,
        },
        center_ad: {
            width: '98%',
            height: 150,
            // backgroundColor: 'gray',
            marginBottom: 50,
        }

    }),
);

function usePreviousNotificationCount(value: number) {
    const ref = useRef<number>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const CustomerDashboard: React.FC = ({ auth, allCustomer, globalOptions, allNotifications, markNotificationAsRead, allBookings, markAsNotNewUser }: any) => {

    const styles = useStyles();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);
    const [menuIndex, setmenuIndex] = useState(0);
    const [pageLoading, setpageLoading] = useState(false);
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const [showNotificationModal, setshowNotificationModal] = useState(false);
    const [currentUser, setcurrentUser] = useState<any>({});
    const [notifications, setnotifications] = useState<object[]>([]);
    const [notificationCount, setnotificationCount] = useState<number>(0);
    const [notificationDetails, setnotificationDetails] = useState<any>({});
    const [shownotificationDetailsModal, setshownotificationDetailsModal] = useState(false);
    const [isEmailVerified, setisEmailVerified] = useState(false);
    const [showWelcomeModal, setshowWelcomeModal] = useState(false);
    // const [isTourOpen, setisTourOpen] = useState(false);

    const previousNotificationCount = usePreviousNotificationCount(notificationCount);


    useEffect(() => {

        if (typeof allNotifications !== 'undefined') {
            if (previousNotificationCount !== notificationCount) NotificationSound.play();
        }


    }, [notificationCount, previousNotificationCount, allNotifications]);

    useEffect(() => {

        setTimeout(() => {
            setpageLoading(false)

        }, 2000);
        return () => {
            clearTimeout();
        }
    }, []);

    useEffect(() => {
        if (typeof allCustomer !== 'undefined' && typeof auth.uid !== 'undefined') {
            const result_user = getCustomer(allCustomer, auth.uid);
            setcurrentUser({ ...result_user });
        }


    }, [allCustomer, auth.uid]);

    useEffect(() => {


        if (typeof allNotifications !== 'undefined') {
            const result_notifications = getNotifications(allNotifications, auth.uid);
            const result_notification_count = getNotificationCount(allNotifications, auth.uid);
            setnotifications([...result_notifications]);
            setnotificationCount(result_notification_count);
        }
    }, [allNotifications, auth]);

    useEffect(() => {
        if (typeof auth.uid !== 'undefined') {
            if (auth.emailVerified !== false) {
                if (typeof currentUser !== 'undefined') {
                    if (currentUser.is_new === true) {
                        openWelcomeModal();
                    }
                }

            } else {
                setisEmailVerified(true)
            }
        }
    }, [auth.emailVerified, currentUser, auth.uid])




    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };


    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const closeNotificationModal = () => {
        setshowNotificationModal(false);
    }
    const openNotificationModal = () => {
        setshowNotificationModal(true);
    }

    const viewNotification = (Notification: any) => {

        setnotificationDetails({ ...Notification });
        setshownotificationDetailsModal(true);
        if (Notification.unread === true) {
            markNotificationAsRead(Notification.id);
        }
    }

    const closeWelcomeModal = () => {
        setshowWelcomeModal(false);
    }
    const openWelcomeModal = () => {
        setshowWelcomeModal(true);
    }
    // const closeTour = () => {
    //     setisTourOpen(false);
    // };

    // const openTour = () => {
    //     setisTourOpen(true);
    // };

    // const accentColor = "#5cb7b7";

    // const disableBody = (target: any) => disableBodyScroll(target);
    // const enableBody = (target: any) => enableBodyScroll(target);


    // const tourConfig = [
    //     // {
    //     //     selector: '[data-tut="newuser__menu"]',
    //     //     content: `This is the menu , you can check the Dashboard and Account settings here`
    //     // },
    //     {
    //         selector: '[data-tut="newuser__newbooking"]',
    //         content: `You can add a new a booking with this button`
    //     },
    //     {
    //         selector: '[data-tut="newuser__pendingbooking"]',
    //         content: `All pending booking that is yet to be approved by the logistics company would be here`
    //     },
    //     {
    //         selector: '[data-tut="newuser__currentbooking"]',
    //         content: `All Current booking that is ongoing would be here`
    //     },
    //     {
    //         selector: '[data-tut="newuser__completedbooking"]',
    //         content: `All Completed booking that have been acknowledged by you would be here`,

    //     },
    //     {
    //         selector: '[data-tut="newuser__notifications"]',
    //         content: `Get notifications here`,

    //     },

    // ];

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
            <MenuItem onClick={() => firebase.auth().signOut()}>Sign Out</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >


            <MenuItem component={Link} to={`/NewBooking/118e16523a1e478da74a78a9a4b4ed9932ae3d9196f227a3c2ae3f548c40e82807d5532a498c5132453e654a16a78b49d4db8bc08687a91b5e02db029907c8e3/${currentUser.customer_first_name}/${currentUser.customer_last_name}/${currentUser.customer_phone_number}/${auth.email}/${auth.uid}`}>New Booking</MenuItem>
            <MenuItem onClick={openNotificationModal}>
                <IconButton aria-label={`show ${notificationCount} new notifications`} color="primary" >
                    <Badge badgeContent={notificationCount} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
            <MenuItem onClick={() => firebase.auth().signOut()}>Sign Out</MenuItem>
        </Menu>
    );



    if (!isLoaded(auth)) {
        return <div className={styles.loader_wrapper}> PLease wait ...</div>
    } else {
        if (!auth.uid) {
            return <Redirect to="/login" />
        }
    }
    if (!isLoaded(allCustomer) && !isLoaded(globalOptions) && !isLoaded(allNotifications)) {
        return <div className={styles.loader_wrapper}> <img src={Loader} alt="Loader logo" /> </div>
    } else {



    }


    return (
        <Fragment>

            <div className={styles.root}>
                <Dialog key="email_verified_modal" open={isEmailVerified} onClose={() => setisEmailVerified(false)} aria-labelledby="form-dialog-title" disableBackdropClick disableEscapeKeyDown>
                    <DialogTitle id="form-dialog-title">You have not verfied your email</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            For Security reasons you have to verify your email before you can use this application
                        </DialogContentText>


                    </DialogContent>
                    <DialogActions>

                        <Button onClick={() => { firebase.auth().currentUser?.sendEmailVerification(); alert('Email verification sent , check you email and click on the link to verify') }} color="primary">
                            Resend Email Verification
            </Button>
                    </DialogActions>
                </Dialog>
                <Dialog key="welcome" open={showWelcomeModal} onClose={closeWelcomeModal} aria-labelledby="form-dialog-title" disableBackdropClick disableEscapeKeyDown>
                    <DialogTitle id="form-dialog-title">Welcome to FastLogistics</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga officiis nemo sit, repellendus voluptates, corporis doloribus fugiat, suscipit omnis facilis cupiditate voluptatibus libero corrupti blanditiis reprehenderit. Voluptas obcaecati amet aperiam.
          </DialogContentText>


                    </DialogContent>
                    <DialogActions>
                        {/* <Button onClick={() => { closeWelcomeModal(); openTour(); }} color="primary">
                            Start a Tour of the Fastlogistics
                        </Button> */}
                        <Button onClick={() => { closeWelcomeModal(); markAsNotNewUser(auth.uid); }} color="primary">
                            Dont show again
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog key="notification_modal" fullScreen open={showNotificationModal} onClose={closeNotificationModal} TransitionComponent={Transition}>
                    <AppBar className={styles.notifiction_appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={closeNotificationModal} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6">
                                Notifications
            </Typography>

                        </Toolbar>
                    </AppBar>
                    <List>
                        {
                            notifications.map((item: any, index: number) => {
                                return (
                                    <Fragment key={index}>
                                        <ListItem key={item.subject + index} button style={{ backgroundColor: item.unread === true ? '#e6f9ff' : 'white' }} onClick={() => viewNotification(item)}>
                                            <ListItemIcon>
                                                {
                                                    item.unread === true ? <MailIcon /> : <DraftsIcon />
                                                }

                                            </ListItemIcon>
                                            <ListItemText primary={item.subject} secondary={item.severity} />
                                            <ListItemSecondaryAction>
                                                <IconButton edge="end" aria-label={item.subject} onClick={() => viewNotification(item)}>
                                                    <KeyboardArrowRightIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Divider />
                                    </Fragment>
                                )
                            })
                        }

                    </List>
                </Dialog>
                <Dialog key="notification_detail" open={shownotificationDetailsModal} onClose={() => setshownotificationDetailsModal(false)} aria-labelledby="form-dialog-title" disableBackdropClick disableEscapeKeyDown>
                    <DialogTitle id="form-dialog-title">{notificationDetails.subject}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {
                                notificationDetails.body
                            }
                        </DialogContentText>


                    </DialogContent>
                    <DialogActions>

                        <Button onClick={() => setshownotificationDetailsModal(false)} color="primary">
                            Close
                                                </Button>
                    </DialogActions>
                </Dialog>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={clsx(styles.appBar, {
                        [styles.appBarShift]: open,
                    })}
                    style={{ backgroundColor: '#cce0ff' }}
                >
                    <Container>
                        <Toolbar>
                            <IconButton
                                color="primary"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                className={clsx(styles.menuButton, {
                                    [styles.hide]: open,
                                })}
                            >
                                <MenuIcon />
                            </IconButton>
                            {pageLoading ? (
                                <Skeleton width={100} height={35} variant="rect" />


                            ) : (
                                    <Link to="/">
                                        <img className={styles.logo} src={Logo} alt="Fast logistics logo" />
                                    </Link>
                                )}


                            <div className={styles.grow} />
                            <div className={styles.sectionDesktop}>
                                <Button data-tut="newuser__newbooking" component={Link} to={`/NewBooking/118e16523a1e478da74a78a9a4b4ed9932ae3d9196f227a3c2ae3f548c40e82807d5532a498c5132453e654a16a78b49d4db8bc08687a91b5e02db029907c8e3/${currentUser.customer_first_name}/${currentUser.customer_last_name}/${currentUser.customer_phone_number}/${auth.email}/${auth.uid}`}>New Booking</Button>


                                {/* <Button onClick={() => openTour()}>
                                    Open Tour
                                </Button> */}
                                <div data-tut="newuser__notifications">
                                    <IconButton aria-label={`show ${notificationCount} new notifications`} color="primary" onClick={openNotificationModal}>
                                        <Badge badgeContent={notificationCount} color="secondary">
                                            <NotificationsIcon />
                                        </Badge>
                                    </IconButton>
                                </div>

                                <IconButton
                                    edge="end"
                                    aria-label="account of current user"
                                    aria-controls={menuId}
                                    aria-haspopup="true"
                                    onClick={handleProfileMenuOpen}
                                    color="primary"
                                >
                                    <AccountCircle />
                                </IconButton>
                            </div>
                            <div className={styles.sectionMobile}>
                                <IconButton
                                    aria-label="show more"
                                    aria-controls={mobileMenuId}
                                    aria-haspopup="true"
                                    onClick={handleMobileMenuOpen}
                                    color="primary"
                                >
                                    <MoreIcon />
                                </IconButton>
                            </div>
                        </Toolbar>
                    </Container>
                </AppBar>
                {renderMobileMenu}
                {renderMenu}
                <div data-tut="newuser__menu">
                    <Drawer
                        className={styles.drawer}
                        variant="persistent"
                        anchor="left"
                        open={open}
                        classes={{
                            paper: styles.drawerPaper,
                        }}
                    >
                        <div className={styles.toolbar}>
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                            </IconButton>
                        </div>
                        <Divider />
                        <List>
                            <ListItem button onClick={() => setmenuIndex(0)} selected={menuIndex === 0 ? true : false}>
                                <ListItemIcon><DashboardTwoToneIcon /></ListItemIcon>
                                <ListItemText primary="Dashboard" />
                            </ListItem>

                        </List>
                        <Divider />
                        <List>

                        </List>
                        <Divider />
                        <List>

                            <ListItem button onClick={() => setmenuIndex(1)} selected={menuIndex === 1 ? true : false}>
                                <ListItemIcon><AccountCircleTwoToneIcon /></ListItemIcon>
                                <ListItemText primary="Account" />
                            </ListItem>
                        </List>
                        <Divider />
                        {/* <List>
                            <ListItem button onClick={() => setmenuIndex(2)} selected={menuIndex === 2 ? true : false}>
                                <ListItemIcon><SettingsTwoToneIcon /></ListItemIcon>
                                <ListItemText primary="Settings" />
                            </ListItem>
                        </List> */}
                    </Drawer>
                </div>
                <main className={styles.content}>
                    <Typography variant="h6" align="center" style={{ marginTop: 70 }}>Welcome  {currentUser.customer_first_name + ' ' + currentUser.customer_last_name}</Typography>
                    <Grid container>
                        <Grid item xs={12} lg={2}>

                        </Grid>
                        <Grid item xs={12} lg={8}>
                            <Advert />
                           
                            {
                                menuIndex === 0 && <CustomerDashBoardWindow user={currentUser} />
                            }
                            {
                                menuIndex === 1 && <AccountWindow user={currentUser} currentUserEmail={auth.email} />
                            }

                        </Grid>
                        <Grid item xs={12} lg={2}>

                        </Grid>
                    </Grid>
                </main>

            </div>
            {/* <Tour
                onRequestClose={closeTour}
                steps={tourConfig}
                isOpen={isTourOpen}
                maskClassName="mask"
                className="helper"
                rounded={5}
                accentColor={accentColor}
                onAfterOpen={disableBody}
                onBeforeClose={enableBody}
                lastStepNextButton={<Button onClick={() => { closeTour(); markAsNotNewUser(auth.uid); }}>  Done! Start Booking</Button>}
            /> */}

        </Fragment>
    )
}



const mapStateToProps = (state: any) => {

    return {
        auth: state.firebase.auth,
        allCustomer: state.firestore.ordered.Customers,
        globalOptions: state.firestore.ordered.globalOptions,
        allNotifications: state.firestore.ordered.Notifications,
        allBookings: state.firestore.ordered.Bookings,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        markNotificationAsRead: (notification_id: string) => dispatch(MarkAsRead(notification_id)),
        markAsNotNewUser: (userid: string) => dispatch(MarkAsNotNewUser(userid)),
    };
};



export default compose<any>(
    firestoreConnect((props: any) => [
        {
            collection: 'Customers',

        },
        {
            collection: 'globalOptions',

        },
        {
            collection: 'Notifications',

        },
        {
            collection: 'Bookings',

        },

    ]),
    connect(mapStateToProps, mapDispatchToProps)
)(CustomerDashboard)