import { useMutation } from "react-query";
import firebase from "../config/firebaseConfig";

const uploadLottoData = async (value: any) => {
  return firebase.firestore().collection("LottoData").doc().set({
    data: value,
    added_on: firebase.firestore.FieldValue.serverTimestamp(),
  });
};

const useUploadLottoData = () => {
  return useMutation(uploadLottoData);
};

export default useUploadLottoData;
