import { INotifications } from "../../../ts/interfaces/notification";

import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';

export const SendNotification = (notification: INotifications): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

        firebase.firestore().collection('Notifications').doc().set({
            to: notification.to,
            subject: notification.subject,
            body: notification.body,
            unread: true,
            severity: notification.severity,
            sent_on: firebase.firestore.FieldValue.serverTimestamp(),

        });
            
    }