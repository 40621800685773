// import { ICustomerAccount } from "../../../../ts/interfaces/customer";

import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';

export const EditCustomer = ({customer_first_name,customer_last_name,customer_phone_number,id}:{customer_first_name:string;customer_last_name:string;customer_phone_number:string;id:string;}): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

        return firebase.firestore().collection('Customers').doc(id).update({
            customer_first_name: customer_first_name,
            customer_last_name: customer_last_name,
            customer_phone_number: customer_phone_number,
            edited_on: firebase.firestore.FieldValue.serverTimestamp(),

        })

    }