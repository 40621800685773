import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';
import { SendNotification } from '../../notification/sendNotificationAction';

import { EMAIL_ALREADY_EXISTS, OPERATION_NOT_ALLOWED, INVALID_EMAIL, WEAK_PASSWORD, SIGNUP_FEEDBACK } from './signupTypes';

export const NewCustomerAccount = (customer_account: any, device_data: any): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

        try {
            const response = await firebase.auth().createUserWithEmailAndPassword(customer_account.email, customer_account.password);
            var batch = firebase.firestore().batch();
            var newCustomerCreden = firebase.firestore().collection('Customers').doc(response.user.uid);
            batch.set(newCustomerCreden, {
                customer_first_name: customer_account.customer_first_name,
                customer_last_name: customer_account.customer_last_name,
                customer_phone_number: customer_account.customer_phone_number,
                is_new: true,
                blocked: false,
                created_on: firebase.firestore.FieldValue.serverTimestamp(),
                devices: [device_data],
            });

            var newBooking = firebase.firestore().collection('Bookings').doc();
            batch.set(newBooking, {
                customer_id: response.user.uid,
                logistics_company_id: '',
                persona: customer_account.persona,
                pickup_at: {
                    address: customer_account.pickup_at.address,
                    country: customer_account.pickup_at.country,
                    state: customer_account.pickup_at.state,
                    city: customer_account.pickup_at.city,
                },
                destination_to: {
                    address: customer_account.destination_to.address,
                    country: customer_account.destination_to.country,
                    state: customer_account.destination_to.state,
                    city: customer_account.destination_to.city
                },
                sender: {
                    name: customer_account.sender.name,
                    phone_number: customer_account.sender.phone_number,
                    email: customer_account.sender.email,
                },
                reciever: {
                    name: customer_account.reciever.name,
                    phone_number: customer_account.reciever.phone_number,
                    email: customer_account.reciever.email,
                },
                third_party: customer_account.third_party,
                items: {
                    catergory: customer_account.items.catergory,
                    description: customer_account.items.description,
                    quantity: customer_account.items.quantity,
                    weight: customer_account.items.weight,
                    length: customer_account.items.length,
                    breadth: customer_account.items.breadth,
                    height: customer_account.items.height,
                },
                pending: {
                    status: true,
                    on: firebase.firestore.FieldValue.serverTimestamp(),
                },
                new: {
                    status: false,
                },
                current:{
                    status:false,
                },
                created_on: firebase.firestore.FieldValue.serverTimestamp(),
                // price_per_mile: customer_account.price_per_mile,
                // miles: customer_account.miles,
                number_of_seats: customer_account.number_of_seats,
                type_of_transport: customer_account.type_of_transport,
                delivery_of_item_type: customer_account.delivery_of_item_type,
                item_check_list: {
                    have_you_put_name_of_reciever_on_the_parcel: customer_account.item_check_list.have_you_put_name_of_reciever_on_the_parcel,
                    have_you_put_mobile_number_of_reciever_on_the_parcel: customer_account.item_check_list.have_you_put_mobile_number_of_reciever_on_the_parcel,
                    have_you_ensured_the_parcel_is_secured: customer_account.item_check_list.have_you_ensured_the_parcel_is_secured,
                    position_of_item: customer_account.item_check_list.position_of_item,

                },
                distance:customer_account.distance,
                distance_text:customer_account.distance_text,
                duration:customer_account.duration,
                rejected:[]
                
            });
            var newPickupAddress = firebase.firestore().collection('Addresses').doc();
            batch.set(newPickupAddress, {
                customer_id: response.user.uid,
                address_type: 'pickup',
                data: {
                    address: customer_account.pickup_at.address,
                    country: customer_account.pickup_at.country,
                    state: customer_account.pickup_at.state,
                    city: customer_account.pickup_at.city,
                },
                added_on: firebase.firestore.FieldValue.serverTimestamp(),

            });
            var newDestinationAddress = firebase.firestore().collection('Addresses').doc();
            batch.set(newDestinationAddress, {
                customer_id: response.user.uid,
                address_type: 'destination',
                data: {
                    address: customer_account.destination_to.address,
                    country: customer_account.destination_to.country,
                    state: customer_account.destination_to.state,
                    city: customer_account.destination_to.city,
                },
                added_on: firebase.firestore.FieldValue.serverTimestamp(),

            });

            // Commit the batch
            batch.commit();
             const updateProfilePromise =  firebase.auth().currentUser.updateProfile({
                displayName: "customer",
            });

            const sendEmailPromise =  firebase.auth().currentUser.sendEmailVerification();

            const allPromise  = Promise.all([updateProfilePromise,sendEmailPromise]);

            allPromise.then(() => {
                dispatch({ type: SIGNUP_FEEDBACK, payload: { severity: 'success', message: "Congratulations! Your Account has been created! A verification link has been sent to your email." } });
                dispatch(SendNotification({
                    to: firebase.auth().currentUser.uid,
                    subject: 'Welcome! to Fast Logistics',
                    body: 'We are happy to have you the best logistics booking website',
                    severity: 'info'
                }));
                dispatch(SendNotification({
                    to: firebase.auth().currentUser.uid,
                    subject: 'New Booking Added!',
                    body: 'You have added a new booking, its still in pending phase until you pick a logistics company',
                    severity: 'info'
                }));
            })
           


        } catch (error) {
            var errorCode = error.code;
            //    var errorMessage = error.message;

            switch (errorCode) {
                case EMAIL_ALREADY_EXISTS:
                    dispatch({ type: SIGNUP_FEEDBACK, payload: { severity: 'error', message: "Oops! An existing account is already using this email! Use another email" } })
                    break;
                case OPERATION_NOT_ALLOWED:
                    dispatch({ type: SIGNUP_FEEDBACK, payload: { severity: 'error', message: "Oops! The developers of this app did not activate this feature!A report would be sent to the owners " } })
                    break;
                case INVALID_EMAIL:
                    dispatch({ type: SIGNUP_FEEDBACK, payload: { severity: 'error', message: "Oops! This email is invalid! Check the format and try again" } })
                    break;
                case WEAK_PASSWORD:
                    dispatch({ type: SIGNUP_FEEDBACK, payload: { severity: 'warning', message: "Oops! Your password is too weak! try another password! " } })
                    break;
                default:
                    console.log(error);
                    
                    break;
            }

        }

    }
