import React, { useState, useEffect } from 'react';

//yup
import * as yup from 'yup';

//navigation
import { Link } from 'react-router-dom';
import { Redirect } from "react-router-dom";

//components
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';


//styles
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

//formik
import { Formik, Form, Field } from 'formik';
import { Button, LinearProgress } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import InputAdornment from '@material-ui/core/InputAdornment';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

//connect react 
import { connect } from 'react-redux';

//signupinfo type
import { SIGNIN_FEEDBACK } from '../../../store/actions/auth/login/signinTypes';


//Alert Component
import CustomAlert from '../../../components/CustomAlert/CustomAlert';

//signin action
import { AdminSignupAction } from '../../../store/actions/auth/signup/adminsignup';

import Logo from '../../../assets/images/logo2.png';

interface Values {
    email: string;
    password: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        login_wrapper: {
            height: "99.2vh",
            width: "99.8vw"
        },
        login_form_wrapper: {
            width: "100%",
            height: "auto",
            marginTop: theme.spacing(1),
            backgroundColor: "white",
            paddingBottom: 20,
        },
        login_top_section: {
            height: 50,
            paddingTop: 20,
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
        },
        login_middle_section: {
            paddingLeft: "50px",
            paddingRight: "50px",
            paddingTop: "20px"

        },
        login_bottom_section: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "20px",
            paddingLeft: "30px",
            paddingRight: "30px",
        },
        page_title: {
            fontWeight: "bolder",
        },
        form_field: {
            marginBottom: "50px",
        },
        bg: {
            position: "fixed",
            top: 0,
            zIndex: -1,
            background: 'linear-gradient(45deg, #0066ff 30%,#4d94ff 90%)',
            border: 0,
            borderRadius: 3,
            // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
            width: "100%",
            height: "300px",
        },
        logo_wrapper: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& div': {
                width: '40%',
                backgroundColor: theme.palette.background.paper,
                borderRadius: 20,
                marginTop: theme.spacing(1),
                '& a img': {
                    width: '100%'
                }
            }
        }
    })
);

//login yup schema
const LoginValidationScheme = yup.object().shape({
    email: yup
        .string()
        .email()
        .required(),
    password: yup
        .string()
        .label('Password')
        .required()
        .min(8, 'Your password must not be less than 8 characters')
        .max(15, 'We prefer you try a shorter password'),
})

interface Props {
    signInInfo: any;
    clearSignInInfo: () => void;
    auth: any;
    SignUp: (values: { email: string; password: string; username: string; }) => void;
}

const AdminSignUp = ({ signInInfo, clearSignInInfo, auth, SignUp }: Props) => {
    const [showPassword, setshowPassword] = useState(false);
    const [pageLoading, setpageLoading] = useState(true);

    useEffect(() => {

        setTimeout(() => {
            setpageLoading(false)

        }, 2000);
        return () => {
            clearTimeout();
        }
    }, []);
    const styles = useStyles();


    if (auth.uid) {
        return <Redirect to="/Redirect" />
    }


    return (
        <Box className={styles.login_wrapper}>
            {
                signInInfo.severity === 'error' || signInInfo.severity === 'warning' || signInInfo.severity === 'info' || signInInfo.severity === 'success' ? <CustomAlert severity={signInInfo.severity} message={signInInfo.message} show={true} clearState={clearSignInInfo} /> : null
            }
            <Box className={styles.bg}></Box>
            <Grid container spacing={1} >
                <Grid item md></Grid>
                <Grid item md={6} xs={12}>
                    <div className={styles.logo_wrapper}>
                        <div>
                            <Link to="/">
                                <img src={Logo} alt="fastlogistics" />
                            </Link>
                        </div>
                    </div>
                    <Paper className={styles.login_form_wrapper} elevation={10} variant="outlined">
                        <Box className={styles.login_top_section}>
                            {pageLoading ? (
                                <Skeleton width="40%" height={35} variant="rect" />


                            ) : (
                                    <Typography variant="h5" color="initial" align="center" className={styles.page_title} gutterBottom>ADMIN SIGN UP</Typography>
                                )}

                        </Box>
                        <Divider variant="middle" />
                        <Box className={styles.login_middle_section}>
                            <Formik
                                initialValues={{
                                    username: '',
                                    email: '',
                                    password: '',
                                }}

                                onSubmit={(values, { setSubmitting, resetForm }) => {
                                    setTimeout(() => {
                                        setSubmitting(false);
                                        SignUp(values);
                                    }, 500);
                                }}

                                validationSchema={LoginValidationScheme}
                            >
                                {({ submitForm, isSubmitting }) => (
                                    <Form>
                                        {
                                            pageLoading ? (
                                                <Skeleton variant="rect" width="100%" height={50} />
                                            ) : (
                                                    <Field
                                                        component={TextField}
                                                        name="username"
                                                        type="text"
                                                        label="Username"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <EmailIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        fullWidth
                                                        className={styles.form_field}
                                                    />
                                                )
                                        }

                                        <br />
                                        {
                                            pageLoading ? (
                                                <Skeleton variant="rect" width="100%" height={50} />
                                            ) : (
                                                    <Field
                                                        component={TextField}
                                                        name="email"
                                                        type="email"
                                                        label="Email"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <EmailIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        fullWidth
                                                        className={styles.form_field}
                                                    />
                                                )
                                        }

                                        <br />
                                        {
                                            pageLoading ? (
                                                <Skeleton variant="rect" width="100%" height={50} />
                                            ) : (
                                                    <Field
                                                        component={TextField}
                                                        type={showPassword ? 'text' : 'password'}
                                                        label="Password"
                                                        name="password"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <LockIcon />
                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: (
                                                                <InputAdornment position="start" onClick={() => setshowPassword(!showPassword)}>
                                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        fullWidth
                                                        className={styles.form_field}

                                                    />
                                                )
                                        }

                                        {isSubmitting && <LinearProgress />}
                                        <br />
                                        {
                                            pageLoading ? (
                                                <Skeleton variant="rect" width="25%" height={50} />
                                            ) : (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={isSubmitting}
                                                        onClick={submitForm}
                                                    >
                                                        Submit
                                                    </Button>
                                                )
                                        }


                                    </Form>
                                )}
                            </Formik>
                        </Box>

                    </Paper>
                </Grid>
                <Grid item md></Grid>
            </Grid>
        </Box>
    )
}


const mapStateToProps = (state: any) => {

    return {
        signInInfo: state.auth.signinInfo,
        auth: state.firebase.auth,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        clearSignInInfo: () => dispatch({ type: SIGNIN_FEEDBACK, payload: { severity: '', message: '' } }),
        SignUp: (creden: { email: string; password: string; username: string; }) => dispatch(AdminSignupAction(creden))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminSignUp);

