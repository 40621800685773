import React, { Fragment } from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

interface Props {
    severity: 'error' | 'warning' | 'info' | 'success';
    message: string;
    show: boolean;
    clearState: any;
}
const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CustomAlert = ({ severity, message, show, clearState }: Props) => {
    const [open, setOpen] = React.useState(show);
  
    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        clearState();
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

   
    return (
        <Fragment>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert severity={severity}>{message}</Alert>
            </Snackbar>
        </Fragment>
    )
}

export default CustomAlert;
