import React from 'react';
import { Redirect } from "react-router-dom";
// import firebase from '../../../config/firebaseConfig';
import { isLoaded } from 'react-redux-firebase'
//connect react 
import { connect } from 'react-redux';

const RedirectPage = ({ auth }: any) => {

    if(isLoaded(auth)){
        if (auth.displayName === 'logisticsCompany') {
            return <Redirect to="/Dashboard" />
        }
        else if (auth.displayName === 'customer') {
            return <Redirect to="/CustomerDashboard" />
        } 
        else if (auth.displayName === 'admin') {
            return <Redirect to="/AdminDashboard" />;
        }
    }

       

    return (
        <div>
            <p>Please  wait ... redirect...</p>
        </div>
    )
}


const mapStateToProps = (state: any) => {
   
    return {
        signInInfo: state.auth.signinInfo,
        auth: state.firebase.auth,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RedirectPage);
