import React, { Fragment } from "react";
import { Link } from "react-router-dom";
// import Contact from '../../components/Contact/Contact';
// logo
// import Logo from '../../assets/images/logo2.png';
import Logo from "./img/logo2.png";
// import BannerImage1 from './img/banner/banner.png';
// import ContactBg from './img/banner/contact_bg.png';

import "./css/bootstrap.min.css";
// import "./css/owl.carousel.min.css";
// import "./css/magnific-popup.css";
import "./css/font-awesome.min.css";
import "./css/themify-icons.css";
import "./css/nice-select.css";
import "./css/flaticon.css";
import "./css/gijgo.css";
import "./css/animate.css";
import "./css/slick.css";
import "./css/slicknav.css";
// import "https://ajax.googleapis.com/ajax/libs/jqueryui/1.11.2/themes/smoothness/jquery-ui.css";
import "./css/style.css";

const HowItWorks = () => {
	return (
		<Fragment>
			<header>
				<div className="header-area ">
					<div className="header-top_area d-none d-lg-block">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-xl-4 col-lg-4">
									<div className="logo">
										<Link to="/">
											<img src={Logo} alt="fastlogistics logo" />
										</Link>
									</div>
								</div>
								<div className="col-xl-8 col-md-8">
									<div className="header_right d-flex align-items-center">
										<div className="short_contact_list">
											<ul>
												<li>
													<a href="!#">
														{" "}
														<i className="fa fa-envelope"></i>{" "}
														info@fastlogisticsng.com
													</a>
												</li>
												<li>
													<a href="!#">
														{" "}
														<i className="fa fa-phone"></i> 1601-609 6780
													</a>
												</li>
											</ul>
										</div>

										<div className="book_btn d-none d-lg-block">
											<a
												className="boxed-btn3-line"
												target="_blank"
												rel="noopener noreferrer"
												href="https://docs.google.com/forms/d/e/1FAIpQLSeWM8n6lyurRLRH8tE0lUP93HgS_HyqbXqZ4cSui8fZG-KXTw/viewform"
											>
												Order A Dispatch
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div id="sticky-header" className="main-header-area">
						<div className="container">
							<div className="header_bottom_border">
								<div className="row align-items-center">
									<div className="col-12 d-block d-lg-none">
										<div className="logo">
											<a href="/">
												<img
													src={Logo}
													alt="fastlogistics logo"
													style={{ width: "50px", height: "50px" }}
												/>
											</a>
										</div>
									</div>
									<div className="col-xl-9 col-lg-9">
										<div className="main-menu  d-none d-lg-block">
											<nav>
												<ul id="navigation">
													<li>
														<Link to="/">home</Link>{" "}
													</li>
													<li>
														<Link to="/About">about</Link>
													</li>
													<li>
														<Link to="/how-it-works">How it works</Link>
													</li>
													<li>{/* <Link to="/Contact">Contact</Link> */}</li>
												</ul>
											</nav>
										</div>
									</div>
									{/* <div className="col-xl-3 col-lg-3 d-none d-lg-block">
                                        <div className="Appointment justify-content-end">
                                            <div className="search_btn">
                                                <a data-toggle="modal" data-target="#exampleModalCenter" href="#!">
                                                    <i className="ti-search"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div> */}
									<div className="col-12">
										<div className="mobile_menu d-block d-lg-none"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>

			<div className="transportaion_area">
				<div className="container">
					<div className="row">
						<div className="col-xl-6 col-lg-6 col-md-6">
							<h4>How it simply works</h4>
							<p>
								Fast logistics is targeted at solving the following issues
								including but not limited to:
							</p>
							<br />
							<ul>
								<li>
									Step 1 <br />
									Go to fastlogisticsng.com.
								</li>
								<br />
								<li>
									Step 2<br />
									Click on Order A Dispatch.
								</li>
								<br />
								<li>
									Step 3 <br />
									Fill out the form.
								</li>
								<br />
								<li>
									Step 4 <br />
									Wait for a dispatch to call and give you a quote.
								</li>
								<br />
								<li>
									Step 5 <br />
									Bookmark fastlogisticsng.com
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			{/* <!-- footer start --> */}
			<footer className="footer">
				<div className="footer_top">
					<div className="container">
						<div className="row">
							<div className="col-xl-3 col-md-6 col-lg-3"></div>
							<div className="col-xl-2 col-md-6 col-lg-2">
								<div className="footer_widget">
									<h3 className="footer_title">Fastlogisticsng</h3>
									<ul>
										<li>
											<Link to="/">Home</Link>{" "}
										</li>
										<li>
											<Link to="/About">About</Link>
										</li>
										<li>
											<Link to="/how-it-works">How it works</Link>
										</li>
										<li>{/* <Link to="/Contact">Contact</Link> */}</li>
									</ul>
								</div>
							</div>
							<div className="col-xl-3 col-md-6 col-lg-3"></div>
							<div className="col-xl-4 col-md-6 col-lg-4">
								{/* <div className="footer_widget">
									<h3 className="footer_title">Subscribe</h3>
									<form action="#" className="newsletter_form">
										<input type="text" placeholder="Enter your mail" />
										<button type="submit">Subscribe</button>
									</form>
									<p className="newsletter_text">
										Get up to date information on the best deals on logistics
									</p>
								</div> */}
							</div>
						</div>
					</div>
				</div>
				<div className="copy-right_text">
					<div className="container">
						<div className="footer_border"></div>
						<div className="row">
							<div className="col-xl-12">
								<p className="copy_right text-center">
									Copyright &copy;2020 All rights reserved{" "}
									<a
										href="https://scitylanang.com"
										rel="noopener noreferrer"
										target="_blank"
									>
										Scitylana
									</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</footer>
			{/* <!--/ footer end  --> */}
		</Fragment>
	);
};

export default HowItWorks;
