//import firebase
import firebase from '../config/firebaseConfig';
//import reducer
import rootReducer from './reducers/rootReducer';
//binding for fireebase
import { applyMiddleware, compose, createStore } from "redux";
import {
  reduxFirestore,
  getFirestore,
} from "redux-firestore";
import {  getFirebase } from "react-redux-firebase";
//middleware
import thunk from "redux-thunk";

export const rrfConfig = {
  // userProfile: 'leaderBoard',
  // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}

const middlewares = [thunk.withExtraArgument({getFirebase,getFirestore})];
export const store = createStore(
  rootReducer,
  compose(applyMiddleware(...middlewares), reduxFirestore(firebase,rrfConfig))
);
