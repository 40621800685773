// import { IBooking } from "../../../ts/interfaces/booking";
// import { SendNotification } from '../notification/sendNotificationAction';
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';

export const EditPendingBooking = (edit_pending_booking: any): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

        firebase.firestore().collection('Bookings').doc(edit_pending_booking.booking_id).update({
            customer_id: edit_pending_booking.customer_id,
            logistics_company_id: '',
            persona: edit_pending_booking.persona,
            pickup_at: {
                address: edit_pending_booking.pickup_at.address,
                country: edit_pending_booking.pickup_at.country,
                state: edit_pending_booking.pickup_at.state,
                city: edit_pending_booking.pickup_at.city,
            },
            destination_to: {
                address: edit_pending_booking.destination_to.address,
                country: edit_pending_booking.destination_to.country,
                state: edit_pending_booking.destination_to.state,
                city: edit_pending_booking.destination_to.city
            },
            sender: {
                name: edit_pending_booking.sender.name,
                phone_number: edit_pending_booking.sender.phone_number,
                email: edit_pending_booking.sender.email,
            },
            reciever: {
                name: edit_pending_booking.reciever.name,
                phone_number: edit_pending_booking.reciever.phone_number,
                email: edit_pending_booking.reciever.email,
            },
            third_party: edit_pending_booking.third_party,
            items: {
                catergory: edit_pending_booking.items.catergory,
                description: edit_pending_booking.items.description,
                quantity: edit_pending_booking.items.quantity,
                weight: edit_pending_booking.items.weight,
                length: edit_pending_booking.items.length,
                breadth: edit_pending_booking.items.breadth,
                height: edit_pending_booking.items.height,
            },
            pending: {
                status: true,
                on: firebase.firestore.FieldValue.serverTimestamp(),
            },
            created_on: firebase.firestore.FieldValue.serverTimestamp(),
            // price_per_mile: edit_pending_booking.price_per_mile,
            // miles: edit_pending_booking.miles,
            number_of_seats: edit_pending_booking.number_of_seats,
            type_of_transport: edit_pending_booking.type_of_transport,
            delivery_of_item_type: edit_pending_booking.delivery_of_item_type,
            item_check_list: {
                have_you_put_name_of_reciever_on_the_parcel: edit_pending_booking.item_check_list.have_you_put_name_of_reciever_on_the_parcel,
                have_you_put_mobile_number_of_reciever_on_the_parcel: edit_pending_booking.item_check_list.have_you_put_mobile_number_of_reciever_on_the_parcel,
                have_you_ensured_the_parcel_is_secured: edit_pending_booking.item_check_list.have_you_ensured_the_parcel_is_secured,
                position_of_item: edit_pending_booking.item_check_list.position_of_item,

            },

        })
            .then(() => {
                dispatch({ type: 'SIGNUP_FEEDBACK', payload: { severity: 'success', message: "Booking Edited!" } });
                // dispatch(SendNotification({
                //     to:  edit_pending_booking.customer_id,
                //     subject: 'New Booking Added!',
                //     body: 'You have edited a new booking, its still in pending phase until you pick a logistics company',
                //     severity: 'info'
                // }));
            })
            .catch(() => {
                dispatch({ type: 'SIGNUP_FEEDBACK', payload: { severity: 'error', message: "Oops! Sorry an error occured , please try again" } });
            })

    }