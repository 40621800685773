import React, { Fragment } from 'react';

//components
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

//Alert Component
import CustomAlert from '../../components/CustomAlert/CustomAlert';

//connect react 
import { connect } from 'react-redux';

import { sendPasswordReset } from '../../store/actions/auth/PasswordReset/passwordresetActions';
import {EditCustomer} from '../../store/actions/account/customer/editCustomerAccountAction';

//formik
import { Formik, Form, Field } from 'formik';
import { LinearProgress } from '@material-ui/core';
import { TextField } from 'formik-material-ui';

const AccountWindow = ({ currentUserEmail, signUpInfo, clearSignUpInfo, PasswordReset ,user,editAccount}: any) => {

    return (
        <Fragment>
            {
                signUpInfo.severity === 'error' || signUpInfo.severity === 'warning' || signUpInfo.severity === 'info' || signUpInfo.severity === 'success' ? <CustomAlert severity={signUpInfo.severity} message={signUpInfo.message} show={true} clearState={clearSignUpInfo} /> : null
            }
            <Grid container>
                <Grid item xs={12} lg={2}></Grid>
                <Grid item xs={12} lg={8}>
                    <div style={{ width: '100%', height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10%' }}>
                        <div> <Button variant="contained" color="primary" onClick={() => PasswordReset({ email: currentUserEmail })}>Reset Password</Button></div>
                    </div>
                    <Formik
                        initialValues={{
                            id:user.id,
                            customer_first_name: user.customer_first_name,
                            customer_last_name: user.customer_last_name,
                            customer_phone_number: user.customer_phone_number,
                        }}

                        onSubmit={(values, { setSubmitting, resetForm }) => {

                            setTimeout(() => {
                                setSubmitting(false);
                                editAccount(values);
                            }, 500);
                        }}
                    >
                        {({ submitForm, isSubmitting, resetForm }) => (
                            <Form>
                                <Field
                                    component={TextField}
                                    name="customer_first_name"
                                    type="text"
                                    label="First Name"

                                    fullWidth
                                />
                                <br />
                                <br />
                                <Field
                                    component={TextField}
                                    name="customer_last_name"
                                    type="text"
                                    label="Last Name"

                                    fullWidth
                                />
                                <br />
                                <br />
                                <Field
                                    component={TextField}
                                    name="customer_phone_number"
                                    type="text"
                                    label="Phone number"

                                    fullWidth
                                />
                                <br />
                                <br />

                                {isSubmitting && <LinearProgress />}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting}
                                    onClick={submitForm}
                                >
                                    Submit
                                </Button>
                            </Form>
                        )}
                    </Formik>

                </Grid>
                <Grid item xs={12} lg={2}></Grid>
            </Grid>
        </Fragment>
    )
}



const mapStateToProps = (state: any) => {

    return {
        signUpInfo: state.auth.signupInfo,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        clearSignUpInfo: () => dispatch({ type: 'PASSWORD_RESET_FEEDBACK', payload: { severity: '', message: '' } }),
        PasswordReset: (creden: { email: string }) => dispatch(sendPasswordReset(creden)),
        editAccount: ({customer_first_name,customer_last_name,customer_phone_number,id}:{customer_first_name:string;customer_last_name:string;customer_phone_number:string;id:string})=> dispatch(EditCustomer({customer_first_name,customer_last_name,customer_phone_number,id})),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountWindow);
