import React, { useState, useEffect } from "react";

import firebase from "../../config/firebaseConfig";

// make a reference to our firebase storage
const storage = firebase.storage().ref();

const GetImageFromFireBase = ({ source }: any) => {
  const [photoUrl, setphotoUrl] = useState("");
  // fetch the download URL from firebase
  useEffect(() => {
    if (typeof source !== 'undefined') {
      storage
        .child("images/" + source)
        .getDownloadURL()
        .then((url) => {
          setphotoUrl(url);
        })
        .catch((err) => {
          console.log(err.message)
        });
    }


  }, [source])


  return  <img src={photoUrl} alt={photoUrl} />
};

export default GetImageFromFireBase;
