

import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';

export const DeleteBooking = (booking_id: any): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

        firebase.firestore().collection('Bookings').doc(booking_id).delete()
            .then(() => {
                dispatch({ type: 'SIGNUP_FEEDBACK', payload: { severity: 'error', message: "Booking Deleted!" } });
            })
            .catch(() => {
                dispatch({ type: 'SIGNUP_FEEDBACK', payload: { severity: 'error', message: "Oops! Sorry an error occured , please try again" } });
            })

    }