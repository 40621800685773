

import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';

export const UnBlockLogisticsCompany = (id: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

        firebase.firestore().collection('LogisticsCompanies').doc(id).update({
            blocked: false,
        })
            .then(() => {
                dispatch({ type: 'SIGNUP_FEEDBACK', payload: { severity: 'success', message: "Account Unblocked!" } })
            })


    }