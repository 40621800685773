import firebase from 'firebase/app';
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";


const firebaseConfig = {
  apiKey: "AIzaSyAYVMr5WMxDnEeCTyhT55qW_XDiAJt9Qds",
  authDomain: "fastlogisticsng-6184a.firebaseapp.com",
  databaseURL: "https://fastlogisticsng-6184a.firebaseio.com",
  projectId: "fastlogisticsng-6184a",
  storageBucket: "fastlogisticsng-6184a.appspot.com",
  messagingSenderId: "184446268978",
  appId: "1:184446268978:web:011ba1289a0f519f072e2b",
  measurementId: "G-J92VT2RK2K"
};


// Initialize firebase instance
firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default firebase;



