import { ILogisticsCompanyAccount } from "../../../../ts/interfaces/logisticsCompany";

import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';

export const EditLogisticsCompany = (logistics_company_account: ILogisticsCompanyAccount): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

        return firebase.firestore().collection('LogisticsCompanies').doc(logistics_company_account.id).update({
            company_name: logistics_company_account.company_name,
            company_address: logistics_company_account.company_address,
            CACnumber: logistics_company_account.CACnumber,
            type_of_delivery: logistics_company_account.type_of_delivery,
            type_of_cargo_delivery_option: logistics_company_account.type_of_cargo_delivery_option,
            type_of_human_delivery_option: logistics_company_account.type_of_human_delivery_option,
            price_per_mile: logistics_company_account.price_per_mile,
            representative_data: {
                name: logistics_company_account.representative_data.name,
                phone_number: logistics_company_account.representative_data.phone_number,
                email: logistics_company_account.representative_data.email,
                position: logistics_company_account.representative_data.position,
            },
            company_logo_ID: logistics_company_account.company_logo_ID,
            operational_countries: logistics_company_account.operational_countries,
            // operational_states: logistics_company_account.operational_states,
            edited_on: firebase.firestore.FieldValue.serverTimestamp(),

        })
            .then(() => {
                dispatch({ type: 'SIGNUP_FEEDBACK', payload: { severity: 'success', message: "Account Updated!" } })
            })
            

    }