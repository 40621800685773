import React, { Fragment } from 'react';

//components
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

//Alert Component
import CustomAlert from '../../components/CustomAlert/CustomAlert';

//connect react 
import { connect } from 'react-redux';

import { sendPasswordReset } from '../../store/actions/auth/PasswordReset/passwordresetActions';



const LogisticsAccountWindow = ({ currentUserEmail, signUpInfo, clearSignUpInfo, PasswordReset }: any) => {

    return (
        <Fragment>
            {
                signUpInfo.severity === 'error' || signUpInfo.severity === 'warning' || signUpInfo.severity === 'info' || signUpInfo.severity === 'success' ? <CustomAlert severity={signUpInfo.severity} message={signUpInfo.message} show={true} clearState={clearSignUpInfo} /> : null
            }
            <Grid container>
                <Grid item xs={12} lg={2}></Grid>
                <Grid item xs={12} lg={8}>
                    <div style={{ width: '100%', height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20%' }}>
                        <div> <Button variant="contained" color="primary" onClick={() => PasswordReset({ email: currentUserEmail })}>Reset Password</Button></div>
                    </div>

                </Grid>
                <Grid item xs={12} lg={2}></Grid>
            </Grid>
        </Fragment>
    )
}



const mapStateToProps = (state: any) => {

    return {
        signUpInfo: state.auth.signupInfo,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        clearSignUpInfo: () => dispatch({ type: 'PASSWORD_RESET_FEEDBACK', payload: { severity: '', message: '' } }),
        PasswordReset: (creden: { email: string }) => dispatch(sendPasswordReset(creden)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogisticsAccountWindow);
