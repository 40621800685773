import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Redirect, Link } from "react-router-dom";
import { default as MyTextField } from '@material-ui/core/TextField';
//formik
import { Formik, Form, Field } from 'formik';
import { Button, LinearProgress, Box } from '@material-ui/core';
import { TextField, CheckboxWithLabel } from 'formik-material-ui';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-material-ui-lab';


//icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Business from '@material-ui/icons/Business';
import CardMembership from '@material-ui/icons/CardMembership';
// import LocationOn from '@material-ui/icons/LocationOn';
import Call from '@material-ui/icons/Call';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { Select } from 'formik-material-ui';

import BGImage from '../../../assets/images/pexels-photo-4393444.jpeg';

//signupinfo type
import { SIGNUP_FEEDBACK } from "../../../ts/types/signup";
import { ILogisticsCompanyAccount } from "../../../ts/interfaces/logisticsCompany";

//yup
import * as yup from 'yup';

//firebase image uploader
import ImageUploader from '../../../components/ImageUploader';

//Alert Component
import CustomAlert from '../../../components/CustomAlert/CustomAlert';

//Utils
import { getBrowserProps } from '../../../Utils/getBrowerProps';

//signup Actions
import { NewLogisticsCompanyAccount } from '../../../store/actions/auth/signup/logisticsCompany';
// import CircularProgress from '@material-ui/core/CircularProgress';


// import LazyLoad from 'react-lazyload';
//firebase
import firebase from '../../../config/firebaseConfig';

//connect react 
import { connect } from 'react-redux';



//images
import BikeImage from '../../../assets/images/bike.png';
import MotorBikeImage from '../../../assets/images/motorbike.jpg';
import VanImage from '../../../assets/images/van.png';
import TruckImage from '../../../assets/images/truck.jpg';
import AirPlaneImage from '../../../assets/images/airplane.jpg';
import CargoShipImage from '../../../assets/images/cargoship.jpg';
import SaloonCarImage from '../../../assets/images/salooncar.jpg';
import PowerBikeImage from '../../../assets/images/powerbike.jpg';
import SiennaImage from '../../../assets/images/sienna.jpg';
import KekeImage from '../../../assets/images/keke.jpg';
import HiluxImage from '../../../assets/images/hilux.jpg';
import CoasterImage from '../../../assets/images/coaster.jpg';
import TourImage from '../../../assets/images/tourbus.jpg';
import Logo from '../../../assets/images/logo2.png';
//@ts-ignore
import CountryStateCityJson from 'countrycitystatejson';

//google autocomplete
// import GooglePlaceAutoComplete from '../../../components/GooglePlaceAutoComplete';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


function loadScript(src: string, position: HTMLElement | null, id: string) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };


interface PlaceType {
    description: string;
    structured_formatting: {
        main_text: string;
        secondary_text: string;
        main_text_matched_substrings: [
            {
                offset: number;
                length: number;
            },
        ];
    };
}


//signup yup schema

const LogisticsCompanyAccountSchema = yup.object().shape({
    company_name: yup
        .string()
        .required('Your company name is required!')
    ,
    CACnumber: yup
        .string()
        .required('Your company name is required!')
    ,
    type_of_delivery: yup
        .string()
    ,
    type_of_cargo_delivery_option: yup
        .array().of(yup.string())
    ,
    type_of_human_delivery_option: yup
        .array().of(yup.string())

    ,
    price_per_mile: yup
        .number()
        .min(45, 'Minimum price per mile is 45'),
    // ,
    representative_data: yup
        .object({
            name: yup.string().required('We need a Name of Someone the customers can talk to'),
            phone_number: yup.string().required('We need a the representative phone number'),
            position: yup.string(),
            email: yup.string().email(),
        }),
    // ,
    // company_logo_ID: yup.string(),
    operational_countries: yup
        .array().of(yup.string()).nullable()
        .min(1, 'You must select at least one country you operate in')
    ,
    email: yup
        .string()
        .email(),

    password: yup
        .string()
        .label('Password'),
    confirmpassword: yup
        .string()
        .label('Confirm Password')
        .test('passwords-match', 'Ouch! Passwords do not match!', function (value) {
            return this.parent.password === value
        })
})

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        button: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
        signup_wrapper: {
            height: "99.2vh",
            width: "99.8vw"
        },
        signup_form_wrapper: {
            width: "100%",
            height: "auto",
            marginTop: theme.spacing(10),
            backgroundColor: "white",
            paddingBottom: 20,
        },
        signup_top_section: {
            height: 50,
            paddingTop: 20,
        },
        signup_middle_section: {
            paddingLeft: "50px",
            paddingRight: "50px",
            paddingTop: "20px"

        },
        signup_bottom_section: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "20px",
            paddingLeft: "30px",
            paddingRight: "30px",
        },
        page_title: {
            fontWeight: "bolder",
        },
        form_field: {
            marginBottom: "50px",
        },
        bg: {
            position: "fixed",
            top: 0,
            zIndex: -1,
            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            border: 0,
            borderRadius: 3,
            // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
            width: "100%",
            height: "300px",
        },
        delivery_options: {
            width: '98%',
            height: 150,
            backgroundColor: 'white',
            borderRadius: 10,
            marginBottom: 10,
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            flexDirection: 'row',
            '& img': {
                width: 120,
                height: 120,
            }
        },
        operation_countries_wrapper: {
            width: '98%',
            height: 60,
            padding: 10,
            backgroundColor: 'white',
            borderRadius: 10,
            marginBottom: 10,
        },
        bg_image_wrapper: {
            position: 'absolute',
            right: 0,
            top: 0,
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& div ': {
                width: 200,
                height: 'auto',
                backgroundColor: 'white',
                boxShadow: theme.shadows[11],
                '& img': {
                    width: '100%'
                }
            }
        },
        show_in_mobile: {
            display: 'none',

            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
            }
        },
        icon: {
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(2),
        },
    }),
);

function getSteps() {
    return ['Your company details', 'Type of delivery', 'Set price of parcel', 'company logo and banner', 'operation coiuntry', 'Company Representative Details', 'Secure your account'];
}




function SignupPage({ signUpInfo, clearSignUpInfo, createAccount, auth }: any) {
    const styles = useStyles();
    const [activeStep, setActiveStep] = useState(0);

    const [showPassword, setshowPassword] = useState(false);
    // const [nextButtonState, setnextButtonState] = useState(true);
    const [photoid, setphotoid] = useState('');
    const [clientDevicedata, setclientDevicedata] = useState({});
    const [value, setValue] = React.useState<PlaceType | null>(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<PlaceType[]>([]);
    const loaded = React.useRef(false);

    const steps = getSteps();

    useEffect(() => {

        getBrowserProps().then((data: any) => {
            setclientDevicedata({ ...data })
        });


    }, []);

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                'https://maps.googleapis.com/maps/api/js?key=AIzaSyAYVMr5WMxDnEeCTyhT55qW_XDiAJt9Qds&libraries=places',
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    const fetch = React.useMemo(
        () =>
            throttle((request: { input: string }, callback: (results?: PlaceType[]) => void) => {
                (autocompleteService.current as any).getPlacePredictions(request, callback);
            }, 200),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && (window as any).google) {
            autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results?: PlaceType[]) => {
            if (active) {
                let newOptions = [] as PlaceType[];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        // setnextButtonState(true);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = (resetForm: any) => {
        setActiveStep(0);
        setValue(null);
        resetForm();
    };

    const handleImageUploadSaved = (id: string) => {
        // console.log(id);
        setphotoid(id);
        // setnextButtonState(false);
    };

    const handleImageUploadCleared = () => {

        // setphotoid('');
        // setphotoid('test image');
        // setnextButtonState(false);
    };

    if (auth.uid) {
        return <Redirect to="/Redirect" />;
    }

    firebase.auth().onAuthStateChanged(user => {
        if (user) {
            return <Redirect to="/Redirect" />;
        } else {

        }
    })

    return (
        <div className={styles.root}>
            {
                signUpInfo.severity === 'error' || signUpInfo.severity === 'warning' || signUpInfo.severity === 'info' || signUpInfo.severity === 'success' ? <CustomAlert severity={signUpInfo.severity} message={signUpInfo.message} show={true} clearState={clearSignUpInfo} /> : null
            }
            <div className={styles.show_in_mobile}>
                <div style={{ width: '60%' }}>
                    <Link to="/">
                        <img src={Logo} alt="fastlogistics logo" style={{ width: '100%' }} />
                    </Link>
                </div>
                <Link to="/login">
                    Already have an account?  Click to login
                </Link>
            </div>
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <div style={{ width: '100%', height: '100vh', overflowX: 'hidden', overflowY: 'auto', }}>
                        <Typography variant="h5" align="center" ><Link to="/login">
                            Already have an account?  Click to login
                        </Link></Typography>
                        <Typography variant="h5" align="center" >OR</Typography>
                        <Typography variant="h4" align="center" >Register your Logistics Company</Typography>
                        <Formik
                            initialValues={{
                                company_name: '',
                                company_address: '',
                                CACnumber: '',
                                type_of_delivery: '',
                                type_of_cargo_delivery_option: [],
                                type_of_human_delivery_option: [],
                                price_per_mile: null,
                                representative_data: {
                                    name: '',
                                    phone_number: '',
                                    email: '',
                                    position: '',
                                },
                                email: '',
                                password: '',
                                confirmpassword: '',
                                company_logo_ID: '',
                                operational_countries: [],

                            }}

                            validationSchema={LogisticsCompanyAccountSchema}
                            onSubmit={(values, { setSubmitting, resetForm }) => {

                                setTimeout(() => {
                                    setSubmitting(false);
                                    values.company_logo_ID = photoid;
                                    //@ts-ignore
                                    values.company_address = value.description;
                                    // alert(JSON.stringify(values, null, 2));
                                    // console.log(values);
                                    createAccount(values, clientDevicedata);
                                    resetForm();
                                }, 500);
                            }}
                        >
                            {({ submitForm, isSubmitting, resetForm, errors, touched, setFieldValue, values }) => (
                                <Form>



                                    <Stepper activeStep={activeStep} orientation="vertical">
                                        <Step key="0" active={activeStep === 0 ? true : false}>
                                            <StepLabel key="0.1" >Your company details </StepLabel>
                                            <StepContent key="0.2" >
                                                {/* <GooglePlaceAutoComplete /> */}
                                                <Field
                                                    component={TextField}
                                                    name="company_name"
                                                    type="text"
                                                    label="Company Name"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Business />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    fullWidth
                                                />
                                                <br />

                                                {/* <Field
                                                    component={TextField}
                                                    name="company_address"
                                                    type="text"
                                                    label="Company Address"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <LocationOn />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    fullWidth
                                                    className={styles.form_field}
                                                /> */}
                                                <FormControl fullWidth>
                                                    <Field
                                                        name="company_address"
                                                        component={Autocomplete}
                                                        id="google-map-demo"

                                                        getOptionLabel={(option: any) => (typeof option === 'string' ? option : option.description)}
                                                        filterOptions={(x: any) => x}
                                                        options={options}
                                                        autoComplete
                                                        includeInputInList
                                                        filterSelectedOptions
                                                        value={value}
                                                        onChange={(event: any, newValue: PlaceType | null) => {
                                                            setOptions(newValue ? [newValue, ...options] : options);
                                                            setValue(newValue);

                                                        }}
                                                        onInputChange={(event: any, newInputValue: any) => {
                                                            setInputValue(newInputValue);
                                                            setFieldValue('company_address', newInputValue, false);
                                                        }}
                                                        renderInput={(params: any) => (
                                                            <MyTextField {...params} label="Company address" fullWidth />

                                                        )}
                                                        renderOption={(option: any) => {
                                                            const matches = option.structured_formatting.main_text_matched_substrings;
                                                            const parts = parse(
                                                                option.structured_formatting.main_text,
                                                                matches.map((match: any) => [match.offset, match.offset + match.length]),
                                                            );

                                                            return (
                                                                <Grid container alignItems="center">
                                                                    <Grid item>
                                                                        <LocationOnIcon className={styles.icon} />
                                                                    </Grid>
                                                                    <Grid item xs>
                                                                        {parts.map((part, index) => (
                                                                            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                                                {part.text}
                                                                            </span>
                                                                        ))}
                                                                        <Typography variant="body2" color="textSecondary">
                                                                            {option.structured_formatting.secondary_text}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        }}
                                                    />
                                                </FormControl>
                                                <br />
                                                <br />
                                                <Field
                                                    component={TextField}
                                                    name="CACnumber"
                                                    type="text"
                                                    label="Corporate Affairs Commission Number"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <CardMembership />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    fullWidth
                                                    className={styles.form_field}

                                                />
                                                <div className={styles.actionsContainer}>
                                                    {isSubmitting && <LinearProgress />}
                                                    <div>
                                                        <Button
                                                            disabled={activeStep === 0}
                                                            onClick={handleBack}
                                                            className={styles.button}
                                                        >
                                                            Back
                                                        </Button>
                                                        {
                                                            !errors.company_name && touched.company_name && !errors.CACnumber && touched.CACnumber && values.company_address.length > 5 ?
                                                                (<Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={handleNext}
                                                                    className={styles.button}

                                                                >
                                                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                                </Button>) :
                                                                (null)
                                                        }

                                                    </div>
                                                </div>

                                            </StepContent>

                                        </Step>

                                        <Step key="1" active={activeStep === 1 ? true : false}>
                                            <StepLabel key="1.1">Type of Delivery Service</StepLabel>
                                            <StepContent key="1.2">
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="type-of-delivery">Select type of delivery</InputLabel>
                                                    <Field
                                                        component={Select}
                                                        name="type_of_delivery"
                                                        inputProps={{
                                                            id: 'type-of-delivery',
                                                        }}


                                                    >
                                                        <MenuItem value="Parcel">Parcel</MenuItem>
                                                        <MenuItem value="Human">Human</MenuItem>

                                                    </Field>
                                                </FormControl>
                                                <br />
                                                <br />

                                                {
                                                    values.type_of_delivery === 'Parcel' ? (<Fragment>
                                                        <Typography variant="subtitle1" align="center">Select one or more Cargo vechicles your company uses for delivery</Typography>
                                                        <Grid container>
                                                            <Grid item xs={12} lg={6}>
                                                                <Box className={styles.delivery_options} boxShadow={5}>
                                                                    <Field
                                                                        component={CheckboxWithLabel}
                                                                        type="checkbox"
                                                                        name="type_of_cargo_delivery_option"
                                                                        Label={{ label: 'Bicycle Delivery' }}
                                                                        value="Bicycle"
                                                                    />
                                                                    <img src={BikeImage} alt="bicycle " />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <Box className={styles.delivery_options} boxShadow={5}>
                                                                    <Field
                                                                        component={CheckboxWithLabel}
                                                                        type="checkbox"
                                                                        name="type_of_cargo_delivery_option"
                                                                        Label={{ label: 'Bike Delivery' }}
                                                                        value="Bike"
                                                                    />
                                                                    <img src={MotorBikeImage} alt="bike " />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <Box className={styles.delivery_options} boxShadow={5}>
                                                                    <Field
                                                                        component={CheckboxWithLabel}
                                                                        type="checkbox"
                                                                        name="type_of_cargo_delivery_option"
                                                                        Label={{ label: 'Van Delivery' }}
                                                                        value="Van"
                                                                    />
                                                                    <img src={VanImage} alt="van delivery " />
                                                                </Box>
                                                            </Grid>

                                                            <Grid item xs={12} lg={6}>
                                                                <Box className={styles.delivery_options} boxShadow={5}>
                                                                    <Field
                                                                        component={CheckboxWithLabel}
                                                                        type="checkbox"
                                                                        name="type_of_cargo_delivery_option"
                                                                        Label={{ label: 'Truck Delivery' }}
                                                                        value="Truck"
                                                                    />
                                                                    <img src={TruckImage} alt="Truck delivery " />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <Box className={styles.delivery_options} boxShadow={5}>
                                                                    <Field
                                                                        component={CheckboxWithLabel}
                                                                        type="checkbox"
                                                                        name="type_of_cargo_delivery_option"
                                                                        Label={{ label: 'Air Delivery' }}
                                                                        value="Air"
                                                                    />
                                                                    <img src={AirPlaneImage} alt="Air delivery " />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <Box className={styles.delivery_options} boxShadow={5}>
                                                                    <Field
                                                                        component={CheckboxWithLabel}
                                                                        type="checkbox"
                                                                        name="type_of_cargo_delivery_option"
                                                                        Label={{ label: 'Ship Delivery' }}
                                                                        value="Ship"
                                                                    />
                                                                    <img src={CargoShipImage} alt="ship delivery " />
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Fragment>) : (null)
                                                }

                                                {
                                                    values.type_of_delivery === 'Human' ? (<Fragment>
                                                        <Typography variant="subtitle1" align="center">Select one or more Transport Vechicles your company uses for transport</Typography>
                                                        <Grid container>

                                                            <Grid item xs={12} lg={6}>
                                                                <Box className={styles.delivery_options} boxShadow={5}>
                                                                    <Field
                                                                        component={CheckboxWithLabel}
                                                                        type="checkbox"
                                                                        name="type_of_human_delivery_option"
                                                                        Label={{ label: '1 Seat' }}
                                                                        value="1"
                                                                    />
                                                                    <img src={PowerBikeImage} alt="1 seater " />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <Box className={styles.delivery_options} boxShadow={5}>
                                                                    <Field
                                                                        component={CheckboxWithLabel}
                                                                        type="checkbox"
                                                                        name="type_of_human_delivery_option"
                                                                        Label={{ label: '3 Seater' }}
                                                                        value="3"
                                                                    />
                                                                    <img src={KekeImage} alt="3 seater " />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <Box className={styles.delivery_options} boxShadow={5}>
                                                                    <Field
                                                                        component={CheckboxWithLabel}
                                                                        type="checkbox"
                                                                        name="type_of_human_delivery_option"
                                                                        Label={{ label: '4 Seater' }}
                                                                        value="4"
                                                                    />
                                                                    <img src={SaloonCarImage} alt="4 seater " />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <Box className={styles.delivery_options} boxShadow={5}>
                                                                    <Field
                                                                        component={CheckboxWithLabel}
                                                                        type="checkbox"
                                                                        name="type_of_human_delivery_option"
                                                                        Label={{ label: '7 Seater' }}
                                                                        value="7"
                                                                    />
                                                                    <img src={SiennaImage} alt="7 seater " />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <Box className={styles.delivery_options} boxShadow={5}>
                                                                    <Field
                                                                        component={CheckboxWithLabel}
                                                                        type="checkbox"
                                                                        name="type_of_human_delivery_option"
                                                                        Label={{ label: '12 Seater' }}
                                                                        value="12"
                                                                    />
                                                                    <img src={HiluxImage} alt="12 seater " />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <Box className={styles.delivery_options} boxShadow={5}>
                                                                    <Field
                                                                        component={CheckboxWithLabel}
                                                                        type="checkbox"
                                                                        name="type_of_human_delivery_option"
                                                                        Label={{ label: '22 Seater' }}
                                                                        value="22"
                                                                    />
                                                                    <img src={CoasterImage} alt="22 seater " />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <Box className={styles.delivery_options} boxShadow={5}>
                                                                    <Field
                                                                        component={CheckboxWithLabel}
                                                                        type="checkbox"
                                                                        name="type_of_human_delivery_option"
                                                                        Label={{ label: '36 - 60 Seater' }}
                                                                        value="36 - 60"
                                                                    />
                                                                    <img src={TourImage} alt="36 - 60 seater " />
                                                                </Box>
                                                            </Grid>

                                                        </Grid>
                                                    </Fragment>) : (null)
                                                }

                                                <div className={styles.actionsContainer}>
                                                    {isSubmitting && <LinearProgress />}
                                                    <div>
                                                        <Button
                                                            disabled={activeStep === 0}
                                                            onClick={handleBack}
                                                            className={styles.button}
                                                        >
                                                            Back
                                            </Button>
                                                        {
                                                            values.type_of_delivery === 'Parcel' && values.type_of_cargo_delivery_option.length >= 1 ?
                                                                (<Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={handleNext}
                                                                    className={styles.button}

                                                                >
                                                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                                </Button>) :
                                                                (null)
                                                        }
                                                        {
                                                            values.type_of_delivery === 'Human' && values.type_of_human_delivery_option.length >= 1 ?
                                                                (<Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={handleNext}
                                                                    className={styles.button}

                                                                >
                                                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                                </Button>) :
                                                                (null)
                                                        }
                                                    </div>
                                                </div>



                                            </StepContent>

                                        </Step>
                                        <Step key="2" active={activeStep === 2 ? true : false}>
                                            <StepLabel key="2.1"> Countries of Operation</StepLabel>

                                            <StepContent key="2.2">
                                                <Typography variant="subtitle1" align="center">Select one or more Countries your Company Operates</Typography>
                                                {/* <Grid container> */}
                                                <FormControl fullWidth>
                                                    <Field
                                                        multiple
                                                        name="operational_countries"
                                                        disableCloseOnSelect
                                                        component={Autocomplete}
                                                        options={CountryStateCityJson.getCountries().map((item: any) => {
                                                            return item.name
                                                        })}
                                                        getOptionLabel={(option: any) => option}
                                                        getOptionSelected={(option: any, value: any) => option === value}
                                                        limitTags={5}

                                                        renderOption={(option: any, { selected }: any) => (
                                                            <React.Fragment>
                                                                <Checkbox
                                                                    icon={icon}
                                                                    checkedIcon={checkedIcon}
                                                                    style={{ marginRight: 8 }}
                                                                    checked={selected}
                                                                />
                                                                {option}
                                                            </React.Fragment>
                                                        )}
                                                        renderInput={(params: AutocompleteRenderInputParams) => (

                                                            <MyTextField
                                                                {...params}
                                                                style={{ width: '100%' }}
                                                                type="text"
                                                            />

                                                        )}
                                                    />
                                                </FormControl>


                                                <div className={styles.actionsContainer}>
                                                    {isSubmitting && <LinearProgress />}
                                                    <div>
                                                        <Button
                                                            disabled={activeStep === 0}
                                                            onClick={handleBack}
                                                            className={styles.button}
                                                        >
                                                            Back
                                            </Button>
                                                        {
                                                            values.operational_countries.length >= 1 ?
                                                                (<Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={handleNext}
                                                                    className={styles.button}

                                                                >
                                                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                                </Button>) :
                                                                (null)
                                                        }
                                                    </div>
                                                </div>
                                            </StepContent>
                                        </Step>

                                        <Step key="3" active={activeStep === 3 ? true : false}>
                                            <StepLabel key="3.1"> Set price per mile of delivery</StepLabel>
                                            <StepContent key="3.2">
                                                <Field
                                                    component={TextField}
                                                    name="price_per_mile"
                                                    type="number"
                                                    label="Price per Mile (minimum is &#8358;45)"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="start">
                                                                <Typography>per mile</Typography>
                                                            </InputAdornment>
                                                        ),
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Typography>&#8358;</Typography>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    fullWidth
                                                    className={styles.form_field}
                                                />






                                                <div className={styles.actionsContainer}>
                                                    {isSubmitting && <LinearProgress />}
                                                    <div>
                                                        <Button
                                                            disabled={activeStep === 0}
                                                            onClick={handleBack}
                                                            className={styles.button}
                                                        >
                                                            Back
                                            </Button>
                                                        {
                                                            !errors.price_per_mile && touched.price_per_mile ?
                                                                (<Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={handleNext}
                                                                    className={styles.button}

                                                                >
                                                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                                </Button>) :
                                                                (null)
                                                        }
                                                    </div>
                                                </div>
                                            </StepContent>
                                        </Step>

                                        <Step key="4" active={activeStep === 4 ? true : false}>
                                            <StepLabel key="4.1">Your company logo </StepLabel>
                                            <StepContent key="4.2">
                                                <ImageUploader
                                                    onRequestSave={handleImageUploadSaved}
                                                    onRequestClear={handleImageUploadCleared}
                                                />
                                                <div className={styles.actionsContainer}>
                                                    {isSubmitting && <LinearProgress />}
                                                    <div>
                                                        <Button
                                                            disabled={activeStep === 0}
                                                            onClick={handleBack}
                                                            className={styles.button}
                                                        >
                                                            Back
                                            </Button>
                                                        {
                                                            photoid !== '' ?
                                                                (<Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={handleNext}
                                                                    className={styles.button}

                                                                >
                                                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                                </Button>) :
                                                                (null)
                                                        }
                                                    </div>
                                                </div>
                                            </StepContent>
                                        </Step>

                                        <Step key="5" active={activeStep === 5 ? true : false}>
                                            <StepLabel key="5.1"> Company Representative Details</StepLabel>
                                            <StepContent key="5.2">
                                                <Field
                                                    component={TextField}
                                                    name="representative_data.name"
                                                    type="text"
                                                    label="Representative Name"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <AccountCircleIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    fullWidth
                                                    className={styles.form_field}
                                                />
                                                <br />
                                                <Field
                                                    component={TextField}
                                                    name="representative_data.phone_number"
                                                    type="text"
                                                    label="Representative Phone Number"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Call />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    fullWidth
                                                    className={styles.form_field}
                                                />
                                                <br />
                                                <Field
                                                    component={TextField}
                                                    name="representative_data.email"
                                                    type="email"
                                                    label="Representative Email"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <EmailIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    fullWidth
                                                    className={styles.form_field}
                                                />
                                                <br />
                                                <Field
                                                    component={TextField}
                                                    name="representative_data.position"
                                                    type="text"
                                                    label="Representative Position"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <AccountCircleIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    fullWidth
                                                    className={styles.form_field}
                                                />


                                                <div className={styles.actionsContainer}>
                                                    {isSubmitting && <LinearProgress />}
                                                    <div>
                                                        <Button
                                                            disabled={activeStep === 0}
                                                            onClick={handleBack}
                                                            className={styles.button}
                                                        >
                                                            Back
                                            </Button>
                                                        {
                                                            !errors.representative_data?.name && touched.representative_data?.name && !errors.representative_data?.phone_number && touched.representative_data?.phone_number && !errors.representative_data?.email && touched.representative_data?.email ?
                                                                (<Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={handleNext}
                                                                    className={styles.button}

                                                                >
                                                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                                </Button>) :
                                                                (null)
                                                        }
                                                    </div>
                                                </div>
                                            </StepContent>
                                        </Step>
                                        <Step key="6" active={activeStep === 6 ? true : false}>
                                            <StepLabel key="6.1"> Secure your account (for dashboard access)</StepLabel>
                                            <StepContent key="6.2">

                                                <Field
                                                    component={TextField}
                                                    name="email"
                                                    type="email"
                                                    label="Email"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <EmailIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    fullWidth
                                                    className={styles.form_field}
                                                />
                                                <br />
                                                <Field
                                                    component={TextField}
                                                    type={showPassword ? 'text' : 'password'}
                                                    label="Password"
                                                    name="password"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <LockIcon />
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment: (
                                                            <InputAdornment position="start" onClick={() => setshowPassword(!showPassword)}>
                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    fullWidth
                                                    className={styles.form_field}

                                                />
                                                <br />
                                                <Field
                                                    component={TextField}
                                                    type={showPassword ? 'text' : 'password'}
                                                    label="Confirm Password"
                                                    name="confirmpassword"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <LockIcon />
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment: (
                                                            <InputAdornment position="start" onClick={() => setshowPassword(!showPassword)}>
                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    fullWidth
                                                    className={styles.form_field}

                                                />
                                                <div className={styles.actionsContainer}>
                                                    {isSubmitting && <LinearProgress />}
                                                    <div>
                                                        <Button
                                                            disabled={activeStep === 0}
                                                            onClick={handleBack}
                                                            className={styles.button}
                                                        >
                                                            Back
                                            </Button>
                                                        {
                                                            !errors.email && touched.email && !errors.password && touched.password && !errors.confirmpassword && touched.confirmpassword ?
                                                                (<Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={handleNext}
                                                                    className={styles.button}

                                                                >
                                                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                                </Button>) :
                                                                (null)
                                                        }
                                                    </div>
                                                </div>
                                            </StepContent>
                                        </Step>

                                    </Stepper>
                                    {isSubmitting && <LinearProgress />}
                                    {activeStep === steps.length && (
                                        <Paper square elevation={0} className={styles.resetContainer}>
                                            <Typography>All steps completed - you&apos;re finished</Typography>
                                            <Button
                                                onClick={handleBack}
                                                className={styles.button}
                                            >
                                                Back
                                            </Button>
                                            <Button onClick={() => handleReset(resetForm)} className={styles.button}>
                                                Reset
                                        </Button>


                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={isSubmitting}
                                                onClick={submitForm}
                                            >
                                                Submit
                                </Button>
                                        </Paper>
                                    )}

                                </Form>
                            )}
                        </Formik>

                    </div>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <div style={{ position: 'relative' }}>
                        <div className={styles.bg_image_wrapper} style={{ backgroundImage: `url(${BGImage})`, width: "100%", height: '100vh', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                            <div>
                                <Link to="/">
                                    <img src={Logo} alt="fastlogistics" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state: any) => {

    return {
        signUpInfo: state.auth.signupInfo,
        auth: state.firebase.auth,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        clearSignUpInfo: () => dispatch({ type: SIGNUP_FEEDBACK, payload: { severity: '', message: '' } }),
        createAccount: (credentials: ILogisticsCompanyAccount, clientDevicedata: any) => dispatch(NewLogisticsCompanyAccount(credentials, clientDevicedata))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupPage);
