
export const INVALID_EMAIL = 'auth/invalid-email';

export const USER_DISABLED =   'auth/user-disabled';

export const USER_NOT_FOUND = 'auth/user-not-found';

export const WRONG_PASSWORD = 'auth/wrong-password';

export const SIGNIN_FEEDBACK = 'SIGNIN_FEEDBACK';

export interface SignInValues {
    email:string;
    password:string;
}