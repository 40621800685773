import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./pages/Home2";
import About from "./pages/Home/About";
import HowItWorks from "./pages/Home/HowItWorks";
import LoginPage from "./pages/Auth/LoginPage";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import SignUp from "./pages/Auth/SignUpPage";
import ClientSignUpPage from "./pages/Auth/ClientSignUpPage";
// import SignUp from './pages/Auth/SignUpPage';
import Dashboard from "./pages/Dashboard";
import CustomerDashboard from "./pages/CustomerDashboard";
import RedirectPage from "./pages/Auth/RedirectPage";
import AddNewPendingBooking from "./components/AddNewPendingBooking";
import EditPendingBooking from "./components/EditPendingBooking";
import AdminSignUp from "./pages/Auth/AdminSignUp";
import AdminDashBoard from "./pages/AdminDashboard";

//lotto
import Lotto from "./pages/Lotto/Lotto";

function App() {
	return (
		<Router>
			<Switch>
				<Route exact path="/" component={Home} />
				<Route path="/About" component={About} />
				<Route path="/how-it-works" component={HowItWorks} />
				<Route path="/Login" component={LoginPage} />
				<Route path="/forgotpassword" component={ForgotPassword} />
				<Route path="/Signup" component={SignUp} />
				<Route path="/ClientSignup" component={ClientSignUpPage} />
				<Route path="/Dashboard" component={Dashboard} />
				<Route path="/CustomerDashboard" component={CustomerDashboard} />
				<Route path="/Redirect" component={RedirectPage} />
				<Route
					path="/NewBooking/:rubish/:firstname/:lastname/:phonenumber/:customeremail/:customerid"
					component={AddNewPendingBooking}
				/>
				<Route
					path="/EditBooking/:rubish/:firstname/:lastname/:phonenumber/:customeremail/:customerid/:bookingid"
					component={EditPendingBooking}
				/>
				<Route path="/adminSignup" component={AdminSignUp} />
				<Route path="/adminDashboard" component={AdminDashBoard} />

				<Route path="/lotto-upload" component={Lotto} />
			</Switch>
		</Router>
	);
}

export default App;
