import { INVALID_EMAIL, USER_NOT_FOUND , PASSWORD_RESET_FEEDBACK , PASSWORD_RESET_VALUE} from './passwordresetTypes';

export const sendPasswordReset = (credential:PASSWORD_RESET_VALUE) => (dispatch:any,getState:any,{getFirebase}:any)=>{
    const  firebase = getFirebase();

    return firebase.auth().sendPasswordResetEmail(credential.email)
    .then(()=>{
        dispatch({type:PASSWORD_RESET_FEEDBACK,payload:{severity:'success',message:'Password reset link has been sent to your email, go click on it'}})
    })
    .catch((error: any) => {
      
        var errorCode = error.code;

        switch (errorCode) {
            case USER_NOT_FOUND:
                dispatch({ type: PASSWORD_RESET_FEEDBACK, payload: { severity: 'error', message: "Oops! for some reasons this user is not found" } })
                break;
            case INVALID_EMAIL:
                dispatch({ type: PASSWORD_RESET_FEEDBACK, payload: { severity: 'error', message: "Oops! This email is invalid! Check the format and try again" } })
                break;
            default:
                break;
        }

    })
}