
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';

export const IsAvailable = (id:string,status: true | false): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

        return firebase.firestore().collection('LogisticsCompanies').doc(id).update({
            is_available:status
        })
            .then(() => {

            })
            .catch(() => {

            })

    }