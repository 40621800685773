

import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';

export const BlockLogisticsCompany = (id: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

        firebase.firestore().collection('LogisticsCompanies').doc(id).set({
            blocked: true,
            blocked_on: firebase.firestore.FieldValue.serverTimestamp(),

        }, { merge: true })
            .then(() => {
                dispatch({ type: 'SIGNUP_FEEDBACK', payload: { severity: 'success', message: "Account Blocked!" } })
            })

    }