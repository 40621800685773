

import React, { Fragment, useState, useEffect, lazy, Suspense } from 'react';
//package

// styles
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
//components
import Grid from '@material-ui/core/Grid';
// import LogisticsCompanyList from '../../components/LogisticsCompanyList/LogisticsCompanyList';
// import { Typography, Divider } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { connect } from 'react-redux';
import { compose } from "redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
//Alert Component
import CustomAlert from '../CustomAlert/CustomAlert';
import GetImageFromFireBase from '../GetImageFromFirebase';

import Advert from '../Advert';

import _ from 'lodash';

const NewBookingList = lazy(() => import('../../components/Bookings/NewBookingList'));
const CurrentBookingList = lazy(() => import('../../components/Bookings/CurrentBookingList'));
const CompletedBookingList = lazy(() => import('../../components/Bookings/CompletedBookingList'));
const RatingsAndReviewLlist = lazy(() => import('../RatingsAndReviewList'));




const getNewBookings = (AllBookings: object[], lc_id: string) => {
    const result_new_booking = AllBookings.filter((item: any) => {
        if (typeof item.new !== 'undefined') {
            return _.includes(item.choosen_logistics_companies,lc_id) && item.new.status === true
        }
        return null
    });

    return result_new_booking
}
// const getNewBookings = (AllBookings: object[], lc_id: string) => {
//     const result_new_booking = AllBookings.filter((item: any) => {
//         if (typeof item.new !== 'undefined') {
//             return item.logistics_company_id === lc_id && item.new.status === true
//         }
//         return null
//     });

//     return result_new_booking
// }

const getCurrentBookings = (AllBookings: object[], lc_id: string) => {
    const result_new_booking = AllBookings.filter((item: any) => {
        if (typeof item.current !== 'undefined') {
            return item.logistics_company_id === lc_id && item.current.status === true
        }
        return null
    });

    return result_new_booking
}

const getCompletedBookings = (AllBookings: object[], id: string) => {
    const result_new_booking = AllBookings.filter((item: any) => {
        if (typeof item.customer_completed !== 'undefined') {
            return item.logistics_company_id === id && item.customer_completed.status === true
        }
        return null
    });
    return result_new_booking

}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                   {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            marginTop: 60,
            [theme.breakpoints.down('sm')]: {
                marginTop: 50,
            }
        },
        no_result: {
            width: '100%',
            height: '200px',
            border: '2px gray solid',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        account_details_wrapper: {
            width: '80%',
            height: 'auto',
            // border: '1px red solid',
            borderRadius: 30,
            marginTop: 70,
            paddingBottom: 50,
            paddingTop: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        },
        company_logo_wrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 2,
            height: '100%',
            '& img': {
                width: 100,
                height: 100,
                borderRadius: 100,
            }
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
        side_ad: {
            width: 200,
            height: 500,
            // backgroundColor: 'gray',
            marginBottom: 50,
        },
        center_ad: {
            width: '98%',
            height: 150,
            // backgroundColor: 'gray',
            marginBottom: 50,
        }
    }),
);


const DashboardWindow = ({ user, allBookings, auth, signUpInfo, clearSignUpInfo, allRatingsAndReviews }: any) => {
    const styles = useStyles();

    const [tabIndex, settabIndex] = useState(0);
    // const [openOperationalCountries, setopenOperationalCountries] = useState(false);
    // const [representativedata, setrepresentativedata] = useState(false);
    // const [vehcicles, setVehcicles] = useState(false);
    // const [cargoVehicles, setcargoVehicles] = useState(false);
    const [newBookings, setnewBookings] = useState<any>([]);
    const [currentBookings, setcurrentBookings] = useState<any>([]);
    const [completedBooking, setcompletedBooking] = useState<any>([]);
    // const [viewProfile, setviewProfile] = useState(false);

    useEffect(() => {
        if (typeof allBookings !== 'undefined' && typeof auth !== 'undefined') {
            const result_new_booking = getNewBookings(allBookings, auth.uid);
            const result_current_booking = getCurrentBookings(allBookings, auth.uid);
            const result_completedbooking = getCompletedBookings(allBookings, auth.uid);
            setnewBookings(result_new_booking);
            setcurrentBookings(result_current_booking);
            setcompletedBooking([...result_completedbooking]);
        }

    }, [allBookings, auth])

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        settabIndex(newValue);
    };



    if (!isLoaded(allBookings) && !isLoaded(auth) && !isLoaded(allRatingsAndReviews)) {
        return <div>Loading...</div>
    }


    return (
        <Fragment>
            {
                signUpInfo.severity === 'error' || signUpInfo.severity === 'warning' || signUpInfo.severity === 'info' || signUpInfo.severity === 'success' ? <CustomAlert severity={signUpInfo.severity} message={signUpInfo.message} show={true} clearState={clearSignUpInfo} /> : null
            }
            <div className={styles.root}>
                <Grid container>
                    <Grid item xs={12} lg={2}>

                    </Grid>
                    <Grid item xs={12} lg={8}>
                        <Advert />
                        <Grid container>
                            <Grid item xs={12} lg={2}>
                            </Grid>
                            <Grid item xs={12} lg={8}>
                                <div className={styles.company_logo_wrapper}>
                                    <GetImageFromFireBase source={user.company_logo_ID} />
                                </div>
                                <Typography variant="h5" align="center">{user.company_name}</Typography>
                            </Grid>
                            <Grid item xs={12} lg={2}>

                            </Grid>
                        </Grid>
                        <AppBar position="static" style={{ marginTop: 40 }} color="default" >
                            <Tabs
                                value={tabIndex}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"

                            >
                                <Tab data-tut="newuser__newbooking" label="New Bookings" {...a11yProps(0)} />
                                <Tab data-tut="newuser__currentbooking" label="Current Bookings" {...a11yProps(1)} />
                                <Tab data-tut="newuser__completedbooking" label="Completed Bookings" {...a11yProps(2)} />
                                <Tab data-tut="newuser__reviews" label="Reviews" {...a11yProps(3)} />

                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabIndex} index={0} >

                            <Suspense fallback={() => <div>Loading...</div>}>
                                <NewBookingList data={newBookings} pricePerMile={user.price_per_mile} name={user.company_name} />
                            </Suspense>
                            {
                                newBookings.length === 0 && <div className={styles.no_result}>
                                    <h6>No New Booking </h6>
                                </div>
                            }
                        </TabPanel>
                        <TabPanel value={tabIndex} index={1} >
                            <Suspense fallback={() => <div>Loading...</div>}>

                                <CurrentBookingList data={currentBookings} />
                            </Suspense>
                            {
                                currentBookings.length === 0 && <div className={styles.no_result}>
                                    <h6>No Current Booking </h6>
                                </div>
                            }

                        </TabPanel>
                        <TabPanel value={tabIndex} index={2} >
                            <Suspense fallback={() => <div>Loading...</div>}>
                                <CompletedBookingList data={completedBooking} />
                            </Suspense>
                            {
                                completedBooking.length === 0 && <div className={styles.no_result}>
                                    <h6>No Completed Booking </h6>
                                </div>
                            }
                        </TabPanel>
                        <TabPanel value={tabIndex} index={3}>
                            <Suspense fallback={<div>Loading...</div>}>
                                <RatingsAndReviewLlist lcID={auth.uid} allRatingAndReview={allRatingsAndReviews} />
                            </Suspense>
                        </TabPanel>
                    </Grid>
                    <Grid item xs={12} lg={2}>

                    </Grid>
                </Grid>

            </div>
        </Fragment>
    );
}

// export default DashboardWindow;
const mapStateToProps = (state: any) => {


    return {
        allBookings: state.firestore.ordered.Bookings,
        allRatingsAndReviews: state.firestore.ordered.RatingsAndReviews,
        auth: state.firebase.auth,
        signUpInfo: state.auth.signupInfo,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        clearSignUpInfo: () => dispatch({ type: 'SIGNUP_FEEDBACK', payload: { severity: '', message: '' } }),
    };
};



export default compose<any>(
    firestoreConnect((props: any) => [
        {
            collection: 'Bookings',

        },
        {
            collection: 'RatingsAndReviews',

        },
    ]),
    connect(mapStateToProps, mapDispatchToProps)
)(DashboardWindow)