import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import Logo from "../../assets/images/logo2.png";
import Loader from "../../assets/images/fastlogisticsloader.gif";
import BGImage from "../../assets/images/banner.png";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		grow: {
			flexGrow: 1,
		},
		menuButton: {
			marginRight: theme.spacing(2),
		},
		title: {
			display: "none",
			[theme.breakpoints.up("sm")]: {
				display: "block",
			},
		},

		inputRoot: {
			color: "inherit",
		},
		inputInput: {
			padding: theme.spacing(1, 1, 1, 0),
			// vertical padding + font size from searchIcon
			paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
			transition: theme.transitions.create("width"),
			width: "100%",
			[theme.breakpoints.up("md")]: {
				width: "20ch",
			},
		},
		sectionDesktop: {
			display: "none",
			[theme.breakpoints.up("md")]: {
				display: "flex",
			},
		},
		sectionMobile: {
			display: "flex",
			[theme.breakpoints.up("md")]: {
				display: "none",
			},
		},
		logo: {
			width: 150,
			height: "auto",
			[theme.breakpoints.down("sm")]: {
				width: 120,
			},
		},
		logo_wrapper: {
			width: "50%",
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "center",
			[theme.breakpoints.down("sm")]: {
				width: "100%",
				justifyContent: "center",
			},
		},
		drawer_wrapper: {
			width: 250,
			height: "100vh",
		},
		drawer_logo: {
			width: "100%",
			height: "auto",
		},
		hero_image: {
			width: "100%",
			height: "90vh",
			backgroundSize: "cover",
			backgroundPosition: "center",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "column",
		},
		personas: {
			width: "100%",
			height: 300,
			display: "flex",
			justifyContent: "space-evenly",
			alignItems: "center",
			flexDirection: "column",
		},
		loader_wrapper: {
			width: "100vw",
			height: "100vh",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
	})
);

export const Home = () => {
	const styles = useStyles();
	const [toggleDrawerState, setToggleDrawerState] = useState(false);
	const [pageLoader, setPageLoader] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setPageLoader(true);
		}, 2000);
	}, [pageLoader]);

	if (!pageLoader) {
		return (
			<div className={styles.loader_wrapper}>
				{" "}
				<img src={Loader} alt="Loader logo" />{" "}
			</div>
		);
	}

	return (
		<div className={styles.grow}>
			<Drawer
				anchor="left"
				open={toggleDrawerState}
				onClose={() => setToggleDrawerState(!toggleDrawerState)}
			>
				<div className={styles.drawer_wrapper}>
					<img
						src={Logo}
						alt="fastlogistics logo"
						className={styles.drawer_logo}
					/>
					<List>
						<ListItem component={Link} to="/how-it-works">
							<ListItemText primary="How it works" />
						</ListItem>
						<ListItem component={Link} to="/About">
							<ListItemText primary="About" />
						</ListItem>

						{/* <ListItem button>
							<ListItemText primary="Contact Us" />
						</ListItem> */}
					</List>
					<Button
						component={Link}
						to="/Login"
						style={{ marginLeft: 20 }}
						variant="outlined"
					>
						Login
					</Button>
				</div>
			</Drawer>
			<AppBar position="static" color="inherit">
				<Container>
					<Toolbar>
						<div className={styles.sectionMobile}>
							<IconButton
								edge="start"
								className={styles.menuButton}
								color="inherit"
								aria-label="open drawer"
								onClick={() => setToggleDrawerState(!toggleDrawerState)}
							>
								<MenuIcon />
							</IconButton>
						</div>
						<div className={styles.logo_wrapper}>
							<img
								src={Logo}
								alt="fast logistics logo"
								className={styles.logo}
							/>
						</div>
						<div className={styles.grow} />
						<div className={styles.sectionDesktop}>
							<Button component={Link} to="/how-it-works">
								How it works
							</Button>
							<Button component={Link} to="/About">
								About
							</Button>
							{/* <Button component={Link} to="/Contact">
								Contact Us
							</Button> */}
							<Button variant="outlined" component={Link} to="/login">
								Login
							</Button>
						</div>
					</Toolbar>
				</Container>
			</AppBar>
			<div
				className={styles.hero_image}
				style={{ backgroundImage: `url(${BGImage})` }}
			>
				<Typography variant="h6" style={{ color: "white" }}>
					For Personal & Business
				</Typography>
				<Typography variant="h3" style={{ color: "white" }} align="center">
					Nation Wide Logistics Service
				</Typography>
				<a
					className="boxed-btn3-line"
					target="_blank"
					rel="noopener noreferrer"
					href="https://docs.google.com/forms/d/e/1FAIpQLSeWM8n6lyurRLRH8tE0lUP93HgS_HyqbXqZ4cSui8fZG-KXTw/viewform"
				>
					Order A Dispatch
				</a>
			</div>
			<Container>
				<Grid container>
					<Grid item xs={12} lg={6}>
						<div className={styles.personas}>
							<Typography variant="h6">
								Are you a Logistics Company and want to more clients?
							</Typography>
							{/* <Button
								component={Link}
								to="/Signup"
								size="large"
								variant="outlined"
								color="primary"
							>
								Get started
							</Button> */}
							<a
								className="boxed-btn3-line"
								target="_blank"
								rel="noopener noreferrer"
								href="https://docs.google.com/forms/d/e/1FAIpQLSeWM8n6lyurRLRH8tE0lUP93HgS_HyqbXqZ4cSui8fZG-KXTw/viewform"
							>
								Order A Dispatch
							</a>
						</div>
					</Grid>
					<Grid item xs={12} lg={6}>
						<div className={styles.personas}>
							<Typography variant="h6">
								Are you a looking for a fast logistics company close to you?{" "}
							</Typography>
							{/* <Button
								component={Link}
								to="/clientsignup"
								size="large"
								variant="outlined"
								color="primary"
							>
								Get started
							</Button> */}
							<a
								className="boxed-btn3-line"
								target="_blank"
								rel="noopener noreferrer"
								href="https://docs.google.com/forms/d/e/1FAIpQLSeWM8n6lyurRLRH8tE0lUP93HgS_HyqbXqZ4cSui8fZG-KXTw/viewform"
							>
								Order A Dispatch
							</a>
						</div>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default Home;
