import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import InboxIcon from "@material-ui/icons/Inbox";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";

import useUploadLottoData from "../../hooks/useUploadLottoData";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  })
);

const Lotto = () => {
  const styles = useStyles();

  const [UploadLottoDataMutate, UploadLottoDataInfo] = useUploadLottoData();

  const [isEmpty, setIsEmpty] = useState(false);

  const [isDone, setIsDone] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleChange = (e: any) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (e) => {
      UploadLottoDataMutate(e.target?.result, {
        onSuccess: () => {
          setIsSuccessful(true);
          setIsError(false);
        },
        onError: () => {
          setIsSuccessful(false);
          setIsError(true);
        },
      });
    };

    if (fileReader.readyState === 0) {
      setIsEmpty(true);

      setIsDone(false);
    } else if (fileReader.readyState === 1) {
      setIsEmpty(false);

      setIsDone(false);
    } else if (fileReader.readyState === 2) {
      setIsEmpty(false);

      setIsDone(true);
    }
  };

  return (
    <div className={styles.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={styles.title}>
            Lotto Lucky Numbers Upload
          </Typography>
          <Button color="inherit">Scitylana</Button>
        </Toolbar>
      </AppBar>
      <Container maxWidth="md">
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 20,
              flexDirection: "column",
              height: 500,
            }}
          >
            <Typography variant="h3" color="initial" align="center">
              UPLOAD DATA FILE HERE
            </Typography>
            <br />
            <br />
            <input type="file" onChange={handleChange} />
            <br />
            {isSuccessful && (
              <Alert severity="success">File uploaded successfully!</Alert>
            )}
            {isError && (
              <Alert severity="error">An error occured, try again!</Alert>
            )}

            {UploadLottoDataInfo.isLoading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <CircularProgress />
                <br />
                <Typography variant="h6" color="initial">
                  Uploading to database...
                </Typography>
              </div>
            )}

            {isEmpty && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <InboxIcon />
                <br />
                <Typography variant="h6" color="initial">
                  No data uploaded yet
                </Typography>
              </div>
            )}
            {isDone && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <CheckCircleTwoToneIcon />
                <br />
                <Typography variant="h6" color="initial">
                  Data uploaded successfully!
                </Typography>
              </div>
            )}
          </div>
        </>
      </Container>
    </div>
  );
};

export default Lotto;
