import React, { Fragment } from "react";
import { Link } from "react-router-dom";
// import Contact from '../../components/Contact/Contact';
// logo
// import Logo from '../../assets/images/logo2.png';
import Logo from "./img/logo2.png";
// import BannerImage1 from './img/banner/banner.png';
// import ContactBg from './img/banner/contact_bg.png';
import Icon1 from "./img/svg_icon/airplane.png";
import Icon2 from "./img/svg_icon/live.png";
import Icon3 from "./img/svg_icon/world.png";
import "./css/bootstrap.min.css";
// import "./css/owl.carousel.min.css";
// import "./css/magnific-popup.css";
import "./css/font-awesome.min.css";
import "./css/themify-icons.css";
import "./css/nice-select.css";
import "./css/flaticon.css";
import "./css/gijgo.css";
import "./css/animate.css";
import "./css/slick.css";
import "./css/slicknav.css";
// import "https://ajax.googleapis.com/ajax/libs/jqueryui/1.11.2/themes/smoothness/jquery-ui.css";
import "./css/style.css";

const About = () => {
	return (
		<Fragment>
			<header>
				<div className="header-area ">
					<div className="header-top_area d-none d-lg-block">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-xl-4 col-lg-4">
									<div className="logo">
										<Link to="/">
											<img src={Logo} alt="fastlogistics logo" />
										</Link>
									</div>
								</div>
								<div className="col-xl-8 col-md-8">
									<div className="header_right d-flex align-items-center">
										<div className="short_contact_list">
											<ul>
												<li>
													<a href="!#">
														{" "}
														<i className="fa fa-envelope"></i>{" "}
														info@fastlogisticsng.com
													</a>
												</li>
												<li>
													<a href="!#">
														{" "}
														<i className="fa fa-phone"></i> 1601-609 6780
													</a>
												</li>
											</ul>
										</div>

										<div className="book_btn d-none d-lg-block">
											<a
												className="boxed-btn3-line"
												target="_blank"
												rel="noopener noreferrer"
												href="https://docs.google.com/forms/d/e/1FAIpQLSeWM8n6lyurRLRH8tE0lUP93HgS_HyqbXqZ4cSui8fZG-KXTw/viewform"
											>
												Order A Dispatch
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div id="sticky-header" className="main-header-area">
						<div className="container">
							<div className="header_bottom_border">
								<div className="row align-items-center">
									<div className="col-12 d-block d-lg-none">
										<div className="logo">
											<a href="/">
												<img
													src={Logo}
													alt="fastlogistics logo"
													style={{ width: "50px", height: "50px" }}
												/>
											</a>
										</div>
									</div>
									<div className="col-xl-9 col-lg-9">
										<div className="main-menu  d-none d-lg-block">
											<nav>
												<ul id="navigation">
													<li>
														<Link to="/">home</Link>{" "}
													</li>
													<li>
														<Link to="/About">about</Link>
													</li>
													<li>
														<Link to="/how-it-works">How it works</Link>
													</li>
													<li>{/* <Link to="/Contact">Contact</Link> */}</li>
												</ul>
											</nav>
										</div>
									</div>
									{/* <div className="col-xl-3 col-lg-3 d-none d-lg-block">
                                        <div className="Appointment justify-content-end">
                                            <div className="search_btn">
                                                <a data-toggle="modal" data-target="#exampleModalCenter" href="#!">
                                                    <i className="ti-search"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div> */}
									<div className="col-12">
										<div className="mobile_menu d-block d-lg-none"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>

			<div className="transportaion_area">
				<div className="container">
					<div className="row">
						<div className="col-xl-6 col-lg-6 col-md-6">
							<h4>Problems we are addressing</h4>
							<p>
								Fast logistics is targeted at solving the following issues
								including but not limited to:
							</p>
							<br />
							<ul>
								<li>
									1. Swift delivery to customers to the last mile, that is even
									to their doorstep enabling ease.
								</li>
								<br />
								<li>
									2. It also aims at reducing marketing and advertising costs
									for the various logistics companies that are on board as fast
									logistics takes them up and they connect them with their
									customers.
								</li>
								<br />
								<li>
									3. Given there a list of logistics companies on board in
									various different locations, goods to be delivered are done
									with proximity to the customer, hence, further reducing cost
									as well as saving time and giving customers their satisfaction
									for ease and swift delivery.
								</li>
								<br />
								<li>
									4. Fair prices are calibrated into the system as a result of
									the already mentioned factors, especially proximity.
								</li>
								<br />
								<li>
									5. User friendly interface, the application is very easy to
									use and understand.
								</li>
							</ul>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-6">
							<h4>Fast Logistics Corporate Social Responsibility (CSR)</h4>
							<p>
								Fast logistics is also about ensuring safety on our roads, we
								are dedicated to stopping drunk driving and improving drivers
								knowledge on road signs and markings.
							</p>
							<br />
							<ul>
								<li>
									<a
										href="https://www.highwaycodeuk.co.uk/signs-and-signals.html"
										target="_blank"
										rel="noopener noreferrer"
									>
										Click here
									</a>
									to know your road markings (United Kingdoms/International)
								</li>
								<br />
								<li>
									<a
										href="https://worksandhousing.gov.ng/management/uploads_images/1569354651.pdf"
										target="_blank"
										rel="noopener noreferrer"
									>
										Click here{" "}
									</a>
									to know your road markings (Nigeria)
								</li>
								<br />
								<li>
									<a
										href="https://test.1driver.co.uk/?utm_source=HC_website&utm_medium=pop_up_exit&utm_campaign=2021"
										target="_blank"
										rel="noopener noreferrer"
									>
										Click here{" "}
									</a>
									to do a mock theory test provided by the UK Highway code
								</li>
								<br />
								<li>
									<a
										href="https://test.1driver.co.uk/hazard-perception-test/practice/1"
										target="_blank"
										rel="noopener noreferrer"
									>
										Click here{" "}
									</a>
									to Improve your Hazard Perception on the road
								</li>
								<br />
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="transportaion_area">
				<div className="container">
					<div className="row">
						<div className="col-xl-4 col-lg-4 col-md-6">
							<div className="single_transport">
								<div className="icon">
									<img src={Icon1} alt="transportation icon" />
								</div>
								<h3>About Us</h3>
								<p>
									Fast Logistics Nigeria are a distinct team of experts who have
									decided to cater for the incessant needs for speedy, reliable
									and affordable forms of logistics of people and goods to
									various parts of the world.
								</p>
							</div>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-6">
							<div className="single_transport">
								<div className="icon">
									<img src={Icon2} alt="Livemonitoriing logo" />
								</div>
								<h3>Mission</h3>
								<p>
									Our goal is to ensure that every person is able to access the
									required information to perform their logistics needs, an easy
									and fast means of providing these logistics needs and at an
									affordable price.
								</p>
							</div>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-6">
							<div className="single_transport">
								<div className="icon">
									<img src={Icon3} alt="worldwide logo" />
								</div>
								<h3>Vision</h3>
								<p>
									We intend to transform the mode at which logistics is done and
									looked at in Nigeria by automating and providing only
									top-notch services that relates to the efficient management
									and growth of logistics in Nigeria, Africa and the world at
									large. Therefore making us the number one providers of
									reliable logistics for people, commodities and goods.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <!-- footer start --> */}
			<footer className="footer">
				<div className="footer_top">
					<div className="container">
						<div className="row">
							<div className="col-xl-3 col-md-6 col-lg-3"></div>
							<div className="col-xl-2 col-md-6 col-lg-2">
								<div className="footer_widget">
									<h3 className="footer_title">Fastlogisticsng</h3>
									<ul>
										<li>
											<Link to="/">Home</Link>{" "}
										</li>
										<li>
											<Link to="/About">About</Link>
										</li>
										<li>
											<Link to="/how-it-works">How it works</Link>
										</li>
										<li>{/* <Link to="/Contact">Contact</Link> */}</li>
									</ul>
								</div>
							</div>
							<div className="col-xl-3 col-md-6 col-lg-3"></div>
							<div className="col-xl-4 col-md-6 col-lg-4">
								{/* <div className="footer_widget">
									<h3 className="footer_title">Subscribe</h3>
									<form action="#" className="newsletter_form">
										<input type="text" placeholder="Enter your mail" />
										<button type="submit">Subscribe</button>
									</form>
									<p className="newsletter_text">
										Get up to date information on the best deals on logistics
									</p>
								</div> */}
							</div>
						</div>
					</div>
				</div>
				<div className="copy-right_text">
					<div className="container">
						<div className="footer_border"></div>
						<div className="row">
							<div className="col-xl-12">
								<p className="copy_right text-center">
									Copyright &copy;2020 All rights reserved{" "}
									<a
										href="https://scitylanang.com"
										rel="noopener noreferrer"
										target="_blank"
									>
										Scitylana
									</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</footer>
			{/* <!--/ footer end  --> */}
		</Fragment>
	);
};

export default About;
