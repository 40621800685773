import {
    // BrowserView,
    // MobileView,
    // isBrowser,
    // isMobile,
    // isMobileOnly,
    // isTablet,
    // isSmartTV,
    // isWearable,
    // isConsole,
    // isAndroid,
    // isWinPhone,
    // isIOS,
    // isChrome,
    // isFirefox,
    // isSafari,
    // isOpera,
    // isIE,
    // isEdge,
    // isYandex,
    // isChromium,
    // isMobileSafari,
    // osVersion,
    // osName,
    // fullBrowserVersion,
    // browserVersion,
    // browserName,
    // mobileVendor,
    // mobileModel,
    // engineName,
    // engineVersion,
    // deviceType,
    // isIOS13,
    // isIPhone13,
    // isIPad13,
    // isIPod13,
    // isElectron,
    // isEdgeChromium,
    // isLegacyEdge,
    // isWindows,
    // isMacOs,
    deviceDetect

} from "react-device-detect";

import shortid from 'shortid';

// export const getBrowserProps =  () => {
//     fetch('https://extreme-ip-lookup.com/json').then(function(response) {
//         return response.json();
//       }).then(function(parsedJson) {
//         let deviceDetectResult = deviceDetect();
//         let windowScreenResult = window.screen;
//         let deviceIdObj = { deviceId: shortid.generate() }
    
//         let resultData = Object.assign(windowScreenResult, deviceIdObj,parsedJson, deviceDetectResult);
//         console.log(typeof(resultData),resultData);
//         return resultData
       
//       })

// }
export const getBrowserProps =  async () => {

try{
    const response = await fetch("https://extreme-ip-lookup.com/json");     
    const responsedata = await response.json();
    
    let deviceDetectResult = deviceDetect();
    let windowScreenResult = window.screen;
   

        let resultData = Object.assign(windowScreenResult,responsedata, deviceDetectResult);
        // console.log(typeof(resultData),resultData);
        return {
            deviceId: shortid.generate(),
            deviceData:{...resultData},
            blacklist:false,
            whitelist:true,
        }
}catch(error){
    console.log(error)
}

}
