import { SIGNUP_FEEDBACK } from '../actions/auth/signup/signupTypes';
import { SIGNIN_FEEDBACK } from '../actions/auth/login/signinTypes';
import { PASSWORD_RESET_FEEDBACK} from '../actions/auth/PasswordReset/passwordresetTypes'

interface initialStateValue {
    signupInfo: {
        severity: string;
        message: string;
    },
    signinInfo:{
        severity: string;
        message: string;
    },
    passwordresetInfo:{
        severity: string;
        message: string;
    }
}

interface actionValues {
    type: string;
    payload: {
        severity: string;
        message: string;
    }
}

let initialState: initialStateValue = {
    signupInfo: {
        severity: '',
        message: ''
    },
    signinInfo: {
        severity: '',
        message: ''
    },
    passwordresetInfo: {
        severity: '',
        message: ''
    }
};




const authReducer = (state = initialState, action: actionValues) => {
    switch (action.type) {
        case SIGNUP_FEEDBACK:
            return {
                ...state,
                signupInfo: {
                    severity: action.payload.severity,
                    message: action.payload.message
                }
            }
        case SIGNIN_FEEDBACK:
            return {
                ...state,
                signinInfo: {
                    severity: action.payload.severity,
                    message: action.payload.message
                }
            }
        case PASSWORD_RESET_FEEDBACK:
            return {
                ...state,
                passwordresetInfo: {
                    severity: action.payload.severity,
                    message: action.payload.message
                }
            }
        default:
            return state
    }
}

export default authReducer;