import * as React from "react";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import firebase from "../../config/firebaseConfig";
import shortid from "shortid";
// And import the necessary css
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// register the filepond plugins for additional functionality
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

// make a reference to our firebase storage
const storage = firebase.storage().ref();

const ImageUploader = ({onRequestSave,onRequestClear,defaultFiles = [],existingImageId}:any) => {
  // use a useState hook to maintain our files collection
  const [files, setFiles] = React.useState(defaultFiles);

  const server = {
    // this uploads the image using firebase
    process: (fieldName:any, file:any, metadata:any, load:any, error:any, progress:any, abort:any) => {
      // create a unique id for the file
      let id:string;
      if (existingImageId) {
        id = existingImageId;
      } else {
        id = shortid.generate();
      }
      console.log(id);

      // upload the image to firebase
      const task = storage.child("images/" + id).put(file, {
        contentType: "image/jpeg",
      });

      // monitor the task to provide updates to FilePond
      task.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snap) => {
          // provide progress updates
          progress(true, snap.bytesTransferred, snap.totalBytes);
        },
        (err) => {
          // provide errors
          error(err.message);
          console.log(err.message);
        },
        () => {
          // the file has been uploaded
          load(id);
          onRequestSave(id);
        }
      );
    },

    // this loads an already uploaded image to firebase
    load: (source:any, load:any, error:any, progress:any, abort:any) => {
      // reset our progress
      progress(true, 0, 1024);

      // fetch the download URL from firebase
      storage
        .child("images/" + source)
        .getDownloadURL()
        .then((url) => {
          // fetch the actual image using the download URL
          // and provide the blob to FilePond using the load callback
          let xhr = new XMLHttpRequest();
          xhr.responseType = "blob";
          xhr.onload = function (event) {
            let blob = xhr.response;
            load(blob);
          };
          xhr.open("GET", url);
          xhr.send();
        })
        .catch((err) => {
          error(err.message);
          abort();
        });
    },
  };

  return (
    <FilePond
      files={files}
      allowMultiple={false}
      maxFiles={1}
      onupdatefiles={(fileItems:any) => {
        if (fileItems.length === 0) {
          onRequestClear();
        }

        setFiles(fileItems.map((fileItem:any) => fileItem.file));
      }}
      server={server} // todo: add custom server functionality using firebase
    />
  );
}


export default ImageUploader;